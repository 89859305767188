import React from "react";
import { CSVLink } from "react-csv";
import { Button, Card, DatePicker, Dropdown, Modal, Select, Switch, Tabs, message, notification } from "antd";
import { Icon } from "@ant-design/compatible";
import { connect } from "react-redux";
import numberFormatter from "../../../../utils/numberFormatter";
import {
  getAllSales,
  getAllSalesByDuration,
  merchantHasSale,
  getBranchSales,
  getBranchSalesByDuration,
  getCashierSales,
  sendSalesReportToEmail,
  getSalesByCustomerName,
  getSalesByPaymentType,
  getSalesByCustomerId,
  getAllPaymentLabel
} from "../../../../redux/actions/saleActions";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import {
  handleDurationChange,
  parseExportData,
  parseReport,
} from "./functions";
import Transactions from "./Transactions";

import "./index.scss";
import PaymentMethodTotal from "./PaymentMethodTotal";
import strings from "../../../../strings";

const { RangePicker } = DatePicker;
const { Option } = Select;

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      reports: [],
      products: [],
      spenders: [],
      transactions: [],
      duration: "today",
      currentPage: 1,
      filterValue: "",
      isExportingSales: false,
      exportData: [],
      pageSize: 30,
      isBranchSelected: false,
      selectedBranch: null,
      allBranchDetails: [],
      allStaffs: [],
      selectedBranchName: null,
      isStaffSelected: false,
      selectedStaffDetails: null,
      sendingMailLoading: false,
      isPaymentTypeSelected: false,
      selectedPaymentType: null,
      isCustomerSelected: false,
      selectedCustomerName: null,
      selectedCustomerId: null,
      paymentName: null,
      searchTransactionId: null,
      filterByVat: false,
      totalRangeProfit: null,
      paymentLabelLoading: false,
      allPaymentLabels: [],
    };

    this.handleDurationChange = handleDurationChange.bind(this);
    this.csvLinkRef = React.createRef();
  }

  componentDidMount() {
    this.handleSetStaff();
    this.handleFetchSales();
    this.handleGetPaymentOptions();
  }

  componentDidUpdate(prevprops){
    if(prevprops.allBranches !== this.props.allBranches){
      this.handleSetStaff();
    }
  }

  handleFetchSales = () => {
    const { user, getAllBusinessBranches } = this.props;
    if(user.business_branch){
      this.handleGetSalesByBranch();
    }else{
      this.handleGetAllSales();
      getAllBusinessBranches();
      // this.fetchCustomers();
    }
  }

  handleSetStaff = () => {
    const { user, allBranches } = this.props;
    const staffs = [];
    allBranches.map(branch => {
      if(branch.staff.length > 0){
        branch.staff.map(staff => {
          let staf = {...staff, branch_id: branch.id}
          staffs.push(staf)
        })
      }
    });

    this.setState({ allStaffs: staffs });
  }
  resetTransactions = (transactions = []) => {
    this.setState({
      reports: transactions,
    });
  };

  handleSalesExport = async done => {
    const { begin, end, isBranchSelected, selectedBranch } = this.state;
    const { user } = this.props;

    notification.info({
      message: strings.exportTransactions,
      description: strings.yourDownloadWillStart,
      duration: 2,
      style: {
        top: "3rem",
      },
    });

    if (begin && end) {
      if(user.business_branch){
        const response = await this.handleGetBranchSalesRangeForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }else if(isBranchSelected){
        const response = await this.handleGetBranchSalesRangeForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }else{
        const response = await this.handleGetSalesRangeForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
  
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }
      
    } else {
      if(user.business_branch){
        const response = await this.handleGetBranchSalesForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }else if(isBranchSelected){
        const response = await this.handleGetBranchSalesForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }else{
        const response = await this.handleGetAllSalesForExport().catch(err => {
          console.log(err);
          this.setState({ isExportingSales: false });
          message.error(strings.errorFetchingSalesRecord);
        });
  
        const resp = parseReport(response?.data);
  
        const exportData = parseExportData(resp);
  
        this.setState({ isExportingSales: false, exportData });
  
        setTimeout(() => {
          const downloadBtn = document.getElementById("csvDownloadBtn");
  
          downloadBtn && downloadBtn.click();
        }, 1000);
      }
    }
  };

  handleGetAllSalesForExport = async (pageNumber = 1, type = "export") => {
    this.setState({ isExportingSales: true });
    const response = await this.props.getAllSales({current: pageNumber, type});

    return response;
  };

  handleGetBranchSalesForExport = async (pageNumber = 1, type = "export") => {
    this.setState({ isExportingSales: true });
    const { selectedBranch } = this.state;
    const { user } = this.props;
    
    const id = user.business_branch ? user.business_branch.id :  selectedBranch;

    const response = await this.props.getBranchSales({current: pageNumber, id, type});

    return response;
  };

  handleGetSalesRangeForExport = async (pageNumber = 1, type = "export") => {
    const { begin, end } = this.state;

    this.setState({ isExportingSales: true });
    const response = await this.props.getAllSalesByDuration(
      begin.format().split("T")[0],
      end.format().split("T")[0],
      pageNumber,
      type
    );

    return response;
  };

  handleGetBranchSalesRangeForExport = async (pageNumber = 1, type = "export") => {
    const { begin, end, selectedBranch } = this.state;
    const { user } = this.props;
    const id = user.business_branch ? user.business_branch.id : selectedBranch;

    this.setState({ isExportingSales: true });
    const response = await this.props.getBranchSalesByDuration(
      begin.format().split("T")[0],
      end.format().split("T")[0],
      id,
      pageNumber,
      type
    );

    return response;
  };

  handleGetAllSales(pageNumber = 1, id) {
    // Clear any Present Filters
    const { 
      isBranchSelected, 
      selectedBranch, 
      isStaffSelected, 
      selectedStaffDetails, 
      selectedPaymentType, 
      selectedCustomerId, 
      searchTransactionId 
    } = this.state;
    this.updateFilterValue("");
    this.setState({ loading: true });

    if(id){
      this.handleSetBranchName(id);
      if(isStaffSelected) return this.handleGetStaffSales(selectedStaffDetails.staff_id,id, pageNumber.current);
      return this.props.getBranchSales({pageNumber, id, type: "default"}, selectedPaymentType, selectedCustomerId, searchTransactionId)
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }

        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: null,
          end: null,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        this.setState({
          loading: false
        })
        message.error(err.response.data.error || strings.errorFetchingRecord);
      })
    }else if(isBranchSelected){
      this.handleSetBranchName(selectedBranch);
      if(isStaffSelected) return this.handleGetStaffSales(selectedStaffDetails.staff_id,selectedBranch, pageNumber.current);
      return this.props.getBranchSales({pageNumber: pageNumber.current, id: selectedBranch, type: "default"}, selectedPaymentType, selectedCustomerId, searchTransactionId)
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }

        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: null,
          end: null,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        this.setState({
          loading: false
        })
          message.error(err.response.data.error || strings.errorFetchingRecord);
      })
    }else{
      this.props
      .getAllSales(pageNumber, selectedPaymentType, searchTransactionId)
      .then(response => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }

        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: null,
          end: null,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        this.setState({
          loading: false
        })
        message.error(err?.response?.data?.error || strings.errorFetchingRecord);
      });
    }
  }

  updateFilterValue = filterValue => {
    if(filterValue){
      let filtered;
      if(!isNaN(filterValue)){
        filtered = filterValue;
      }else if(filterValue.toLowerCase().includes("bank transfer")){
        filtered = `is_paid_with_mtransfer`;
      }else if(filterValue.toLowerCase().includes("wallet")){
        filtered = `is_paid_with_customer_account`;
      }else if(filterValue.toLowerCase().includes("payment label")){
        filtered = `payment_label`;
      }else if(filterValue === "Split payment"){
        filtered = `split_payment`;
      }else{
        filtered = `is_paid_with_${filterValue.toLowerCase()}`;
      }
//
      this.handleSelectPaymentType(true, filtered);
      this.handleGetSalesByPaymentType(filtered);
      const namey = this.state.allPaymentLabels.find(label => label.id == filterValue);
      return this.setState({ 
        filterValue: filtered, 
        paymentName: !isNaN(filterValue) ? namey.label_name : filterValue
      })
    }

    this.setState({ filterValue: filterValue, paymentName: filterValue }) 
  };

  handleSetStaffIsSelected = (staff_id, staff_name, branch_id, value) => {
    if(value){
      return this.setState({
        isStaffSelected: value,
        selectedStaffDetails: {
          staff_id: staff_id,
          branch_id: branch_id,
          staff_name: staff_name
        }
      });
    }
    this.setState({
      isStaffSelected: false,
      selectedStaffDetails: null
    });
  }

  handleSelectPaymentType = (value, paymentType) => {
    if(value){
      return this.setState({
        isPaymentTypeSelected: value,
        selectedPaymentType: paymentType
      });
    }

    this.setState({
      isPaymentTypeSelected: value,
      selectedPaymentType: paymentType
    });
  }

  handleSelectCustomer = (value, customer_name, customer_id) => {
    if(value){
      return this.setState({
        isCustomerSelected: value,
        selectedCustomerName: customer_name,
        selectedCustomerId: customer_id
      });
    }

    this.setState({
      isCustomerSelected: value,
      selectedCustomerName: customer_name,
      selectedCustomerId: customer_id
    });
  }

  handleSendSalesToMail = () => {
    const { begin, end } = this.state;
    this.setState({ sendingMailLoading: true })
    if(begin && end) return this.props
    .sendSalesReportToEmail(
      begin.format().split("T")[0], 
      end.format().split("T")[0]
    )
    .then(res => {
      if(res.status === 200 ){
        Modal.success({
          title: res.data.message,
          onOk: () => this.setState({ sendingMailLoading: false })
        })
      }
    })
    .catch(err => {
      Modal.error({
        title: `Unable to send report to mail ${err?.response ? err.response.data.message : err.message}`,
        onOk: this.setState({ sendingMailLoading: false })
      })
    });
    
    this.props
    .sendSalesReportToEmail()
    .then(res => {
      if(res.status === 200 ){
        Modal.success({
          title: res.data.message,
          onOk: () => this.setState({ sendingMailLoading: false })
        })
      }
    })
    .catch(err => {
      Modal.error({
        title: `Unable to send report to mail ${err?.response ? err.response.data.message : err.message}`,
        onOk: this.setState({ sendingMailLoading: false })
      })
    }) 
  }

  handleGetStaffSales = (staff_id, branch_id, pageNumber, begin, end) => {
    // this.updateFilterValue("");
    this.setState({ loading: true });
    const { selectedPaymentType, selectedCustomerId, searchTransactionId } = this.state;

    if(begin && end){
      this.props.getCashierSales(
        staff_id,
        branch_id,
        pageNumber,
        begin.format().split("T")[0],
        end.format().split("T")[0],
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: pageNumber,
        });
      })
      .catch((err) => {
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }else{
      let type = "default";
      this.props.getBranchSales({current: pageNumber, id: branch_id, type}, staff_id, selectedPaymentType, selectedCustomerId, searchTransactionId)
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: pageNumber,
        });
      })
      .catch((err) => {
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }
   
  }

  handleGetSalesRange(pageNumber, begin, end) {
    const { user } = this.props;
    const { isBranchSelected, selectedBranch, isStaffSelected, selectedStaffDetails, selectedPaymentType } = this.state;
    // Clear any Present Filters
    this.updateFilterValue("");

    this.setState({ loading: true }); 
    if(user.business_branch){
      this.props
      .getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        user.business_branch.id,
        pageNumber,
        "default",
        selectedPaymentType,
        user.id
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: pageNumber,
        });
      })
      .catch((err) => {
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }else if(isBranchSelected){
      this.handleSetBranchName(selectedBranch);
      if(isStaffSelected) return this.handleGetStaffSales(selectedStaffDetails.staff_id,selectedBranch, pageNumber.current)
      this.props
      .getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        selectedBranch,
        pageNumber,
        "default",
        selectedPaymentType
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: pageNumber,
        });
      })
      .catch((err) => {
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }else{
      this.props
      .getAllSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        pageNumber
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: pageNumber,
        });
      })
      .catch((err) => {
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }
  }

  handleGetSalesByBranch(pageNumber = 1, type = "default"){
    const { user } = this.props;

    this.updateFilterValue("");

    const id = user.business_branch ? user.business_branch.id : user.id;

    this.setState({ loading: true });
    this.props.getBranchSales({pageNumber, id, type}, user.id)
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
        message.error(err.response.data.error || strings.errorFetchingRecord);
    })
  }

  handleBranchSelect = value => {
    this.setState({ 
      selectedBranch: value 
    });

    if(this.props.allBranches.length === 1) return this.handleGetSalesByFilter(1);
    this.handleSetBranchName(value);
    this.setState({ 
      isBranchSelected: true, 
      selectedBranch: value
    });

    this.handleGetSalesByFilter(1, value);
  }

  handleTransactionIdSelect = value => {
    this.setState({ 
      searchTransactionId: value 
    });

    this.handleGetSalesByTransactionId(value, 1);
  }

  handleSetBranchName = (id=null) => {
    const { allBranches } = this.props;
    if(id) {
      const selectedBranch = allBranches.find(branch => branch.id === id);
      return this.setState({ selectedBranchName: selectedBranch.name })
    }
    this.setState({ selectedBranchName: null })
  }

  handleResetGetAllSales = (pageNumber=1) => {
    this.setState({ loading: true })
    this.props
    .getAllSales(pageNumber)
    .then(response => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }

      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        selectedBranchName: null,
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      this.setState({
        loading: false
      })
      message.error(err.response.data.error || strings.errorFetchingRecord);
    });
  }

  handleResetTransactions = () => {
    const { user } = this.props;
    const { isBranchSelected, selectedBranch } = this.state;
    this.setState({ isBranchSelected: false, selectedBranch: null })
    if(user.business_branch){
      this.handleGetSalesByBranch();
    }else{
      this.handleResetGetAllSales();
    }
  }

  handleResetAll = (pageNumber = 1) => {
    const { user } = this.props;
    this.updateFilterValue("");
    this.setState({ 
      loading: true,
      isBranchSelected: false,
      selectedBranch: null,
      selectedBranchName: null,
      isStaffSelected: false,
      selectedStaffDetails: null,
      isPaymentTypeSelected: false,
      selectedPaymentType: null,
      isCustomerSelected: false,
      selectedCustomerName: null,
      paymentName: null,
      begin: null,
      end: null,
    });

    const id = user.business_branch ? user.business_branch.id : null

    if(id){
      return this.props.getBranchSales({pageNumber, id, type: "default"})
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }

        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: null,
          end: null,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
        this.handleSetBranchName(id);
      })
      .catch((err) => {
        console.log("err",err.response);
        this.setState({
          loading: false
        })
        message.error(err.response.data.error || strings.errorFetchingRecord);
      })
    }else{
      this.props
      .getAllSales(pageNumber)
      .then(response => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }

        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: null,
          end: null,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        this.setState({
          loading: false
        })
        message.error(err.response.data.error || strings.errorFetchingRecord);
      });
    }
  }

  handleGetSalesByCustomerName = (name, pageNumber, pageSize) => {
    const { allBranches } = this.props;
    const { selectedBranch } = this.state;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch;
    }

    this.setState({ loading: true });
    this.props.getSalesByCustomerName(chosenBranch, name, pageNumber, pageSize)
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetSalesByCustomerId = (customer_id, pageNumber) => {
    const { allBranches, getBranchSales } = this.props;
    const { selectedStaffDetails, selectedBranch, selectedPaymentType,searchTransactionId  } = this.state;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch;
    }

    let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null; 
    let type = "default";

    this.setState({ loading: true });
    getBranchSales(
      {current: pageNumber, id: chosenBranch, type}, 
      staffId, 
      selectedPaymentType, 
      customer_id, 
      searchTransactionId
    )
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetSalesByPaymentType = (selectedPaymentType, pageNumber, pageSize) => {
    const { 
      selectedStaffDetails, 
      selectedBranch, 
      selectedCustomerId, 
      begin, end,
      searchTransactionId  
    } = this.state;
    const { allBranches, getBranchSales, getBranchSalesByDuration } = this.props;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch
    }

    let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null 

    // this.setState({ loading: true });
    // this.props.getSalesByPaymentType(chosenBranch, selectedPaymentType, pageNumber, pageSize)
    if(begin && end){
      this.setState({ loading: true });
      getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        chosenBranch,
        pageNumber,
        "default",
        selectedPaymentType,
        staffId,
        searchTransactionId
      )
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: begin,
          end: end,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        message.error(err.response.data.error || strings.errorFetchingRecord);
        this.setState({ loading: false })
      });
      return;
    }
    
    let type = "default";
    this.setState({ loading: true });
    getBranchSales(
      {current: pageNumber, id: chosenBranch, type}, 
      staffId, 
      selectedPaymentType, 
      selectedCustomerId,
      searchTransactionId
    )
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetPaymentOptions = async () => {
    this.setState({
      paymentLabelLoading: true
    })
    this.props.getAllPaymentLabel()
    .then((res) => {
      this.setState({
        paymentLabelLoading: false,
        allPaymentLabels: res.data,
      })
    })
    .catch((err) => {
      this.setState({
        paymentLabelLoading: false
      })
      message.error(`unable to get payment options, ${err.message}`);
    });
  };

  handleGetSalesByTransactionId = (transactionId, pageNumber, pageSize) => {
    const { selectedStaffDetails, selectedBranch, selectedCustomerId,selectedPaymentType, begin, end,filterByVat  } = this.state;
    const { allBranches, getBranchSales, getBranchSalesByDuration } = this.props;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch
    }

    let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null 

    // this.setState({ loading: true });
    // this.props.getSalesByPaymentType(chosenBranch, selectedPaymentType, pageNumber, pageSize)
    if(begin && end){
      this.setState({ loading: true });
      getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        chosenBranch,
        pageNumber,
        "default",
        selectedPaymentType,
        staffId,
        transactionId,
        filterByVat
      )
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: begin,
          end: end,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        message.error(err.response.data.error || strings.errorFetchingRecord);
        this.setState({ loading: false })
      });
      return;
    }
    
    let type = "default";
    this.setState({ loading: true });
    getBranchSales({current: pageNumber, id: chosenBranch, type}, staffId, selectedPaymentType, selectedCustomerId,transactionId,filterByVat)
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetSalesByVAT = (allowVat, pageNumber, pageSize) => {
    const { selectedStaffDetails, selectedBranch, selectedCustomerId,selectedPaymentType, begin, end,searchTransactionId  } = this.state;
    const { allBranches, getBranchSales, getBranchSalesByDuration } = this.props;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch
    }

    let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null 

    // this.setState({ loading: true });
    // this.props.getSalesByPaymentType(chosenBranch, selectedPaymentType, pageNumber, pageSize)
    if(begin && end){
      this.setState({ loading: true });
      getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        chosenBranch,
        pageNumber,
        "default",
        selectedPaymentType,
        staffId,
        searchTransactionId,
        allowVat
      )
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: begin,
          end: end,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err);
        message.error(err.response.data.error || strings.errorFetchingRecord);
        this.setState({ loading: false })
      });
      return;
    }
    
    let type = "default";
    this.setState({ loading: true });
    getBranchSales(
      {current: pageNumber, id: chosenBranch, type}, 
      staffId, 
      selectedPaymentType, 
      selectedCustomerId,
      searchTransactionId,
      allowVat
    )
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetSalesByFilter = (pageNumber, branch_id) => {
    const { selectedStaffDetails, selectedBranch, selectedPaymentType, selectedCustomerId,begin,end, searchTransactionId,filterByVat  } = this.state;
    const { allBranches, getBranchSales, getBranchSalesByDuration } = this.props;
    let chosenBranch;
    if(branch_id){
      chosenBranch = branch_id;
    }else if(!selectedBranch && !branch_id){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch;
    }

    let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null; 
    if(begin && end){
      this.setState({ loading: true });
      getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        chosenBranch,
        pageNumber,
        "default",
        selectedPaymentType,
        staffId,
        searchTransactionId,
        filterByVat
      )
      .then((response) => {
        if (response?.data.length > 0) {
          merchantHasSale(true);
        }
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-profit"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard: response.headers["total-amount-with-card"],
          totalPaymentWithCash: response.headers["total-amount-with-cash"],
          totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-amount-with-mtransfer"],
          begin: begin,
          end: end,
          currentPage: Number(response.headers.page),
          pageSize: Number(response.headers["per-page"])
        });
      })
      .catch((err) => {
        console.log("err",err.response);
        message.error(err.response.data.error || strings.errorFetchingRecord);
        this.setState({ loading: false })
      });
      return;
    }
    let type = "default";
    this.setState({ loading: true });
    getBranchSales({current: pageNumber, id: chosenBranch, type}, staffId, selectedPaymentType, selectedCustomerId,searchTransactionId,filterByVat)
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleResetByFilter = (pageNumber, filtername) => {
    const { selectedStaffDetails, selectedBranch, selectedPaymentType, selectedCustomerId  } = this.state;
    const { allBranches, getBranchSales } = this.props;
    let chosenBranch;
   if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch;
    }

    let staffId = filtername === "staff" ? null : selectedStaffDetails ? selectedStaffDetails?.staff_id : null; 
    let type = "default";
    let payment_type = filtername === "payment" ? null : selectedPaymentType;
    let selectedCust = filtername === "customer" ? null : selectedCustomerId;

    this.setState({ loading: true });

    getBranchSales(
      {current: pageNumber, id: chosenBranch, type}, 
      staffId, 
      payment_type, 
      selectedCust
    )
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: null,
        end: null,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err.response);
      message.error(err.response.data.error || strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleFilterByVat = (value) => {
    this.setState({ filterByVat: value });
    this.handleGetSalesByVAT(value, this.state.currentPage);
  }

  render() {
    const {
      reports,
      isExportingSales,
      exportData,
      totalPaymentWithCard,
      totalPaymentWithCash,
      totalPaymentWithMobile,
      totalPaymentWithMTransfer,
      filterValue,
      totalRecords,
      totalProfit,
      currentPage,
      amountInRange,
      totalAmount,
      begin,
      end,
      pageSize,
      selectedBranch,
      selectedBranchName,
      selectedStaffDetails,
      isBranchSelected,
      sendingMailLoading,
      isPaymentTypeSelected,
      selectedPaymentType,
      isCustomerSelected,
      selectedCustomerName,
      paymentName,
      filterByVat,
      searchTransactionId,
      selectedCustomerId,
      isStaffSelected,
      totalRangeProfit,
    } = this.state;

    const { user, allBranches } = this.props;

    const acceptedHeaders = [
      {
        label: strings.customerName,
        key: "customerName",
      },
      {
        label: strings.total,
        key: "total",
      },
      {
        label: strings.date,
        key: "created_at",
      },
      {
        label: strings.paymentType,
        key: "paymentType",
      },
      {
        label: strings.totalSellingPrice,
        key: "total_selling_price",
      },
      {
        label: strings.totalCostPrice,
        key: "total_cost_price",
      },
      // {
      //   label: "Customer Email",
      //   key: "customer.email",
      // },
      // {
      //   label: "Customer Phone",
      //   key: "customer.phone_number",
      // },
      // {
      //   label: "Cash Payment",
      //   key: "is_paid_with_cash",
      // },
      // {
      //   label: "Card Payment",
      //   key: "is_paid_with_card",
      // },
      // {
      //   label: "Mobile Payment",
      //   key: "is_paid_with_mobile",
      // },
      {
        label: strings.appliedDiscount,
        key: "has_discount",
      },
      {
        label: strings.discountAmount,
        key: "discount_amount",
      },
      {
        label: strings.paymentReference,
        key: "payment_reference",
      },
      {
        label: strings.channel,
        key: "channel",
      },
      {
        label: strings.profit,
        key: "total_profit",
      },
      {
        label: "Staff Email",
        key: "staff_email",
      },
      {
        label: "Product Details",
        key: "products",
      },
      {
        label: strings.noOfItems,
        key: "transactions.length",
      },
    ];

    const isAdminIsManager =
      user.role_id === 1 || (user.role && user.role.id === 2);
    const isAdmin = user.role_id === 1;

    const currency = user.currency || user.employer.currency;

    const title = selectedBranchName ? `${selectedBranchName} ${strings.sales}: ${`${currency}${numberFormatter(
      totalAmount || 0
    )}`} (${totalRecords || 0})` : `${strings.sales}: ${`${currency}${numberFormatter(
      totalAmount || 0
    )}`} (${totalRecords || 0})`;

    const borderDisplay = {
      display: 'flex',
      alignItems: "center",
      borderWidth:1,
      borderStyle: "solid",
      borderColor: "#D90068",
      padding: 7,
      position: "relative",
      fontSize:14,
      flexWrap: "wrap"
    }

    const items = [
      {
        key: '1',
        label: (
          <Button
            style={{ width: "100%" }}
            onClick={this.handleSendSalesToMail}
            loading={sendingMailLoading}
          >
            {strings.sendReportToMail}
          </Button>
        ),
      }
    ]

    return (
      <main>
        <Card
          title={title}
          loading={this.state.loading}
          extra={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
                  <RangePicker
                    placeholder={[strings.startDate, strings.endDate]}
                    onChange={this.handleDurationChange} 
                    value={[begin, end]}
                  />
                  {/* {isAdmin && 
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                      <span style={{ margin: "0 10px 0",wordBreak: "break-all" }}>{strings.viewBranchSales}:</span>
                      <Select 
                        onChange={this.handleBranchSelect} 
                        style={{width: "100%"}}
                        placeholder="Select branch"
                      >
                        {allBranches.length > 0 && allBranches.map((branch) => {
                          return (<Option key={branch.id} value={branch.id} >{branch.name}</Option>)
                        })}
                      </Select>
                    </div>  
                  } */}
                </div>
                
                {isAdminIsManager && (
                    <Dropdown  menu={{ items }}>
                      <Button
                        style={{ marginLeft: "1.5rem" }}
                        loading={isExportingSales || sendingMailLoading}
                        onClick={() => {
                          this.handleSalesExport();
                        }}
                        type="primary"
                      >
                        {strings.export}
                      </Button>
                    </Dropdown>
                )}
                
                {exportData.length > 0 && (
                  <CSVLink
                    id="csvDownloadBtn"
                    data={exportData}
                    headers={acceptedHeaders}
                    filename={selectedBranchName ? `${
                      !begin && !end
                        ? `${selectedBranchName}_SalesRecord.csv`
                        : `${selectedBranchName}_SalesRecord_from_${begin.format().split("T")[0]}__${
                            end.format().split("T")[0]
                          }.csv`
                    }` : `${
                      !begin && !end
                        ? "AllSalesRecord.csv"
                        : `SalesRecord${begin.format().split("T")[0]}__${
                            end.format().split("T")[0]
                          }.csv`
                    }`}
                    // asyncOnClick={true}
                    ref={this.csvLinkRef}
                    target="_blank"
                    children=""
                  />
                )}
              </div>
            </div>
          }
        >
          {/* <Tabs></Tabs> */}
          <div className="t-div">
            <div style={{display:"flex", alignItems:"center"}}>
              {isAdmin && (
                <span style={{ margin: "0 10px" }}>
                  {strings.totalProfit}: {currency}
                  {begin && end ? numberFormatter(totalRangeProfit || 0) : numberFormatter(totalProfit || 0)}
                </span>
              )}

              {begin && end && (
                <span style={{ margin: "0 10px" }}>
                  {strings.totalBalanceInPeriod}:
                  {`${currency}${numberFormatter(amountInRange || 0)}`}
                </span>
              )}

              <PaymentMethodTotal
                user={user}
                totalPaymentWithCard={totalPaymentWithCard}
                totalPaymentWithCash={totalPaymentWithCash}
                totalPaymentWithMTransfer={totalPaymentWithMTransfer}
                totalPaymentWithMobile={totalPaymentWithMobile}
                filterValue={filterValue}
              />
            </div>

            <br/><div style={{display: "flex", alignItems: "center", gap: 20}}>
              <p style={{margin: 0}}>{strings.showTransactionWithVat}</p>

              <Switch onChange={this.handleFilterByVat} checked={filterByVat} />
            </div>

            <div className="wrap-div">
              {selectedBranchName && (
                <div 
                  style={borderDisplay}
                  className="borderDisplay"
                >
                  <p style={{margin: 0}}>{strings.branch} - </p>
                  <p style={{margin: 0}}>{selectedBranchName}</p>
                  {/* <Icon 
                    type="close" 
                    style={{position: "absolute", right: 0, top: 0, cursor: "pointer", color: "#D90068"}}
                    onClick={() => {
                      this.setState({
                        isBranchSelected: false,
                        selectedBranch: null,
                        selectedBranchName: null,
                      });

                      this.handleResetByFilter(currentPage, "branch")
                    }}
                  /> */}
                </div>
              )}

              {selectedStaffDetails && (
                <div style={borderDisplay} className="borderDisplay">
                  <p style={{margin: 0}}>{strings.staff} - </p>
                  <p style={{margin: 0}}>{selectedStaffDetails.staff_name}</p>
                  <Icon 
                    type="close" 
                    style={{position: "absolute", right: 0, top: 0, cursor: "pointer", color: "#D90068"}}
                    onClick={() => {
                      this.setState({
                        isStaffSelected: false,
                        selectedStaffDetails: null,
                      });

                      this.handleResetByFilter(currentPage, "staff");
                    }}
                  />
                </div>
              )}

              {selectedPaymentType && (
                <div style={borderDisplay} className="borderDisplay">
                  <p style={{margin: 0}}>{strings.paymentType} - </p>
                  <p style={{margin: 0}}>{paymentName}</p>
                  <Icon 
                    type="close" 
                    style={{position: "absolute", right: 0, top: 0, cursor: "pointer", color: "#D90068"}}
                    onClick={() => {
                      this.setState({
                        isPaymentTypeSelected: false,
                        selectedPaymentType: null,
                        paymentName: null
                      });

                      this.handleResetByFilter(currentPage, "payment")
                    }}
                  />
                </div>
              )}

              {isCustomerSelected && (
                <div style={borderDisplay} className="borderDisplay">
                  <p style={{margin: 0}}>{strings.customer} - </p>
                  <p style={{margin: 0}}>{selectedCustomerName}</p>
                  <Icon 
                    type="close" 
                    style={{position: "absolute", right: 0, top: 0, cursor: "pointer", color: "#D90068"}}
                    onClick={() => {
                      this.setState({
                        isCustomerSelected: false,
                        selectedCustomerName: null,
                        selectedCustomerId: null,
                      });

                      this.handleResetByFilter(currentPage, "customer")
                    }}
                  />
                </div>
              )}

              {isBranchSelected || selectedStaffDetails || selectedCustomerName || isPaymentTypeSelected ? (
                <Button
                  style={{ marginLeft: "1.5rem" }}
                  loading={isExportingSales}
                  onClick={() => {
                    this.setState({
                      loading: true,
                      isBranchSelected: false
                    });
                    this.handleResetAll();
                  }}
                  type="primary"
                >
                  {strings.reset}
                </Button>
              ): null}
            </div>

          </div>

          <section className="invoices-table mt-40">
            <Transactions
              transactions={reports || []}
              updateFilterValue={this.updateFilterValue}
              resetTransactions={this.resetTransactions}
              total={totalRecords}
              currentPage={currentPage}
              getAllSales={pageNumber => {
                console.log("page", pageNumber);
                this.setState({
                  loading: true,
                });
                if(user.business_branch){
                  this.handleGetSalesByFilter(pageNumber.current);
                }else{
                  if(selectedCustomerId || selectedPaymentType || filterByVat || isStaffSelected || isBranchSelected || searchTransactionId){
                    this.handleGetSalesByFilter(pageNumber.current)
                  }else{
                    this.handleGetAllSales(pageNumber);
                  }
                }
              }}
              getSalesRange={pageNumber => {
                const { isStaffSelected, selectedStaffDetails, isBranchSelected, selectedBranch  } = this.state;

                this.setState({
                  loading: true,
                });
                
                if(isStaffSelected && isBranchSelected ){
                  this.handleGetStaffSales(selectedStaffDetails.staff_id, selectedBranch, pageNumber, begin, end)
                }else if(isStaffSelected && !isBranchSelected){
                  this.handleGetStaffSales(selectedStaffDetails.staff_id, selectedStaffDetails.branch_id, pageNumber, begin, end)
                }else{
                  this.handleGetSalesRange(pageNumber, begin, end);
                }
              }}
              loading={this.state.loading}
              user={this.props.user}
              begin={begin}
              end={end}
              pageSize={pageSize}
              handleBranchSelect={this.handleBranchSelect}
              handleTransactionIdSelect={this.handleTransactionIdSelect}
              selectedBranch={selectedBranch}
              handleResetTransactions={this.handleResetTransactions}
              allStaffs={this.state.allStaffs}
              handleGetStaffSales={this.handleGetStaffSales}
              handleSetStaffIsSelected={this.handleSetStaffIsSelected}
              isStaffSelected={this.state.isStaffSelected}
              selectedStaffDetails={this.state.selectedStaffDetails}
              isPaymentTypeSelected={isPaymentTypeSelected}
              selectedPaymentType={selectedPaymentType}
              handleSelectPaymentType={this.handleSelectPaymentType}
              handleGetSalesByCustomerName={this.handleGetSalesByCustomerName}
              handleGetSalesByCustomerId={this.handleGetSalesByCustomerId}
              handleSelectCustomer={this.handleSelectCustomer}
              salespage
              allPaymentLabels={this.state.allPaymentLabels}
            />
          </section>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  products: state.product.allProducts,
  customers: state.customer.allCustomers,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, {
  getAllSales,
  getAllSalesByDuration,
  merchantHasSale,
  getBranchSales,
  getBranchSalesByDuration,
  getAllBusinessBranches,
  getCashierSales,
  sendSalesReportToEmail,
  getSalesByCustomerName,
  getSalesByPaymentType,
  getSalesByCustomerId,
  getAllPaymentLabel
})(Reports);
