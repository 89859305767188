import React, { useState } from "react";
import { Modal, Input, DatePicker, Select, Button, message, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import banks from "../../../../../../banks.json";

// Redux
import { connect } from "react-redux";
import {
  addNewCustomer,
  getAllCustomers,
  updateCustomers,
} from "../../../../../../redux/actions/customerActions";
import "./index.scss";
import "react-phone-input-2/lib/style.css";
import strings from "../../../../../../strings";
import api from "../../../../../../api";

const FormItem = Form.Item;
const Option = Select.Option;

const dropdownOptions = [
  "Saloon",
  "SUV & Space Bus",
  "Truck",
  "14 Seater Bus",
  "18 Seater Bus",
  "32 Seater Bus",
  "36 Seater Bus",
  "Pickup Truck",
  "Cargo Truck",
];

const RenderFields = ({ getFieldDecorator, customFields }) => {
  const [value, setValue] = useState(null);
  return (
    <>
      <FormItem label="Custom Field">
        <Select
          onChange={(val) => setValue(val)}
          size="large"
          placeholder="Select custom field"
        >
          {customFields.map((field) => (
            <Option value={field}>{field}</Option>
          ))}
        </Select>
      </FormItem>
      {value && (
        <FormItem label={value}>
          {getFieldDecorator(value)(
            value.slice(0, 12).toLowerCase() === "vehicle type" ? (
              <Select size="large">
                {dropdownOptions.map((option) => (
                  <Option value={option}>{option}</Option>
                ))}
              </Select>
            ) : (
              <Input size="large" />
            )
          )}
        </FormItem>
      )}
    </>
  );
};

class AddCustomerModal extends React.Component {
  state = {
    loading: false,
    country: "",
    state: "",
    fieldNumbers: 0,
    customFields: [],
    showMoreFields: false,
    // bvnValue: null
  };

  componentDidMount() {
    this.getFields();
    this.fetchCustomerGroups();
  }

  getFields = () => {
    api.merchants.getCustomFields().then((res) => {
      if (res.status === 200) {
        this.setState({ customFields: res.data.data });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  fetchCustomerGroups = () => {
    api.HttpClient.get("/customer_groups")
      .then((res) => {
        if (res.status && res.status === 200) {
          return this.setState({ customerGroups: res.data });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  handleAddCustomer = () => {
    const { form, updateCustomers, closeModal, customers, user, isAddCustomer, updateCustomerAfterAdd, pageSize,
    pageNumber } = this.props;
    const { country, state } = this.state;

    form.validateFields((err, values) => {
      if (err) return message.error("Please enter all required fields!");

      if (user.email.match("ulesson")) {
        if (!country || !state)
          return Modal.error({
            title: "Please select your country and state to continue.",
          });
      }

      const selectedBank =
        values.bank_ussd_code &&
        banks.filter((bank) => bank.name === values.bank_ussd_code);

      let phone_number =
        values.phone_number[0] === "+"
          ? values.phone_number
          : `+${values.phone_number}`;

      phone_number = phone_number.split(" ").join("");

      const isExistingCustomer = customers.find((customer) => {
        return (
          customer.email === values.email ||
          customer.phone_number === phone_number
        );
      });

      if (isExistingCustomer) {
        Modal.error({
          title: strings.customerWithEmailAlreadyExists,
        });
        return;
      }

      this.setState({ loading: true });

      const customValues = [];

      for (let field of this.state.customFields) {
        if (values[field])
          customValues.push({
            name: field.toLowerCase(),
            value: values[field],
          });
      }

      this.props
        .addNewCustomer({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          date_of_birth: values.date_of_birth
            ? values.date_of_birth.format("YYYY-MM-DD")
            : "",
          phone_number: phone_number,
          sex: values.sex,
          state,
          country,
          bank_ussd_code: selectedBank ? selectedBank[0].code : "",
          postcode: values.postcode,
          address_line1: values.address_line1,
          address_line2: values.address_line2,
          custom_data: customValues,
          group_id: values.group_id,
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            this.props.form.resetFields();
            this.setState({ loading: false });

            if (this.props.addCustomerToLoyalty) {
              this.props.addCustomerToLoyalty({
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                customer_id: res.data.user_id,
              });
              this.props.getAllCustomers(1, 10000);
            }

            Modal.success({
              title: strings.successfullyAddedNewCustomer,
              onOk: () => {
                this.props.getAllCustomers(pageNumber, pageSize)
                .then((resp) => {
                  if(isAddCustomer){
                    const id = res.data && res.data.user_id;
                    const userName = `${res.data && res.data.first_name} ${res.data && res.data.last_name || ""}: ${res.data && res.data.phone_number}`;

                    updateCustomerAfterAdd(id, userName);         
                  }
                });
                updateCustomers(res.data);
                closeModal();
              },
            });
          }
        });
    });
  };

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ state: val });
  }

  handleFieldShow = () => {
    this.setState({
      showMoreFields: !this.state.showMoreFields
    })
  }

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
      handleAddCustomer,
      user,
    } = this.props;

    const isCommission =
      user.commission === null ||
      (user.employer && user.employer.commission === null);

    const isUlessonAccount = user.email.match("ulesson");

    const { loading, country, state, fieldNumbers, customFields } = this.state;

    const renderFields = (number) =>
      new Array(number).fill(
        <RenderFields
          getFieldDecorator={getFieldDecorator}
          customFields={customFields}
        />
      );

    return (
      <Modal
        title={strings.addCustomer}
        open={showModal}
        okText={strings.addCustomer}
        cancelText={strings.cancel}
        okButtonProps={{ loading: loading }}
        onCancel={closeModal}
        onOk={handleAddCustomer ? handleAddCustomer : this.handleAddCustomer}
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
      >
        <Form layout="vertical">
          <div id="add-customer">
            <FormItem label={strings.firstName}>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterCustomerFirstName,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>

            <FormItem label={strings.lastName}>
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: false,
                    message: strings.pleaseEnterCustomerLastName,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>

            <FormItem label={strings.email} type="email">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: false,
                    message: strings.pleaseEnterCustomerEmail,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>

            <FormItem>
              {getFieldDecorator("phone_number", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterCustomerPhoneNumber,
                  },
                ],
              })(
                <PhoneInput
                  inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                  inputStyle={{
                    border: "1px solid #d9d9d9",
                    width: "100%",
                    height: "40px",
                  }}
                  placeholder={strings.phoneNumber}
                  country="ng"
                />
              )}
            </FormItem>


            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormItem label={strings.dateOfBirth}>
                {getFieldDecorator("date_of_birth", {
                  rules: [
                    {
                      type: "object",
                    },
                  ],
                })(<DatePicker size="large" style={{ width: "100%" }} />)}
              </FormItem>

              <FormItem label={strings.gender}>
                {getFieldDecorator("sex", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseSelectCustomerGender,
                    },
                  ],
                })(
                  <Select size="large" style={{ width: "200px !important" }}>
                    <Option value="M">{strings.male}</Option>
                    <Option value="F">{strings.female}</Option>
                  </Select>
                )}
              </FormItem>
            </div>
            {this.state.showMoreFields && 
              <><FormItem label={strings.addressLine1}>
              {getFieldDecorator("address_line1", {
                rules: [
                  {
                    required: isUlessonAccount,
                    message: strings.pleaseEnterCustomerAddressLine,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>

            <FormItem label={strings.addressLine2}>
              {getFieldDecorator("address_line2", {
                rules: [
                  {
                    required: isUlessonAccount,
                    message: strings.pleaseEnterCustomerOtherAddress,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>

            {user.currency === "NGN" && !isCommission && (
              <FormItem label={strings.bank}>
                {getFieldDecorator("bank_ussd_code")(
                  <Select
                    placeholder={strings.selectBank}
                    allowClear
                    size="large"
                  >
                    {banks.map((bank, key) => (
                      <Option key={bank.code} value={bank.name}>
                        {bank.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}

            {/* (user.commission || user.employer.commission) && */}

            <div className="field-wrapper">
              <label>{strings.country}</label>
              <CountryDropdown
                value={country}
                defaultOptionLabel={strings.selectCountry}
                onChange={(val) => this.selectCountry(val)}
                className="ant-input-lg ant-input"
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="field-wrapper"
                style={{ margin: 0, marginTop: "8px", width: "220px" }}
              >
                <label style={{ marginBottom: "7px" }}>
                  {strings.stateOrRegionOrProvince}
                </label>
                <RegionDropdown
                  country={country}
                  value={state}
                  defaultOptionLabel={strings.selectRegion}
                  onChange={(val) => this.selectRegion(val)}
                  className="ant-input-lg ant-input"
                />
              </div>

              <FormItem label={strings.postCode}>
                {getFieldDecorator("postcode", {
                  rules: [
                    {
                      required: false,
                      message: strings.pleaseEnterPostCode,
                    },
                  ],
                })(<Input size="large" />)}
              </FormItem>
            </div>

            <FormItem label={strings.customerNotes}>
              {getFieldDecorator("notes")(
                <Input.TextArea size="large" rows={2} />
              )}
            </FormItem></>
            }
          </div>

          {this.state.showMoreFields && this.state.customerGroups && (
            <FormItem label="Customer Group">
              {getFieldDecorator("group_id")(
                <Select size="large" placeholder="Select Customer Group">
                  <Select.Option value="">None</Select.Option>
                  {this.state.customerGroups.map((group) => (
                    <Select.Option
                      value={group.id}
                      key={group.id}
                    >{`${group.name} (${group.customers.length} customers)`}</Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}

          {renderFields(fieldNumbers)}

          {this.state.showMoreFields && <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              disabled={fieldNumbers === customFields.length}
              type="primary"
              onClick={() =>
                this.setState((prev) => ({
                  fieldNumbers: prev.fieldNumbers + 1,
                }))
              }
            >
              Add Custom Fields
            </Button>
          </div>}

          <div>{this.state.showMoreFields ? <a onClick={this.handleFieldShow}>show less fields...</a> : <a onClick={this.handleFieldShow} >show more fields...</a>}</div>
        </Form>
      </Modal>
    );
  }
}

const AddCustomerForm = Form.create()(AddCustomerModal);

AddCustomerForm.propTypes = {
  error: PropTypes.string.isRequired,
  addNewCustomer: PropTypes.func.isRequired,
  getAllCustomers: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  customers: state.customer.allCustomers,
  error: state.error,
  isCredPalActive: state.appStore.isCredPalActive
});

export default connect(mapStateToProps, {
  addNewCustomer,
  getAllCustomers,
  updateCustomers,
})(AddCustomerForm);
