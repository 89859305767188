import React from "react";
import ReactToPrint from "react-to-print";
import { Modal, Button, message, Checkbox } from "antd";
import throttle from "lodash/throttle";
//import { formatTransactionDate } from "../../utils";
import cloneArray from "../../utils/cloneArray";
import SalesReceipt from "./_partials/SalesReceipt";
import {
  ENROLL_CUSTOMER_WITH_AMOUNT_SPENT,
  LIST_LOYALTIES,
  LIST_TIERS,
} from "../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import strings from "../../strings";
import { loginToVpay } from "../Dashboard/Layouts/Account/functions";
import Axios from "axios";
import moment from "moment";
import api from "../../api";


const toastMessageDuration = 5; //sec

const getCategoryName = (id, categories) => {
  const category = categories.find((one) => one.id === id);
  return category ? category.name : "Uncategorized";
};

const addClusterPurchase = async (reference, cartList, categories) => {
  const loginRes = await loginToVpay();
  const token = loginRes.data.token;
  let description = "",
    product_category = "",
    quantity = "";

  for (let product of cartList) {
    description += `${product.name} x ${product.quantity} - ${product.amount} | `;
    quantity += product.quantity;
    product_category += getCategoryName(
      product.merchant_product_category_id,
      categories
    );
  }

  const payload = {
    description,
    product_category,
    quantity,
    transaction_reference: reference,
  };
  const headers = {
    "b-access-token": token,
    publicKey: "2d14ba1b-4819-4af6-923e-ec7a21056f50",
  };
  Axios.post(
    "https://www.vpay.africa/api/service/v1/query/merchant/cluster/purchase/add",
    payload,
    { headers }
  )
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.log({ err }));
};


export const reduceItemFromCart = function (Item) {
  const location = this.props?.location?.state
  const { user, isNitroActive } = this.props;
  const  { isPermitted, cartList } = this.state;
  
  if(location?.fromDraftSales){
    if(isPermitted === false){
      if(!user?.role_id){  
        return this.setState({ isAuthModal: true })
      }
    }
  }
  const item = {...Item};
  if( isNitroActive ){
    const nitroCartItem = { ...item};

    const newcartList = cartList.filter((item)=>{
      return item.id !== nitroCartItem.id;
    });

    if (nitroCartItem.variants.length > 0){
      const isItemInCartlist = cartList.filter((item)=>{
        return item.id === nitroCartItem.id;
      });

      if(isItemInCartlist.length === 1){
        console.log("fisrt place");
        if( Number(nitroCartItem.quantity) <= 1){
          if(newcartList.length === 0){
           return this.setState({ cartList: [], products: []})
          }else{
            return this.setState({ cartList: [ ...newcartList ], products: []})
          }
        }
        // nitroCartItem.quantity = Number(nitroCartItem.quantity) - 1;
  
        // nitroCartItem.amount = Number(nitroCartItem.price) * Number(nitroCartItem.quantity);
  
        // item.quantity = Number(item.quantityCheck) - Number(nitroCartItem.quantity);

        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            item.quantity = item.quantity - 1
            item.amount = Item.price * item.quantity; 
            if(item.track_inventory){
              nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
  
              this.setState({ products: [ nitroCartItem ]})
            }
          }
        })
        this.setState({ cartList: cartList });
      }else{
        const notVariantItem = isItemInCartlist.filter((item)=>{
          return item.variants[0].id !== nitroCartItem.variants[0].id
        });

        const variantItem = isItemInCartlist.filter((item)=>{
          return item.variants[0].id === nitroCartItem.variants[0].id
        });

        if( Number(nitroCartItem.quantity) <= 1){
          return this.setState({ cartList: [...notVariantItem, ...newcartList] })
        }

        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            if(item.variants[0].id == variantItem[0].variants[0].id){
              item.quantity = item.quantity - 1
              item.amount = Item.price * item.quantity; 
              if(item.track_inventory){
                const q1 = isItemInCartlist.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
                nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(q1);
                // nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
    
                this.setState({ products: [ nitroCartItem ]})
              }
            }
          }
        });

        this.setState({ cartList: cartList });
      }
    }else if (nitroCartItem.custom_quantities.length > 0){
      const isItemInCartlist = cartList.filter((item)=>{
        return item.id === nitroCartItem.id;
      });

      if(isItemInCartlist.length === 1){
        if( Number(nitroCartItem.quantity) <= 1){
          if(newcartList.length === 0){
           return this.setState({ cartList: [], products: []})
          }else{
            return this.setState({ cartList: [ ...newcartList ], products: []})
          }
        }
        // nitroCartItem.quantity = Number(nitroCartItem.quantity) - 1;
  
        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            item.quantity = item.quantity - 1
            item.amount = Item.price * item.quantity; 
            if(item.track_inventory){
              nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
  
              this.setState({ products: [ nitroCartItem ]})
            }
          }
        })

        this.setState({ cartList: cartList });
       
      }else{
        const notVariantItem = isItemInCartlist.filter((item)=>{
          return item.custom_quantities[0].id !== nitroCartItem.custom_quantities[0].id
        });

        const variantItem = isItemInCartlist.filter((item)=>{
          return item.custom_quantities[0].id === nitroCartItem.custom_quantities[0].id
        });
        
        if( Number(nitroCartItem.quantity) <= 1){
          const yui = notVariantItem.reduce((prev, curr)=>{
            return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity));
          }, 0);

          item.quantity = Number(item.quantityCheck) - Number(yui);

          if( Number(nitroCartItem.quantity) <= 1){
            return this.setState({ cartList: [...notVariantItem, ...newcartList]})
          }
        }
       

        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            if(item.custom_quantities[0].id == variantItem[0].custom_quantities[0].id){
              item.quantity = item.quantity - 1
              item.amount = Item.price * item.quantity; 
              if(item.track_inventory){
                const q1 = isItemInCartlist.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
                nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(q1);
                // nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
    
                this.setState({ products: [ nitroCartItem ]})
              }
            }
          }
        });

        this.setState({ cartList: cartList });
      }
    }else{
      if( Number(nitroCartItem.quantity) <= 1 ){
        if(newcartList.length === 0){
          this.setState({ cartList: [], products: []})
        }else{
          this.setState({ cartList: [ ...newcartList ], products: []})
        }
      }else{
        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            const price = nitroCartItem.price;
      
            item.quantity = item.quantity - 1;
            item.amount = price * item.quantity;
            item.points = nitroCartItem.points ? nitroCartItem.points : 0;
            item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
            item.product_id = nitroCartItem.id;
            item.business_branch_id = user.business_branch
              ? user.business_branch.id
              : null;
            item.product_type = "product";
            item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
            if(item.track_inventory){
              nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
  
              this.setState({ products: [ nitroCartItem ]})
            }
          }
        })

        this.setState({ cartList: cartList })
      }
    }
    
    return;
  }

  if (item.product_type === "service") return this.reduceServiceFromCart(item);
  const productsInStore = this.props.user.employer
    ? cloneArray(this.props.branchProducts)
    : cloneArray(this.props.products);

  // let productItemInStoreCopy = productsInStore.filter(
  //   (product) => product.id === item.id
  // )[0];
  let productItemInStoreCopy;

    if(item.item_id !== undefined){
      productItemInStoreCopy = productsInStore?.filter(
        (product) => product.id === item.item_id
      )[0];
    }else{
      productItemInStoreCopy = productsInStore?.filter(
        (product) => product.id === item.id
      )[0];
    }

    if(item.custom_quantities && item.custom_quantities.length > 0){
      productItemInStoreCopy.quantity = productItemInStoreCopy.quantity + Number(item.custom_quantities[0].quantity)
    }else{
      productItemInStoreCopy.quantity += 1;
    }
  
  if(item.variant && item.variants.length > 0){
    productItemInStoreCopy.variants.map((variant)=>{
      if(Number(variant.id) === Number(item.variants[0].id)){
        variant.quantity = (Number(variant.quantity) + 1).toFixed(1);
      }else{
        variant.quatity = variant.quantity
      }
    })
  }
  

  this.props.updateProductItemInStore(productsInStore, productItemInStoreCopy);
  updateProductItemInState(productsInStore, productItemInStoreCopy, this);
  if(!item.variants)item.variants = productItemInStoreCopy.variants
  if(item.custom_quantities.length > 0){

    const price =
      item.custom_quantities.length > 0 ? item.custom_quantities[0].price : item.price;
    if (item.quantity === 1) return this.removeItemFromCart(item);
   
    const productItem = { ...item };
    const cartList = cloneArray(this.state.cartList);
    cartList.forEach((cartItem) => {
      cartItem.custom_quantities.sort((a, b) => {
        return a.id < b.id;
      });
      if (
        cartItem.id === productItem.id &&
        JSON.stringify(item.custom_quantities) === JSON.stringify(cartItem.custom_quantities)
      ) {
        cartItem.quantity = cartItem.quantity - 1;
        cartItem.amount = price * cartItem.quantity;
        if (productItem.tax_rate) {
          // cartItem.amount =
          //   ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
          //     2
          //   ) * cartItem.quantity;
          cartItem.amount = parseFloat(price).toFixed(2) * cartItem.quantity;
        }
      }
      this.setState({ cartList: [...cartList] });
    });
  }else if (item.variants.length === 0) {
    if (item.quantity === 1) return this.removeItemFromCart(item);

    const productItem = { ...item };

    const cartList = cloneArray(this.state.cartList);
    cartList.forEach((cartItem) => {
      if (cartItem.id === productItem.id) {
        cartItem.quantity = cartItem.quantity - 1;
        cartItem.amount = cartItem.price * cartItem.quantity;
      }
      this.setState({ cartList: [...cartList] });
    });
  }else {
    const price =
      item.variants.length > 0 ? item.variants[0].price : item.price;
    if (item.quantity === 1) return this.removeItemFromCart(item);
    item.variants.sort((a, b) => {
      return a.id < b.id;
    });
    const productItem = { ...item };
    const cartList = cloneArray(this.state.cartList);
    cartList.forEach((cartItem) => {
      cartItem.variants.sort((a, b) => {
        return a.id < b.id;
      });
      if (
        cartItem.id === productItem.id &&
        JSON.stringify(item.variants) === JSON.stringify(cartItem.variants)
      ) {
        cartItem.quantity = cartItem.quantity - 1;
        cartItem.amount = price * cartItem.quantity;
        if (productItem.tax_rate) {
          cartItem.amount =
            ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
              2
            ) * cartItem.quantity;
        }
        cartItem.variants.forEach((variant) => {
          variant.quantityLeft = cartItem.quantity;
        });
      }
      this.setState({ cartList: [...cartList] });
    });
  }
};

export const removeItemFromCart = function (Item) {
  const location = this.props?.location?.state
  const { user, isNitroActive } = this.props;
  const  { isPermitted, cartList } = this.state;

  if(location?.fromDraftSales){
    if(isPermitted === false){
      if(!user?.role_id){  
        return this.setState({ isAuthModal: true })
      }
    }
  }

  const item = {...Item};

  if( isNitroActive ){
    const nitroCartItem = { ...item};

    const newcartList = cartList.filter((item)=>{
      return item.id !== nitroCartItem.id;
    });
    
    if(nitroCartItem.variants.length > 0){
      const isItemInCartlist = cartList.filter((item)=>{
        return item.id === nitroCartItem.id;
      });

      if(isItemInCartlist.length === 1){
        if(newcartList.length === 0){
          this.setState({ cartList: [], products: []})
        }else{
          this.setState({ cartList: [ ...newcartList ], products: []})
        }
      }else{
        const notVariantItem = isItemInCartlist.filter((item)=>{
          return item.variants[0].id !== nitroCartItem.variants[0].id
        });

        const nu = notVariantItem.reduce((prev, curr)=>{
          return Number(prev) + Number(curr.quantity)
        }, 0);

        item.quantity = Number(item.quantityCheck) - nu;

        if(newcartList.length === 0){
          this.setState({ cartList: [ ...notVariantItem ], products: [ item ]})
        }else{
          this.setState({ cartList: [ ...newcartList, ...notVariantItem ], products: [ item ]})
        }
      }
    }else if (nitroCartItem.custom_quantities.length > 0){
      const isItemInCartlist = cartList.filter((item)=>{
        return item.id === nitroCartItem.id;
      });

      if(isItemInCartlist.length === 1){
        if(newcartList.length === 0){
          this.setState({ cartList: [], products: []})
        }else{
          this.setState({ cartList: [ ...newcartList ], products: []})
        }
      }else{
        const notVariantItem = isItemInCartlist.filter((item)=>{
          return item.custom_quantities[0].id !== nitroCartItem.custom_quantities[0].id
        });

        const nu = notVariantItem.reduce((prev, curr)=>{
          return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity));
        }, 0);

        item.quantity = Number(item.quantityCheck) - nu;

        if(newcartList.length === 0){
          this.setState({ cartList: [ ...notVariantItem ], products: [ item ]})
        }else{
          this.setState({ cartList: [ ...newcartList, ...notVariantItem ], products: [ item ]})
        }
      }
    }else{
      if(newcartList.length === 0){
        this.setState({ cartList: [ ]})
      }else{
        this.setState({ cartList: [ ...newcartList ]})
      }
    } 
    
    return;
  }

  if (item.product_type === "service") return this.removeServiceFromCart(item);
  let filteredItemOutArr = [];
  const productsInStore = this.props.user.employer
    ? cloneArray(this.props.branchProducts)
    : cloneArray(this.props.products);
  const initialProductsData = JSON.parse(localStorage.getItem("products"));
  // const initialProductItemData = initialProductsData.filter(
  //   (product) => product.id === item.id
  // )[0];
  let initialProductItemData;

    if(item.item_id){
      initialProductItemData = initialProductsData?.filter(
        (product) => product.id === item.item_id
      )[0];
    }else{
      initialProductItemData = initialProductsData?.filter(
        (product) => product.id === item.id
      )[0];
    }
    let productItemInStoreCopy;

    if(item.item_id){
      productItemInStoreCopy = productsInStore?.filter(
        (product) => product.id === item.item_id
      )[0];
    }else{
      productItemInStoreCopy = productsInStore?.filter(
        (product) => product.id === item.id
      )[0];
    }

  if(!item.variants)item.variants = initialProductItemData.variants
  if (initialProductItemData.variants.length) {
    initialProductItemData.quantity = Number(productItemInStoreCopy.quantity) + Number(item.quantity)
    initialProductItemData.variants.forEach((variant) => {
      productItemInStoreCopy.variants.map((variable)=>{
        if(variant.id === item.variants[0].id){
          variant.quantity = item.variants[0].quantity;
        }else{
          if(variant.id === variable.id){
            variant.quantity = variable.quantity;
          }
        }
      });
    });
  }

  if(!item.custom_quantities)item.custom_quantities = initialProductItemData.custom_quantities
  if (initialProductItemData.custom_quantities.length) {
    initialProductItemData.quantity = Number(productItemInStoreCopy.quantity) + (item.quantity * Number(item.custom_quantities[0].quantity))
    // initialProductItemData.custom_quantities.forEach((custom) => {
    //   productItemInStoreCopy.custom_quantities.map((variable)=>{
    //     if(custom.id === item.custom_quantities[0].id){
    //       custom.quantity = item.custom_quantities[0].quantity;
    //     }else{
    //       if(custom.id === variable.id){
    //         custom.quantity = variable.quantity;
    //       }
    //     }
    //   });
    // });
  }
  this.props.updateProductItemInStore(productsInStore, initialProductItemData);
  updateProductItemInState(productsInStore, initialProductItemData, this);

  if (item.variants.length > 0) {
    item.variants.sort((a, b) => {
      return a.id < b.id;
    });

    filteredItemOutArr = this.state.cartList.filter((cartItem) => {
      if (cartItem.variants && cartItem.variants.length > 0) {
        cartItem.variants.sort((a, b) => {
          return a.id < b.id;
        });

        if (item.id !== cartItem.id) {
          return item.id !== cartItem.id;
        } else if (
          item.id === cartItem.id &&
          JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
        ) {
          return (
            JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
          );
        }
      }
      return item.id !== cartItem.id;
    });
  } else if(item.custom_quantities.length > 0){
    item.custom_quantities.sort((a, b) => {
      return a.id < b.id;
    });

    filteredItemOutArr = this.state.cartList.filter((cartItem) => {
      if (cartItem.custom_quantities && cartItem.custom_quantities.length > 0) {
        cartItem.custom_quantities.sort((a, b) => {
          return a.id < b.id;
        });

        if (item.id !== cartItem.id) {
          return item.id !== cartItem.id;
        } else if (
          item.id === cartItem.id &&
          JSON.stringify(item.custom_quantities) !== JSON.stringify(cartItem.custom_quantities)
        ) {
          return (
            JSON.stringify(item.custom_quantities) !== JSON.stringify(cartItem.custom_quantities)
          );
        }
      }
      return item.id !== cartItem.id;
    });
  }else {
    filteredItemOutArr = this.state.cartList.filter(
      (cartItem) => item.id !== cartItem.id
    );
  }
  this.setState({ cartList: [...filteredItemOutArr] });
};


export const removeServiceFromCart = function (item) {
  const cartList = cloneArray(this.state.cartList);
  return this.setState({
    cartList: cartList.filter((service) => service.id !== item.id),
  });
};

export const reduceServiceFromCart = function (item) {
  if (Number(item.quantity) === 1) return this.removeItemFromCart(item);
  const cartList = cloneArray(this.state.cartList);
  const newCartList = cartList.filter((service) => service.id !== item.id);
  const newQuantity = Number(item.quantity) - 1;
  item.quantity = newQuantity;
  item.amount = newQuantity * Number(item.original_price);
  return this.setState({ cartList: [...newCartList, item] });
};

export const handleAddServiceToCart = function (service, type) {
  message.destroy();

  // console.log(type);
  const cartList = cloneArray(this.state.cartList);

  const { user } = this.props;

  const isItemInCartList = cartList.find(
    (cartItem) => service.id === cartItem.id
  );

  if (service.customer_id) {
    const customer = this.props.customers.find(
      (cstmr) => cstmr.id === service.customer_id || cstmr.user_id === service.customer_id
    );
    customer && this.handleCustomerSearchFieldChange(customer.user_id);
  }

  
  const newService = {
    id: service.id,
    service_id: service.id,
    service_name: service.service_name,
    amount: service.amount_charged ? service.amount_charged : service.amount,
    quantity: "1.0",
    product_type: "service",
    merchant_id: user.employer ? user.employer.id : user.id,
    picture: service.image_url ? service.image_url : "",
    merchant_loyalty_program_id: service.loyalty_id ? service.loyalty_id : null,
    loyalty_id: service.loyalty_id,
    merchant_product_category_id: null,
    name: service.service_name ? service.service_name : service.name,
    price: service.amount_charged ? service.amount_charged : service.price,
    cost_price: "0.0",
    original_price: service.amount_charged ? service.amount_charged.toString() : service.price,
    service_id: service.id,
    tax: null,
    stamps: 0,
    points: 0,
    tax_rate: null,
    tax_type: null,
    track_inventory: false,
    unit: "pcs",
    deleted: false,
    business_branch_id: null,
  };


  // console.log(service, newService, isItemInCartList);
  if (!isItemInCartList) {
    return this.setState({ cartList: [newService, ...cartList] });
  } else {
    const newCartList = cartList.filter(
      (cartItem) => service.id !== cartItem.id
    );
    // let newQuantity;
    // let newAmount;
    // if(type === "single"){
    //   newQuantity = Number(service.quantity);
    //   newAmount = Number(service.price) * newQuantity;
    // }else{
      // newQuantity = Number(isItemInCartList.quantity) + 1;
      // newAmount = Number(isItemInCartList.original_price || Number(service.original_price)) * newQuantity;
    // }
    const newQuantity = Number(isItemInCartList.quantity) + 1;
    const newAmount = Number(isItemInCartList.original_price) * newQuantity;
    return this.setState({
      cartList: [
        ...newCartList,
        { ...isItemInCartList, quantity: newQuantity, amount: newAmount },
      ],
    });
  }
};

export const handleAddBundleProductToCart = function (item, bulk) {
  message.destroy();

 
  const cartList = cloneArray(this.state.cartList);

  const { user } = this.props;

  
  const isItemInCartList = cartList.filter(
    (cartItem) => item.id === cartItem.id
  );

  let quantity;
  let amount;

  if(isItemInCartList.length === 0){
    quantity = 1;
    amount = Number(item.price) * 1;
    const isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.quantity[i]));

    if(isQuantEnough.length === 1) return message.info(`${isQuantEnough[0].name} ${strings.oneBundleProductOutOfStock}`);
    if(isQuantEnough.length > 1) return message.info(strings.moreBundleProductOutOfStock)
    
    const newItem = {
      name: item.name,
      quantity,
      points: item.points ? item.points : 0,
      stamps: item.stamps ? item.stamps : 0,
      program_type: "SimplePoints",
      product_type: "product",
      amount,
      price: item.price,
      merchant_id: item.merchant_id,
      merchant_loyalty_program_id: item.merchant_loyalty_program_id ? item.merchant_loyalty_program_id : null,
      product_id: null,
      bundle_id: item.id,
      id: item.id,
      products: item.products,
      product_quantity: item.quantity,
      bundle_products: item.bundle_products
    }

    this.setState({ cartList: [ newItem, ...cartList ]})
  }else{
    const newCartList = cartList.filter(
      (cartItem) => item.id !== cartItem.id
    );
    let newQuantity;
    let newAmount;
    if(bulk === "bulk"){
      newQuantity = Number(item.quantity);
      newAmount = Number(item.price) * newQuantity;
    }else{
      newQuantity = isItemInCartList[0].quantity + 1;
      newAmount = Number(item.price) * newQuantity;
    }

    let isQuantEnough = [];
    if(item.product_quantity){
      isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.product_quantity[i]) * Number(newQuantity));
    }else{
      isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.quantity[i]) * Number(newQuantity));
    }
    
    if(isQuantEnough.length === 1) return message.info(`${isQuantEnough[0].name} ${strings.oneBundleProductOutOfStock}`);
    if(isQuantEnough.length > 1) return message.info(strings.moreBundleProductOutOfStock)
    
    return this.setState({
      cartList: [
        { ...isItemInCartList[0], quantity: newQuantity, amount: newAmount },
        ...newCartList,
      ],
    });
  }

}

export const handleReduceBundleProductToCart = function (item) {
  message.destroy();

  if (Number(item.quantity) === 1) return this.handleRemoveBundleProductToCart(item);
  const cartList = cloneArray(this.state.cartList);
  const newCartList = cartList.filter((itm) => itm.id !== item.id);
  const newQuantity = Number(item.quantity) - 1;
  item.quantity = newQuantity;
  item.amount = newQuantity * Number(item.price);
  return this.setState({ cartList: [ item, ...newCartList] });
}

export const handleRemoveBundleProductToCart = function (item) {
  message.destroy();
 
  const cartList = cloneArray(this.state.cartList);

  return this.setState({
    cartList: cartList.filter((itm) => itm.id !== item.id),
  });
}

export const addBulkQuantityNew = function (Item){
  const item = [...Item];
  let cartList = cloneArray(this.state.cartList);
  const productsInStore = this.props?.user.employer
      ? cloneArray(this.props?.branchProducts)
      : cloneArray(this.props?.products);

  const initialProductsData = JSON.parse(localStorage.getItem("products"));
  const initialProductItemData = initialProductsData?.filter(
    (product) => product.id === item.id
  )[0];
  const newQuat = Item.reduce((prev, curr)=>{
    return prev + (Number(curr.variants[0].selectQty));
  }, 0);

  if(item.length > 0){
    const them = item.map((eachproduct)=>{
      const productItem = eachproduct;
      productItem.quantity = eachproduct.variants[0].selectQty;

      let productItemInStoreCopy;

      if(eachproduct.item_id){
        productItemInStoreCopy = productsInStore?.filter(
          (product) => product.id === productItem.item_id
        )[0];
      }else{
        productItemInStoreCopy = productsInStore?.filter(
          (product) => product.id === productItem.id
        )[0];
      }

      if (!productItemInStoreCopy) {
        // window.location.reload();
        return;
      }
      
      //find the variant that is being bought
      const varianatToSell = productItemInStoreCopy.variants.filter((variant)=>{
        return variant.id === eachproduct.variants[0].id; 
      })[0];

      const otherVariants = productItemInStoreCopy.variants.filter((variant)=>{
        return variant.id !== eachproduct.variants[0].id;
      });

      

      if (productItemInStoreCopy) {
        productItemInStoreCopy.quantity = parseFloat(
          productItemInStoreCopy.quantity
        );
      }

      const initialProductsData = JSON.parse(localStorage.getItem("products"));
      const initialProductItemData = initialProductsData?.filter(
        (product) => product.id === eachproduct.id
      )[0];

      
      const varianatToSell2 = initialProductItemData.variants.filter((variant)=>{
        return variant.id === eachproduct.variants[0].id; 
      })[0];

      const otherVariants2 = initialProductItemData.variants.filter((variant)=>{
        return variant.id !== eachproduct.variants[0].id;
      });

      const { user } = this.props;

      const isItemInCartList = cartList.filter(
        (cartItem) => {
          return productItem.id === cartItem.id
        }
      );

      
      if(isItemInCartList.length === 0){
        if (eachproduct.track_inventory) {
          if (productItemInStoreCopy && Number(productItemInStoreCopy.quantity) < 1) {
            message.warning(strings.productOutOfStock, toastMessageDuration);
            return;
          }
  
          if(varianatToSell && Number(varianatToSell.quantity) < 1){
            message.warning(strings.productOutOfStock, toastMessageDuration);
            return;
          }
      
          if (productItemInStoreCopy) {
  
            productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - Number(newQuat);
            varianatToSell.quantity = (Number(varianatToSell.quantity) - Number(eachproduct.variants[0].selectQty)).toFixed(1);
            productItemInStoreCopy.variants = [varianatToSell, ...otherVariants];
  
            this.props.updateProductItemInStore(
              productsInStore,
              productItemInStoreCopy
            );
            updateProductItemInState(productsInStore, productItemInStoreCopy, this);
          }
      
          
          // console.log("STATE UPDATE ONE", this.props.products);
        }

        let price = productItem.variants.length > 0
            ? productItem.variants[0].price
            : productItem.price;
        
    
        productItem.amount = Number(productItem.variants[0].price) * Number(productItem.variants[0].selectQty); 
        productItem.points = productItem.points ? productItem.points : 0;
        productItem.stamps = productItem.stamps ? productItem.stamps : 0;
        productItem.product_id = productItem.id;
        productItem.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        productItem.product_type = "product";
    
        if (productItem.variants.length) {
          if (productItem.tax_rate) {
            productItem.amount =
              ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
                2
              ) * productItem.quantity;
          }
          
          productItem.variants.forEach((variant) => {
            variant.quantityLeft = variant.quantity;
          });
          // this.props.updateProductItemInStore(productsInStore, productItem);
    
          // updateProductItemInState(productsInStore, productItem, this);
          // console.log("STATE UPDATE TWO", this.props.products);
        }
        // console.log("CART UPDATED ITEM NOT EXIST BLOCK");
        return productItem;
        // this.setState({ cartList: [productItem, ...cartList] });
      }else{
        if (eachproduct.track_inventory) {
          if (productItemInStoreCopy && Number(productItemInStoreCopy.quantity) < 1) {
            // TODO: flag error - product cannot be added to cart. [Refill product]
            message.warning(strings.productOutOfStock, toastMessageDuration);
            return;
          }
  
          if(varianatToSell && Number(varianatToSell.quantity) < 1){
            message.warning(strings.productOutOfStock, toastMessageDuration);
            return;
          }
      
          if (productItemInStoreCopy) {
  
            productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - Number(newQuat);
            varianatToSell.quantity = (Number(varianatToSell2.quantity) - Number(eachproduct.variants[0].selectQty)).toFixed(1);
            productItemInStoreCopy.variants = [varianatToSell, ...otherVariants];
  
            this.props.updateProductItemInStore(
              productsInStore,
              productItemInStoreCopy
            );
            updateProductItemInState(productsInStore, productItemInStoreCopy, this);
          }
      
          // console.log("STATE UPDATE ONE", this.props.products);
        }

        const samevariantproduct = isItemInCartList.filter((product)=>{
          return product.variants[0].id === eachproduct.variants[0].id
        })[0];

        cartList = cartList.filter((cart)=>{
          return cart.id !== samevariantproduct.id
        });

        let price = productItem.variants.length > 0
            ? productItem.variants[0].price
            : productItem.price;
        
    
        productItem.amount = Number(productItem.variants[0].price) * Number(productItem.variants[0].selectQty);  
        productItem.points = productItem.points ? productItem.points : 0;
        productItem.stamps = productItem.stamps ? productItem.stamps : 0;
        productItem.product_id = productItem.id;
        productItem.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        productItem.product_type = "product";
    
        if (productItem.variants.length) {
          if (productItem.tax_rate) {
            productItem.amount =
              ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
                2
              ) * productItem.quantity;
          }
          
          productItem.variants.forEach((variant) => {
            variant.quantityLeft = variant.quantity;
          });
          // this.props.updateProductItemInStore(productsInStore, productItem);
    
          // updateProductItemInState(productsInStore, productItem, this);
          // console.log("STATE UPDATE TWO", this.props.products);
        }
        // console.log("huil", samevariantproduct.variants[0].quantity, productItem);
        // console.log("CART UPDATED ITEM NOT EXIST BLOCK");
        return productItem;
        
      }
    });    

    this.setState({ cartList: [...them, ...cartList] });
  }
}


export const addProductToCart = function (Item, type = "single") {
  message.destroy();
  const { isNitroActive } = this.props;
 
  if (Item.product_type === "service") return this.handleAddServiceToCart(Item, type);
  const item = {...Item};

  if( isNitroActive ){
    const cartList = cloneArray(this.state.cartList);
    const { user } = this.props;
    const nitroCartItem = { ...item};

    const isItemInCartList = cartList.filter(
      (cartItem) => {
        return Number(nitroCartItem.id) === Number(cartItem.id)
      }
    );

    if(nitroCartItem.variants.length > 0){
      const newCartList = cartList.filter((item)=>{
        return item.id !== nitroCartItem.id
      });

      if( isItemInCartList.length === 1){
        const variant = nitroCartItem.variants[0];
        if( isItemInCartList[0].variants[0].id === variant.id){
          const variantQty = Number(variant.quantity);
          if(isItemInCartList[0].quantity >= variantQty){
            return message.warn("Product out of stock. Please re-stock.");
          };
          if(type === "bulk"){
            nitroCartItem.quantity = Number(Item.quantity);
          }else{
            nitroCartItem.quantity =  Number(isItemInCartList[0].quantity) + 1;
          }

          // console.log("items quantity", nitroCartItem);

          cartList.forEach((item) => {
            if(item.id == nitroCartItem.id){
              if(item.variants[0].id == variant.id){
                const price =
                  nitroCartItem.variants.length > 0
                      ? nitroCartItem.variants[0].price
                      : nitroCartItem.price;
                item.quantity = type === "bulk" ? Number(Item.quantity) : item.quantity + 1
                item.amount = price * item.quantity;
                item.points = nitroCartItem.points ? nitroCartItem.points : 0;
                item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
                item.product_id = nitroCartItem.id;
                item.business_branch_id = user.business_branch
                  ? user.business_branch.id
                  : null;
                item.product_type = "product";
                item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
                if(item.track_inventory){
                  nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(nitroCartItem.quantity);
      
                  this.setState({ products: [ nitroCartItem ]})
                }

                // this.setState({ cartList: cartList })
              }
            }
          })
          return this.setState({ cartList: cartList });
        }else{
          if(type === "bulk"){
            nitroCartItem.quantity = Number(item.quantity);
          }else{
            nitroCartItem.quantity = 1;
          }
          // nitroCartItem.quantity = 1;
          cartList.forEach((item) => {
            if(item.id == nitroCartItem.id){
              if(item.variants[0].id == variant.id){
                const price =
                  nitroCartItem.variants.length > 0
                      ? nitroCartItem.variants[0].price
                      : nitroCartItem.price;
          
                item.amount = price * nitroCartItem.quantity;
                item.points = nitroCartItem.points ? nitroCartItem.points : 0;
                item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
                item.product_id = nitroCartItem.id;
                item.business_branch_id = user.business_branch
                  ? user.business_branch.id
                  : null;
                item.product_type = "product";
                item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
                if(item.track_inventory){
                  item.quantity = Number(nitroCartItem.quantityCheck) - Number(nitroCartItem.quantity);
      
                  this.setState({ products: [ item ]})
                }
              }
            }
          })

          this.setState({ cartList: cartList });
        }
      }else if( isItemInCartList.length > 1){
        const variant = nitroCartItem.variants[0];
        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            if(item.variants[0].id == variant.id){
              if(item.quantity >= variant.quantity){
                message.warn("Product out of stock. Please re-stock.");
                return;
              }
              const price =
                nitroCartItem.variants.length > 0
                    ? nitroCartItem.variants[0].price
                    : nitroCartItem.price;
              item.quantity = type === "bulk" ? Number(Item.quantity) : item.quantity + 1
              item.amount = price * item.quantity;
              item.points = nitroCartItem.points ? nitroCartItem.points : 0;
              item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
              item.product_id = nitroCartItem.id;
              item.business_branch_id = user.business_branch
                ? user.business_branch.id
                : null;
              item.product_type = "product";
              item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
              if(item.track_inventory){
                const q1 = isItemInCartList.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
                nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(q1);
                this.setState({ products: [ nitroCartItem ]})
              }

              // this.setState({ cartList: cartList })
            }
          }
        })
        return this.setState({ cartList: cartList });
      }
      
    }else if(nitroCartItem.custom_quantities.length > 0){
      const newCartList = cartList.filter((item)=>{
        return item.id !== nitroCartItem.id
      });

      if( isItemInCartList.length === 1){
        const customQuantity = nitroCartItem.custom_quantities[0];
        if( isItemInCartList[0].custom_quantities[0].id === customQuantity.id){
          if(type === "bulk"){
            nitroCartItem.quantity = Number(item.quantity);
          }else{
            nitroCartItem.quantity =  Number(isItemInCartList[0].quantity) + 1;
          }

          cartList.forEach((item) => {
            if(item.id == nitroCartItem.id){
              if(item.custom_quantities[0].id == customQuantity.id){
                const price =
                  nitroCartItem.custom_quantities.length > 0
                      ? nitroCartItem.custom_quantities[0].price
                      : nitroCartItem.price;
                item.quantity = type === "bulk" ? Number(Item.quantity) : item.quantity + 1
                item.amount = price * item.quantity;
                item.points = nitroCartItem.points ? nitroCartItem.points : 0;
                item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
                item.product_id = nitroCartItem.id;
                item.business_branch_id = user.business_branch
                  ? user.business_branch.id
                  : null;
                item.product_type = "product";
                item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
                if(item.track_inventory){
                  nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(nitroCartItem.quantity);
      
                  this.setState({ products: [ nitroCartItem ]})
                }
              }
            }
          })
          return this.setState({ cartList: cartList })
        }else{
          if(type === "bulk"){
            nitroCartItem.quantity = Number(item.quantity);
          }else{
            nitroCartItem.quantity = 1;
          }
          // nitroCartItem.quantity = 1;
      
          cartList.forEach((item) => {
            if(item.id == nitroCartItem.id){
              if(item.custom_quantities[0].id == customQuantity.id){
                const price =
                  nitroCartItem.custom_quantities.length > 0
                      ? nitroCartItem.custom_quantities[0].price
                      : nitroCartItem.price;
                item.quantity = type === "bulk" ? Number(Item.quantity) : item.quantity + 1
                item.amount = price * item.quantity;
                item.points = nitroCartItem.points ? nitroCartItem.points : 0;
                item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
                item.product_id = nitroCartItem.id;
                item.business_branch_id = user.business_branch
                  ? user.business_branch.id
                  : null;
                item.product_type = "product";
                item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
                if(item.track_inventory){
                  nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(nitroCartItem.quantity);
      
                  this.setState({ products: [ nitroCartItem ]})
                }

                // this.setState({ cartList: cartList })
              }
            }
          })
          return this.setState({ cartList: cartList })
        }
      }else if( isItemInCartList.length > 1){
        const customQty = nitroCartItem.custom_quantities[0];
        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            if(item.custom_quantities[0].id == customQty.id){
              if(item.quantity >= customQty.quantity){
                message.warn("Product out of stock. Please re-stock.");
                return;
              }
              const price =
                nitroCartItem.custom_quantities.length > 0
                    ? nitroCartItem.custom_quantities[0].price
                    : nitroCartItem.price;
              item.quantity = type === "bulk" ? Number(Item.quantity) : item.quantity + 1
              item.amount = price * item.quantity;
              item.points = nitroCartItem.points ? nitroCartItem.points : 0;
              item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
              item.product_id = nitroCartItem.id;
              item.business_branch_id = user.business_branch
                ? user.business_branch.id
                : null;
              item.product_type = "product";
              item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
              if(item.track_inventory){
                const q1 = isItemInCartList.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
                nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(q1);
    
                this.setState({ products: [ nitroCartItem ]})
              }
            }
          }
        })
        return this.setState({ cartList: cartList });
      }
    }else{
      //if nitro products doesn't have variants
      if(isItemInCartList.length === 0){
        //if product that don't have variants is present in the cart
        if(type === "bulk"){
          item.quantity = Number(item.quantity);
        }else{
          item.quantity = 1;
        }
        
        const price = item.price;
    
        item.amount = price * item.quantity;
        item.points = nitroCartItem.points ? nitroCartItem.points : 0;
        item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
        item.product_id = nitroCartItem.id;
        item.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        item.product_type = "product";
        item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id;

        nitroCartItem.quantity = Number(nitroCartItem.quantity) - 1;
        return this.setState({ cartList: [ item, ...cartList] });
      }else{
        //if product that have variants is present in the cart
        if(nitroCartItem.track_inventory){
          if(Number(isItemInCartList[0].quantity) >= nitroCartItem.quantityCheck){
            return message.warn("Product out of stock. Please re-stock.");
          }
        }
        if(type === "bulk"){
          nitroCartItem.quantity = Number(item.quantity);
        }else{
          nitroCartItem.quantity = Number(isItemInCartList[0].quantity) + 1;
        }

        cartList.forEach((item) => {
          if(item.id == nitroCartItem.id){
            const price =
              nitroCartItem.variants.length > 0
                  ? nitroCartItem.variants[0].price
                  : nitroCartItem.price;
      
            item.quantity = item.quantity + 1;
            item.amount = price * item.quantity;
            item.points = nitroCartItem.points ? nitroCartItem.points : 0;
            item.stamps = nitroCartItem.stamps ? nitroCartItem.stamps : 0;
            item.product_id = nitroCartItem.id;
            item.business_branch_id = user.business_branch
              ? user.business_branch.id
              : null;
            item.product_type = "product";
            item.merchant_loyalty_program_id = nitroCartItem.merchant_loyalty_program_id === 0 ? null : nitroCartItem.merchant_loyalty_program_id 
            if(item.track_inventory){
              nitroCartItem.quantity = Number(nitroCartItem.quantityCheck) - Number(item.quantity);
  
              this.setState({ products: [ nitroCartItem ]})
            }
          }
        })

        return this.setState({ cartList: cartList });
      }
    }
  }
 
  const location = this.props?.location?.state
  const { user } = this.props;
  const  { isPermitted } = this.state;

  if(location?.fromDraftSales){
    if(isPermitted === false){
      if(!user?.role_id){  
        return this.setState({ isAuthModal: true })
      }
    }
  }
  
  const productItem = item;
  productItem.quantity = parseFloat(productItem.quantity);

  const productsInStore = this.props?.user.employer
    ? cloneArray(this.props?.branchProducts)
    : cloneArray(this.props?.products);

  let productItemInStoreCopy;

  if(item.item_id){
    productItemInStoreCopy = productsInStore?.filter(
      (product) => Number(product.id) === Number(productItem.item_id)
    )[0];
  }else{
    productItemInStoreCopy = productsInStore?.filter(
      (product) => Number(product.id) === Number(productItem.id)
    )[0];
  }

  if(!item.variants) item.variants = productItemInStoreCopy.variants;
  if (!productItemInStoreCopy) {
    return;
  }

  
  if (productItemInStoreCopy) {
    productItemInStoreCopy.quantity = parseFloat(
      productItemInStoreCopy.quantity
    );
  }

  const initialProductsData = JSON.parse(localStorage.getItem("products"));
  const initialProductItemData = initialProductsData?.filter(
    (product) => product.id === item.id
  )[0];
 
  const cartList = cloneArray(this.state.cartList);
  

  // future fix:- this will be used to reset the item state when there's any error while adding bulk quantity
  let bulkProductItemInStoreCopy = { ...productItemInStoreCopy };

  if (item.track_inventory) {
    if (productItemInStoreCopy && Number(productItemInStoreCopy.quantity) < 1) {
      // TODO: flag error - product cannot be added to cart. [Refill product]
      message.warning(strings.productOutOfStock, toastMessageDuration);
      return;
    }

    // const bukProd = productItemInStoreCopy.variants.reduce((prev, curr)=>{
    //             return prev + Number(curr.quantity)
    //           }, 0)
    if (productItemInStoreCopy) {

      let cartNewClone = cartList.filter((product)=>{
        return product.id === item.id
      });
      // console.log("iyems", item.variants, item.quantity, type);

      if(type !== "single" && type !== 1){
        if(item.variants.length > 0){
          const initialProductItemDataVariant = initialProductItemData.variants.filter((variant)=>{
            return variant.id === item?.variants[0].id
          })[0];
    
          if(Number(initialProductItemDataVariant.quantity < item.quantity))return message.warning(strings.productOutOfStock, toastMessageDuration);

          productItemInStoreCopy.variants.map((variant)=>{
            if(variant.id === item.variants[0].id){
              variant.quantity = (Number(variant.quantity)- Number(item.quantity)).toFixed(1)
            }else{
              variant.quantity = variant.quantity
            }
          });
  
          const notCartCloneVariant = cartNewClone.filter((product)=>{
            return product.variants[0].id !== item.variants[0].id;
          });

          const quality = notCartCloneVariant.reduce((prev, curr)=>{
            return prev + Number(curr.quantity)
          }, 0);
          
          const calculatedQty = quality + Number(item.quantity);
          productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - calculatedQty;
          // console.log(item, initialProductItemData, productItemInStoreCopy);
        }else if(item.custom_quantities.length > 0){
          if(isNitroActive){
            
          }else{
            const initialProductItemDataVariant = initialProductItemData.custom_quantities.filter((custom)=>{
              return custom.id === item?.custom_quantities[0].id
            })[0];
      
            const itemInCart = cartList.filter((ite)=>{
              return ite.id === item.id;
            });
            if(itemInCart.length !== 0){
              const qunatity = itemInCart.reduce((prev, curr)=>{
                return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
              }, 0)
              if(Number(initialProductItemData.quantity < (qunatity + (item.quantity * Number(item.custom_quantities[0].quantity)))))return message.warning(strings.productOutOfStock, toastMessageDuration);
              
            }else{
              if(Number(initialProductItemData.quantity < (item.quantity * Number(item.custom_quantities[0].quantity))))return message.warning(strings.productOutOfStock, toastMessageDuration);
            }
  
            // productItemInStoreCopy.custom_quantities.map((custom)=>{
            //   if(custom.id === item.custom_quantities[0].id){
            //     custom.quantity = (Number(custom.quantity)- Number(item.quantity)).toFixed(1)
            //   }else{
            //     custom.quantity = custom.quantity
            //   }
            // });
    
            const cartCloneVariant = cartNewClone.filter((product)=>{
              return product.custom_quantities[0].id === item.custom_quantities[0].id;
            });
            
            let calculatedQty;
            if(itemInCart.length !== 0){
              const quanlity = itemInCart.reduce((prev, curr)=>{
                return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
              }, 0);
  
              calculatedQty = quanlity + (item.quantity * Number(item.custom_quantities[0].quantity));
            }else{
  
              calculatedQty = (item.quantity * Number(item.custom_quantities[0].quantity));
            }
            productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - calculatedQty;
          }
          
        }
        
        else{
          productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - Number(item.quantity);
        }
      }else if(type === 1){
        if(item.variants?.length > 0){
          const initialProductItemDataVariant = initialProductItemData.variants.filter((variant)=>{
            return variant.id === item?.variants[0].id
          })[0];
    
          if(Number(initialProductItemDataVariant.quantity <= Number(item.quantity))) return message.warning(strings.productOutOfStock, toastMessageDuration);

          if(Number(item.variants[0].quantity) <= Number(item.quantity)){
            return message.warning(strings.productOutOfStock, toastMessageDuration);
          }

          productItemInStoreCopy.quantity = productItemInStoreCopy.quantity - 1;

          productItemInStoreCopy.variants.map((variant)=>{
            if(variant.id === item.variants[0].id){
              variant.quantity = (Number(variant.quantity) - 1).toFixed(1)
            }else{
              variant.quantity = variant.quantity
            }
          });
        }else if(item.custom_quantities.length > 0){
          const initialProductItemDataCustom = initialProductItemData.custom_quantities.filter((custom)=>{
            return custom.id === item?.custom_quantities[0].id
          })[0];
    
          const itemInCart = cartList.filter((ite)=>{
            return ite.id === item.id;
          });
          if(itemInCart.length !== 0){
            const qunatity = itemInCart.reduce((prev, curr)=>{
              return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
            }, 0)

            if(Number(initialProductItemData.quantity < (qunatity + Number(item.custom_quantities[0].quantity))))return message.warning(strings.productOutOfStock, toastMessageDuration);
            
          }else{
            if(Number(initialProductItemData.quantity <  Number(item.custom_quantities[0].quantity)))return message.warning(strings.productOutOfStock, toastMessageDuration);
          }

          const cartCloneVariant = cartNewClone.filter((product)=>{
            return product.custom_quantities[0].id === item.custom_quantities[0].id;
          });
          
          let calculatedQty;
          if(itemInCart.length !== 0){
            const quanlity = itemInCart.reduce((prev, curr)=>{
              return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
            }, 0);

            calculatedQty = quanlity + (1 * Number(item.custom_quantities[0].quantity));
          }else{

            calculatedQty = Number(item.custom_quantities[0].quantity);
          }
          
          productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - calculatedQty;
        }else{
          productItemInStoreCopy.quantity = productItemInStoreCopy.quantity - 1;
        }
      }else{
        if(item.variants.length > 0){
          const initialProductItemDataVariant = initialProductItemData.variants.filter((variant)=>{
            return variant.id === item?.variants[0].id
          })[0];
    
          if(Number(initialProductItemDataVariant.quantity < item.quantity))return message.warning(strings.productOutOfStock, toastMessageDuration);

          productItemInStoreCopy.variants.map((variant)=>{
            if(variant.id === item.variants[0].id){
              variant.quantity = (Number(variant.quantity)- Number(item.quantity)).toFixed(1)
            }else{
              variant.quantity = variant.quantity
            }
          });
  
          const notCartCloneVariant = cartNewClone.filter((product)=>{
            return product.variants[0].id !== item.variants[0].id;
          });

          const cartCloneVariant = cartNewClone.filter((product)=>{
            return product.variants[0].id === item.variants[0].id;
          });

          const quality = notCartCloneVariant.reduce((prev, curr)=>{
            return prev + Number(curr.quantity)
          }, 0);

          let calculatedQty;
          if(cartCloneVariant.length > 0) {
            calculatedQty = quality + Number(item.quantity) + Number(cartCloneVariant[0].quantity);
          }else{
            calculatedQty = quality + Number(item.quantity);
          }
          
         
          productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - calculatedQty;
          // console.log(item, initialProductItemData, productItemInStoreCopy);
        }else if(item.custom_quantities.length > 0){
          if(isNitroActive){
            
          }else{
            const initialProductItemDataVariant = initialProductItemData.custom_quantities.filter((custom)=>{
              return custom.id === item?.custom_quantities[0].id
            })[0];
      
            const itemInCart = cartList.filter((ite)=>{
              return ite.id === item.id;
            });
            if(itemInCart.length !== 0){
              const qunatity = itemInCart.reduce((prev, curr)=>{
                return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
              }, 0)
              if(Number(initialProductItemData.quantity < (qunatity + (item.quantity * Number(item.custom_quantities[0].quantity)))))return message.warning(strings.productOutOfStock, toastMessageDuration);
              
            }else{
              if(Number(initialProductItemData.quantity < (item.quantity * Number(item.custom_quantities[0].quantity))))return message.warning(strings.productOutOfStock, toastMessageDuration);
            }
  
            // productItemInStoreCopy.custom_quantities.map((custom)=>{
            //   if(custom.id === item.custom_quantities[0].id){
            //     custom.quantity = (Number(custom.quantity)- Number(item.quantity)).toFixed(1)
            //   }else{
            //     custom.quantity = custom.quantity
            //   }
            // });
    
            const cartCloneVariant = cartNewClone.filter((product)=>{
              return product.custom_quantities[0].id === item.custom_quantities[0].id;
            });
            
            let calculatedQty;
            if(itemInCart.length !== 0){
              const quanlity = itemInCart.reduce((prev, curr)=>{
                return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
              }, 0);
  
              calculatedQty = quanlity + (item.quantity * Number(item.custom_quantities[0].quantity));
            }else{
  
              calculatedQty = (item.quantity * Number(item.custom_quantities[0].quantity));
            }
            productItemInStoreCopy.quantity = Number(initialProductItemData.quantity) - calculatedQty;
          }
          
        }else{
          productItemInStoreCopy.quantity = Number(productItemInStoreCopy.quantity) - Number(item.quantity);
        }
        // productItemInStoreCopy.quantity = productItemInStoreCopy.quantity - 1;
        // if(item.variants.length > 0){
        //   // productItemInStoreCopy.variants.map((variant)=>{
        //   //   if(variant.id === item.variants[0].id){
        //   //     variant.quantity = (Number(variant.quantity)- Number(item.quantity)).toFixed(1)
        //   //   }else{
        //   //     variant.quantity = variant.quantity
        //   //   }
        //   // });
        // }
        // console.log("other", productItemInStoreCopy);
      }

      this.props.updateProductItemInStore(
        productsInStore,
        productItemInStoreCopy
      );

    }
    updateProductItemInState(productsInStore, productItemInStoreCopy, this);    
    
    
    // console.log("STATE UPDATE ONE", this.props.products);
  }
  // filter cartList array to check for the item
  
    const isItemInCartList = cartList.filter(
      (cartItem) => {
        if(cartItem.item_id){
          return Number(productItem.id) === Number(cartItem.item_id);
        }else{
          return Number(productItem.id) === Number(cartItem.id)
        }
      }
    );

  // if the item does not exist
  if (isItemInCartList.length === 0) {
    if (!productItem.quantity || productItem.quantity <= 0) {
      productItem.quantity = 1;
    }
    const price =
      productItem.variants.length > 0
        ? productItem.variants[0].price
        : productItem.price;

    productItem.amount = price * productItem.quantity;
    productItem.points = productItem.points ? productItem.points : 0;
    productItem.stamps = productItem.stamps ? productItem.stamps : 0;
    productItem.product_id = productItem.id;
    productItem.business_branch_id = user.business_branch
      ? user.business_branch.id
      : null;
    productItem.product_type = "product";

    if (productItem.variants.length) {
      if (productItem.tax_rate) {
        productItem.amount =
          ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
            2
          ) * productItem.quantity;
      }
      productItem.variants.forEach((variant) => {
        variant.quantityLeft = variant.quantity - productItem.quantity;
      });

      // const updatedVariantss = [...productItemInStoreCopy.variants].map(
      //   variant => {
      //     const res = [...productItem.variants].find(variantData => {
      //       return variantData.id === variant.id;
      //     });
      //     if (res) return res;
      //     return variant;
      //   }
      // );

      let updatedVariants = [];

      [...productItemInStoreCopy.variants].forEach((variant) => {
        const res = [...productItem.variants].find((variantData) => {
          return variantData.id === variant.id;
        });

        if (res) {
          updatedVariants.push(res);
        }
      });

      productItem.variants = updatedVariants;

      // this.props.updateProductItemInStore(productsInStore, productItem);

      // updateProductItemInState(productsInStore, productItem, this);
      // console.log("STATE UPDATE TWO", this.props.products);
    }
    // console.log("CART UPDATED ITEM NOT EXIST BLOCK");
    this.setState({ cartList: [productItem, ...cartList] });
  } else {
    if(item.custom_quantities && item.custom_quantities.length > 0){

      const customItemInCart = isItemInCartList.filter((ite)=>{
        return ite.custom_quantities[0].id === item.custom_quantities[0].id
      });

      if (customItemInCart.length !== 0) {
        const productItem = { ...item}
        if(type === 1){
          productItem.quantity = customItemInCart[0].quantity + 1;
        }else{
          productItem.quantity = customItemInCart[0].quantity + item.quantity;
        }
        if((productItem.quantity * Number(productItem.custom_quantities[0].quantity)) >= initialProductItemData.quantity){
          return  message.warning(strings.productOutOfStock, toastMessageDuration);
        }
        const price =
          productItem.custom_quantities.length > 0
            ? productItem.custom_quantities[0].price
            : productItem.price;

          productItem.amount = Number(price) * productItem.quantity;
          productItem.points = productItem.points ? productItem.points : 0;
          productItem.stamps = productItem.stamps ? productItem.stamps : 0;
          productItem.product_id = productItem.id;
          productItem.business_branch_id = user.business_branch
            ? user.business_branch.id
            : null;
          productItem.product_type = "product";
          const newCartItem = cartList.filter((ite)=>{
            return ite?.custom_quantities[0]?.id !== item?.custom_quantities[0]?.id
          });
        return this.setState({ cartList: [  productItem, ...newCartItem]})
      
      }else{
        const productItem = { ...item}
        const price =
          productItem.custom_quantities.length > 0
            ? productItem.custom_quantities[0].price
            : productItem.price;

          productItem.amount = Number(price) * productItem.quantity;
          productItem.points = productItem.points ? productItem.points : 0;
          productItem.stamps = productItem.stamps ? productItem.stamps : 0;
          productItem.product_id = productItem.id;
          productItem.business_branch_id = user.business_branch
            ? user.business_branch.id
            : null;
          productItem.product_type = "product";
        return this.setState({ cartList: [  productItem, ...cartList]})
      }
    }else if (item.variants.length === 0) {
      cartList.forEach((cartItem) => {
        if(cartItem.item_id){
          if (cartItem.item_id === productItem.id) {
            if (type !== "bulk") {
              cartItem.quantity = parseInt(cartItem.quantity) + 1;
              cartItem.amount = cartItem.price * cartItem.quantity;
            } else {
              cartItem.quantity = productItem.quantity;
              cartItem.amount = cartItem.price * cartItem.quantity;
            }
          }
        }else{
          if (cartItem.id === productItem.id) {
            if (type !== "bulk") {
              cartItem.quantity = parseInt(cartItem.quantity) + 1;
              cartItem.amount = cartItem.price * cartItem.quantity;
            } else {
              cartItem.quantity = productItem.quantity;
              cartItem.amount = cartItem.price * cartItem.quantity;
            }
          }
        }
      });
      this.setState({ cartList: [...cartList] });

      // console.log("CART UPDATED ITEM IS EXIST IN BLOCK AND VARIANTS ZERO");
    } else {
      productItem.variants.sort((a, b) => {
        return a.id < b.id;
      });

      let newProdItem = { ...productItem };

      const compareVariants = cloneArray(newProdItem.variants).map(
        (variant) => {
          delete variant.quantity;
          delete variant.quantityLeft;
          return variant;
        }
      );

      const cartListClone = cloneArray(cartList);

      const product = cartListClone.filter((cartItem) => {
        if (item.id === cartItem.id) {
          cartItem.variants.sort((a, b) => a.id < b.id);
          const newCartItem = { ...cartItem };

          let tempVariants = cloneArray(newCartItem.variants);

          tempVariants = tempVariants.map((variant) => {
            delete variant.quantity;
            delete variant.quantityLeft;
            return variant;
          });
          // compare new product variants (incoming product) with item already present in cart
          return (
            item.id === cartItem.id &&
            JSON.stringify(compareVariants) === JSON.stringify(tempVariants)
          );
        }
      });

      if (product.length === 1) {
        let isLowStock = false;
        let addedValue = 0;
        let productItemCopy1;

        cartList.forEach((cartItem) => {
          if (cartItem.id === productItem.id) {
            cartItem.variants.sort((a, b) => a.id < b.id);

            const newCartItem = { ...cartItem };
            let tempCartVariants = cloneArray(newCartItem.variants);

            tempCartVariants = tempCartVariants.map((variant) => {
              delete variant.quantity;
              delete variant.quantityLeft;
              return variant;
            });

            if (
              JSON.stringify(compareVariants) ===
              JSON.stringify(tempCartVariants)
            ) {
              const price =
                cartItem.variants.length > 0
                  ? cartItem.variants[0].price
                  : cartItem.price;

              if (type === "bulk") {
                cartItem.quantity = productItem.quantity;
                cartItem.amount = price * cartItem.quantity;

                if (productItem.tax_rate) {
                  cartItem.amount =
                    (
                      (productItem.tax_rate / 100) * price +
                      parseFloat(price)
                    ).toFixed(2) * cartItem.quantity;
                }
                addedValue = productItem.quantity;
              } else if (productItem.quantity > 1 && type !== 1) {
                cartItem.quantity =
                  parseInt(cartItem.quantity) + productItem.quantity;

                cartItem.amount = price * cartItem.quantity;
                if (productItem.tax_rate) {
                  cartItem.amount =
                    (
                      (productItem.tax_rate / 100) * price +
                      parseFloat(price)
                    ).toFixed(2) * cartItem.quantity;
                }
                addedValue = productItem.quantity;
              } else if (productItem.quantity > 1 && type === 1) {
                let cartNewClone = cartList.filter((product)=>{
                  return product.id === item.id
                });

                const cartCloneVariant = cartNewClone.filter((product)=>{
                  return product.variants[0].id === item.variants[0].id
                })[0];

                const initialProductItemDataVariant = initialProductItemData.variants.filter((variant)=>{
                  return variant.id === item?.variants[0].id
                })[0];

                if(Number(cartItem.quantity) >= Number(cartCloneVariant.variants[0].quantity))return message.warning(strings.productOutOfStock, toastMessageDuration);

                if(Number(cartItem.quantity) >= Number(initialProductItemDataVariant.quantity))return message.warning(strings.productOutOfStock, toastMessageDuration);

                cartItem.quantity = parseInt(cartItem.quantity) + 1;
                cartItem.amount = price * cartItem.quantity;
                if (productItem.tax_rate) {
                  cartItem.amount =
                    (
                      (productItem.tax_rate / 100) * price +
                      parseFloat(price)
                    ).toFixed(2) * cartItem.quantity;
                }
                addedValue = 1;
              } else {
                cartItem.quantity = parseInt(cartItem.quantity) + 1;
                cartItem.amount = price * cartItem.quantity;
                if (productItem.tax_rate) {
                  cartItem.amount =
                    (
                      (productItem.tax_rate / 100) * price +
                      parseFloat(price)
                    ).toFixed(2) * cartItem.quantity;
                }
                addedValue = 1;
              }

              try {
                cartItem.variants.forEach((variant) => {
                  // if (
                  //   parseInt(variant.quantity) < parseInt(cartItem.quantity)
                  // ) {
                  //   throw new Error(strings.lowStock);
                  // } else
                  //  if (
                  //   variant.quantityLeft &&
                  //   parseInt(variant.quantityLeft) - addedValue < 0
                  // ) {
                  //   throw new Error(strings.lowStock);
                  // } else {
                    variant.quantityLeft =
                      parseInt(variant.quantity) - cartItem.quantity;
                    productItemCopy1 = cartItem;
                  // }
                });
              } catch (error) {
                isLowStock = true;
                // console.log(
                //   "Set ISLOWSTOCK TO TRUE IN TRY CATCH BLOCK ONE",
                //   error
                // );

                return;
              }
            }
          }
        });

        if (isLowStock) {
          // this.props.updateProductItemInStore(
          //   productsInStore,
          //   bulkProductItemInStoreCopy
          // );

          // updateProductItemInState(
          //   productsInStore,
          //   bulkProductItemInStoreCopy,
          //   this
          // );

          message.warning(strings.productOutOfStock, toastMessageDuration);
        } else {
          // const updatedVariants = [...productItemInStoreCopy.variants].map(
          //   variant => {
          //     const res = [...productItemCopy1.variants].find(variantData => {
          //       return variantData.id === variant.id;
          //     });
          //     if (res) return res;
          //     return variant;
          //   }
          // );

          let updatedVariants = [];

          [...productItemInStoreCopy.variants].forEach((variant) => {
            const res = [...productItem.variants].find((variantData) => {
              return variantData.id === variant.id;
            });

            if (res) {
              updatedVariants.push(res);
            }
          });

          productItem.variants = updatedVariants;
          // this.props.updateProductItemInStore(productsInStore, productItem);

          // updateProductItemInState(productsInStore, productItem, this);

          // console.log("STATE UPDATE THREE", this.props.products);

          this.setState({ cartList: [...cartList] });
        }
        return;
      } else {
        let isLowStock = false;
        let addedValue = 0;

        if (isNaN(productItem.quantity) || productItem.quantity <= 0) {
          productItem.quantity = 1;
        }

        const price =
          productItem.variants.length > 0
            ? productItem.variants[0].price
            : productItem.price;
        productItem.amount = price * productItem.quantity;

        if (productItem.tax_rate) {
          productItem.amount =
            ((productItem.tax_rate / 100) * price + parseFloat(price)).toFixed(
              2
            ) * productItem.quantity;
        }
        productItem.product_type = "product";
        productItem.points = 0;
        productItem.stamps = 0;
        productItem.product_id = productItem.id;
        productItem.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        addedValue = productItem.quantity;

        try {
          productItem.variants.forEach((variant) => {
            // if (parseInt(variant.quantity) < parseInt(productItem.quantity)) {
            //   throw new Error(strings.lowStock);
            // } else 
            // if (
            //   variant.quantityLeft &&
            //   parseInt(variant.quantityLeft) - addedValue < 0
            // ) {
            //   throw new Error(strings.lowStock);
            // } else {
              variant.quantityLeft =
                parseInt(variant.quantity) - productItem.quantity;

              variant.quantityLeft = variant.quantityLeft
                ? parseInt(variant.quantityLeft) - productItem.quantity
                : parseInt(variant.quantity) - productItem.quantity;
            // }
          });
        } catch (error) {
          isLowStock = true;
          // console.log("Set ISLOWSTOCK TO TRUE IN TRY CATCH BLOCK TWO");
          return;
        }

        if (!isLowStock) {
          let itemToUpdate = productsInStore.filter((prod)=>{
            return prod.id === item.id
          })[0];
          let cartNewClone = cartList.filter((product)=>{
            return product.id === item.id
          })

          const calculatedQty = cartNewClone.reduce((prev, curr)=>{
            return prev + Number(curr.quantity);
          }, 0)
          const newQty = calculatedQty + Number(item.quantity);
          if(location && location?.state?.fromInstoreOrder === false){
            itemToUpdate.quantity = Number(initialProductItemData.quantity) - newQty;
          }

          this.props.updateProductItemInStore(productsInStore, itemToUpdate);

          updateProductItemInState(productsInStore, itemToUpdate, this);

          this.setState({ cartList: [{ ...productItem }, ...cartList] });
        } else {
          message.warning(strings.productOutOfStock, toastMessageDuration);
        }
      }
    }
  }
};


export const handlePayWithCashValue = function (value, hasDiscount) {
  if (typeof value === "string") return;

  if (hasDiscount) {
    this.setState({ customerCash: value, payWithCashChange: 0 });
  } else {
    const cartTotalAmount = this.getCartTotalAmount();
    let customerChange = value - cartTotalAmount;

    this.setState({ customerCash: value });

    if (value > this.getCartTotalAmount()) {
      this.setState({
        payWithCashChange: customerChange,
      });
    } else {
      this.setState({ payWithCashChange: 0 });
    }
  }
};

export const getCartTotalAmount = function () {
  const cartTotalAmount =
    this.state.cartList.length === 0
      ? 0
      : this.state.cartList.reduce(function (prev, curr) {
          return Number(prev) + Number(curr.amount ? curr.amount : curr.total_price / 1000);
        }, 0);

  return cartTotalAmount.toFixed(2);
};

const milisecondsToDaysConst = 86400000;

export const handlePayment = function (
  paidWith,
  location,
  hasDiscount,
  discountedAmount,
  payment_reference,
  clearDiscount,
  created_at,
  reference_code,
  payment_label 
) {
  console.log("jjhjhj", payment_label);
  
  const {
    user,
    getAllProducts,
    allLoyaltyPrograms,
    getAllCustomers,
    getAllSales,
    payOrder,
    branchProducts,
    products,
    isNitroActive,
    splitPaymentActive,
    activeSplitPayment,
    customers 
  } = this.props;

  const {
    addLoyaltyProgramToSale,
    customerCash,
    isTwoPaymentMethod,
    mtier_amount,
    customerData,
  } = this.state;
  const cartTotalAmount = isTwoPaymentMethod
    ? this.getCartTotalAmount() - mtier_amount - Number(discountedAmount || 0).toFixed(2)
    : this.getCartTotalAmount() - Number(discountedAmount || 0).toFixed(2);

  if (paidWith.includes("card")) this.setState({ recordingCardSale: true });

  if (paidWith.includes("gift_card")) this.setState({ recordingGiftCardSale: true });

  if (paidWith.includes("m_transfer"))
    this.setState({ recordingManualTransfer: true });

  if (
    !splitPaymentActive && !hasDiscount &&
    paidWith.includes("cash") &&
    (customerCash === 0 || customerCash < cartTotalAmount)
  ) {
    message.error(strings.pleaseEnterAmountTendered, 4);
    return;
  }

  if (
    !splitPaymentActive && hasDiscount &&
    paidWith.includes("cash") &&
    (customerCash === 0 || customerCash < cartTotalAmount - discountedAmount)
  ) {
    message.error(strings.pleaseEnterAmountTendered, 4);
    return;
  }

  if (paidWith.includes("cash")) this.setState({ recordingCashSale: true });
  const cartList = cloneArray(this.state.cartList);
  cartList.forEach((cartItem) => {
    cartItem.points = computeLoyaltyPoints.apply(this, [
      cartItem,
      allLoyaltyPrograms,
    ]);
    cartItem.stamps = computeLoyaltyStamps.apply(this, [
      cartItem,
      allLoyaltyPrograms,
    ]);

    if (!(typeof this.state.customerValue === "object")) {
      cartItem.user_id = this.state.customerValue;
    }
  });

  const userId = this.state.customerValue || null;

  const businessBranchId = user.business_branch
    ? user.business_branch.id
    : null;

  const customer = customerData.find(
    (data) => Number(data.user_id) === Number(userId)
  );

  const shared_loyalty_txn = customer
    ? customer.isLoyaltyCustomer
      ? true
      : false
    : false;

  let transactionObj = {};
  // let reference_code;
  let paymentReference;
  if(location.state?.fromInstoreOrder === true){
    reference_code = location.state.reference_code;
     paymentReference = location.state.payment_reference;
  }
  if (hasDiscount && discountedAmount) {
    transactionObj = {
      isPayPaidWithCash: paidWith.includes("cash") ? true : false,
      isPayPaidWithCard: paidWith.includes("card") ? true : false,
      isPayPaidWithMobile: false,
      isPayPaidWithMTransfer: paidWith.includes("m_transfer") ? true : false,
      is_paid_with_customer_account: paidWith.includes("wallet") ? true : false,
      isPayPaidWithPoint:
        paidWith.includes("point") || this.state.isTwoPaymentMethod ? true : false,
      userId,
      businessBranchId,
      shared_loyalty_txn,
      card_payment_ref: paidWith.includes("card") ? payment_reference : "",
      payment_reference: payment_reference,
      reference_code: reference_code,
      stampDuty:
        (user.currency || user.employer.currency === "NGN") &&
        paidWith.includes("card")
          ? 0
          : 0,
      has_discount: hasDiscount,
      discount_amount: discountedAmount,
      created_at: created_at || Date.now(),
    };
  } else {
    transactionObj = {
      isPayPaidWithCash: paidWith.includes("cash") ? true : false,
      isPayPaidWithCard: paidWith.includes("card") ? true : false,
      isPayPaidWithMobile: false,
      isPayPaidWithMTransfer: paidWith.includes("m_transfer") ? true : false,
      is_paid_with_customer_account: paidWith.includes("wallet") ? true : false,
      isPayPaidWithPoint:
        paidWith.includes("point") || this.state.isTwoPaymentMethod ? true : false,
      userId,
      businessBranchId,
      shared_loyalty_txn,
      payment_reference: payment_reference,
      reference_code: reference_code,
      stampDuty:
        (user.currency || user.employer.currency === "NGN") &&
        paidWith.includes("card")
          ? 0
          : 0,
      created_at: created_at || Date.now(),
    };
  }

  if(payment_label){
    transactionObj.payment_label_id = payment_label
  }

  if (paidWith.includes("point") || this.state.isTwoPaymentMethod) {
    // transactionObj.loyalty_id = this.state.loyalty_id;
    transactionObj.mtier_amount = this.state.mtier_amount;
  }

  const cartInfo = {
    cartList,
    addLoyaltyProgramToSale,
  };

  const formattedData = {
    id: Date.now(),
    merchant_id: user.id,
    transaction: cartList,
    userId,
    total: cartTotalAmount,
    created_at: created_at || new Date(),
    staff: "",
    status: "Not Synced",
  };

  if (!navigator.onLine) {
    const component = this;

    Modal.confirm({
      title: strings.areYouSureToBookSaleOffline,
      content: strings.saleWouldBeBookedAutomatically,
      okText: strings.proceed,
      okType: "primary",
      cancelText: strings.no,
      onOk() {
        handleOfflinePayment(
          { transactionObj, cartInfo, paidWith },
          formattedData,
          hasDiscount,
          clearDiscount,
          component,
          shared_loyalty_txn,
          payment_reference,
          reference_code,
          created_at
        );
      },
      onCancel() {
        console.log(strings.offlineSalesBookingCancelled);
      },
    });
    return;
  }

  if(location.state?.fromDraftSales){
    const locationItems = location.state.transaction;

    const serviceItems = locationItems.filter((item)=>{
      return item.product_type === "service"
    });

    const productItems = locationItems.filter((item)=>{
      return item.product_type === "product"
    })
    productItems.map((list)=>{
        list.quantity = parseFloat(list.quantity);
    })

       const productsInStore = user.employer
      ? cloneArray(branchProducts)
      : cloneArray(products);
  
    let productItemInStoreCopy;
  
    productItemInStoreCopy = productItems.map((list)=>{
      return productsInStore?.filter(
        (product) => product.id === list.id 
      )[0];
    });

    if (productItemInStoreCopy) {
      productItemInStoreCopy.map((copy)=>{
          copy.quantity = parseFloat(
            copy.quantity
          );
      })
    }else{
      message.warning("No Product Available", toastMessageDuration);
      this.setState(
        {
          // cartList: [],
          cartTotalAmount: 0,
          payWithCashChange: 0,
          customerValue: [],
          recordingCashSale: false,
          recordingCardSale: false,
          recordingGiftCardSale: false,
          recordingManualTransfer: false,
          addLoyaltyProgramToSale: false,
          isTwoPaymentMethod: false,
          isTransactionModalVisible: false
        });
        
      return;
    }
    const initialProductsData = JSON.parse(localStorage.getItem("products"));

    const initialProductItemData = productItems.map((item)=>{
      return initialProductsData?.filter(
        (product) => product.id === item.item_id
      )[0];
    });

    const instoreItem = productItems.map((list)=>{
      return cartList.filter((items)=>{
        return items.id ===  list.id 
      })[0]
    })

      const checkQuantity = productItems.some((item)=>{
      if(productItemInStoreCopy){
        const newItemInStore = productItemInStoreCopy.filter((copy)=>{
          return copy.id === item.id
        })
        if(newItemInStore){
            return Number(newItemInStore[0].quantity) < Number(item.quantity)
        } 
      }
    })

      if (checkQuantity) {
      message.warning("Not enough Quantity, kindly reduce the quantity or Re-stock", toastMessageDuration);
      this.setState(
        {
          // cartList: [],
          cartTotalAmount: 0,
          payWithCashChange: 0,
          customerValue: [],
          recordingCashSale: false,
          recordingCardSale: false,
          recordingGiftCardSale: false,
          recordingManualTransfer: false,
          addLoyaltyProgramToSale: false,
          isTwoPaymentMethod: false,
          isTransactionModalVisible: false
        });
        
      return;
    }
      if (!checkQuantity) {

        instoreItem.map((item)=>{
          const newItemInstore = productItemInStoreCopy.filter((copy)=>{
            return copy.id === item.id;
          })[0];
          newItemInstore.quantity = Number(newItemInstore.quantity) - Number(item.quantity);
        })
        // || initialProductItemData.quantity - item.quantity;
        this.props.updateProductItemInStore(
          productsInStore,
          productItemInStoreCopy
        );
      };

      updateProductItemInState(productsInStore, productItemInStoreCopy, this);
  }

  if(location.state?.fromInstoreOrder === true){

    const locationItems = location.state.transaction;
    
    const instoreItem = locationItems.filter((list)=>{
      return cartList.filter((items)=>{
        return items.id ===  list.id 
      })[0]
    })
    if (instoreItem.length === 0) {
      window.history.replaceState({}, document.title);
      window.location.reload();
      // return;
    }
    if(instoreItem.includes(undefined)){
      window.history.replaceState({}, document.title);
      window.location.reload();
      // return;
    }

    instoreItem && instoreItem.map((list)=>{
      
        list.quantity = parseFloat(list.quantity);
    })
  
    const productsInStore = user.employer
      ? cloneArray(branchProducts)
      : cloneArray(products);
  
    let productItemInStoreCopy;
  
    productItemInStoreCopy = instoreItem.map((list)=>{
      return productsInStore?.filter(
        (product) => {
          if(list === undefined){
            return
          }else{
            return product.id === list.item_id 
          }
        }
      )[0];
    })
  
    if (productItemInStoreCopy) {
      productItemInStoreCopy && productItemInStoreCopy.map((copy)=>{
        if(copy === undefined){
          return;
        }else{
          copy.quantity = parseFloat(
            copy.quantity
          );
        }
      })
    }else{
      message.warning("No Product Available", toastMessageDuration);
      this.setState(
        {
          // cartList: [],
          cartTotalAmount: 0,
          payWithCashChange: 0,
          customerValue: [],
          recordingCashSale: false,
          recordingCardSale: false,
          recordingGiftCardSale: false,
          recordingManualTransfer: false,
          addLoyaltyProgramToSale: false,
          isTwoPaymentMethod: false,
          isTransactionModalVisible: false
        });
        
      return;
    }

    const initialProductsData = JSON.parse(localStorage.getItem("products"));
    const initialProductItemData = instoreItem.map((item)=>{
      return initialProductsData?.filter(
        (product) => {
          if(item === undefined){
            return;
          }else{
            return product.id === item.id;
          }
        }
      )[0];
    });

   

    const checkQuantity = instoreItem.some((item)=>{
      if(productItemInStoreCopy){
        const newItemInStore = productItemInStoreCopy.filter((copy)=>{
          
            return copy?.id === item?.id;
      
        })
        if(newItemInStore){
          if(newItemInStore.length === 0) {
            return
          }else{
            return Number(newItemInStore[0].quantity) < Number(item.quantity)
          }
        } 
      }
    })

    if (checkQuantity) {
      message.warning("Not enough Quantity, kindly reduce the quantity  or Re-stock", toastMessageDuration);
      this.setState(
        {
          // cartList: [],
          cartTotalAmount: 0,
          payWithCashChange: 0,
          customerValue: [],
          recordingCashSale: false,
          recordingCardSale: false,
          recordingGiftCardSale: false,
          recordingManualTransfer: false,
          addLoyaltyProgramToSale: false,
          isTwoPaymentMethod: false,
          isTransactionModalVisible: false
        });
        
      return;
    }
    if (!checkQuantity) {
        if (instoreItem.includes(undefined)) {
          return instoreItem.shift();
        }
        instoreItem.map((item)=>{
          const newItemInstore = productItemInStoreCopy.filter((copy)=>{
            if(copy === undefined){
              return;
            }else{
              return copy.id === item.id;
            }
          })[0];
          if(newItemInstore === undefined){
            return;
          }else{
            newItemInstore.quantity = Number(newItemInstore.quantity) - Number(item.quantity);  
          }
                 
        })
        this.props.updateProductItemInStore(
          productsInStore,
          productItemInStoreCopy
        );
        // || initialProductItemData.quantity - item.quantity;
      };
      updateProductItemInState(productsInStore, productItemInStoreCopy, this);
      // console.log("STATE UPDATE ONE", this.props.products);
    // }
    // })
   }

  cartInfo.cartList.map((item)=>{
    if(item.custom_quantities !== undefined ){
      if(item.custom_quantities.length > 0){
        return item.quantity = Number(item.quantity) * Number(item.custom_quantities[0].quantity)
      }
    }else{
      return item.quantity = item.quantity
    }
  });
  
  // console.log("my customer", customers);
  this.props
    .recordSale(
      { ...transactionObj },
      cartInfo.cartList,
      cartInfo.addLoyaltyProgramToSale,
      shared_loyalty_txn,
      payment_reference,
      reference_code,
    )
    .then(async (res) => {
      // console.log("ress", res);
      if (location.state?.fromInstoreOrder === true) {
        if (res.status === 200) {
          const orderID = location.state.order_id
          payOrder(orderID);
        }
      }
      if (res && res.status === 200) {
        const {
          data: { id, has_discount, discount_amount },
        } = res;

        //update product qunatity from Nitroserver
        if( isNitroActive ){
          let skus = [];
          await cartList.map((item)=>{
            skus.push(item.product_sku)
          });

          if(skus.length !== 0){
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            let nitroLocation;
            if(user.employer){
              nitroLocation = userDetails?.employer?.nitro_location;
            }else{
              nitroLocation = userDetails?.nitro_location;
            }

            let NITROBASEURL;
            if(nitroLocation === ""){
                NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
            }else if(nitroLocation === null){
                NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
            }else{
                NITROBASEURL = nitroLocation;
            }
            // const NITROBASEURL = nitroLocation !== "" && nitroLocation !== null ? `${nitroLocation}:7890` : process.env.REACT_APP_NITRO_BASE_URL;
            
            await Axios.put(`${NITROBASEURL}/products`, { skus })
            .then((res)=>{
              console.log("Nitro Server Updated...");
            })
            .catch((err)=>{
              console.log("err",err);
            })
          }
        }

        if(splitPaymentActive) activeSplitPayment(false);
        // Clear Discount Values
        hasDiscount && clearDiscount && clearDiscount();

        user.ref_code?.toLowerCase() === "vpay" &&
          addClusterPurchase(
            payment_reference,
            cartInfo.cartList,
            this.props.categories
          );

        // Delete Bill if Bill is in Draft Sales
        if (location && location.state && location.state.fromDraftSales) {
          const { isPermitted } = this.state;
          
          const draftSalesList = JSON.parse(localStorage.getItem("draftSales"));

          const filteredDraftSales =
            draftSalesList.length &&
            draftSalesList.filter((bill) => bill.id !== location.state.bill_id);

          localStorage.setItem(
            "draftSales",
            JSON.stringify(filteredDraftSales)
          );
          if ( !isPermitted ) this.setState({ isPermitted: true })
          console.log("Draft Sales Has been deleted");
        }
        this.setState({
          visible: false,
          twoPointModalVisible: false,
        });
        if(location.state?.fromInstoreOrder){
          window.history.replaceState({}, document.title)
        }
        // if(location.state?.fromDraftSales){
        //   window.history.replaceState({}, document.title);
        // }
        // getAllCustomers().then(async (res) => {
          // console.log("CUSTOMER DATA HAS BEEN REFETCHED!!!");
          this.isRecordSalePaymentVisible && this.toggleTransactionModal();
          this.toggleCartOnMobile();
          // !isNitroActive && getAllProducts();
          // !isNitroActive && getAllSales();

          // const customerList =
            // res && res.data.filter((customer) => customer.deleted === false);

          const customer =
            // this.state.customerName &&
            customers.find(
              (item) =>
                item.user_id === this.state.customerValue ||
                item.customer_id === this.state.customerValue
            );

          if (paidWith === "cash" || paidWith === "card") {
            // message.info(strings.covid19Message + "😷", 5);
          }

          const { isSubscribedToMultiLevel } = this.props;

          // Check If Merchant is Subscribed to MultiLevel and if they have any Active Multi-Level Programs

          Modal.success({
            title: strings.saleSuccessfullyRecorded,
            content: (
              <div className="transaction-extras">
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <Button type="primary">{strings.printReceipt}</Button>
                    )}
                    content={() => this.componentRef}
                  />
                </div>

                <div style={{ display: "none" }}>
                  <SalesReceipt
                    currency={user.currency || user.employer.currency}
                    // saleTotal={hasDiscount ? (this.getCartTotalAmount() - discount_amount).toFixed(2) : this.getCartTotalAmount()}
                    saleTotal={cartTotalAmount}
                    paidWith={
                      paidWith === "m_transfer" ? "Bank Transfer" : paidWith
                    }
                    transaction={this.state.cartList}
                    saleId={id}
                    purchaseTime={moment(created_at || Date.now()).format(
                      "DD-MM-YYYY hh:mm a"
                    )}
                    user={this.props.user}
                    selectedCustomer={this.state.customerName}
                    customer={customer}
                    allLoyaltyPrograms={allLoyaltyPrograms}
                    payment_reference={payment_reference || paymentReference}
                    ref={(el) => (this.componentRef = el)}
                    hasDiscount={has_discount}
                    discountAmount={discount_amount}
                  />
                </div>

                <Checkbox
                  className="mt-20"
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({ isReceiptModalVisible: true });
                      this.setState({ saleId: id });
                    } else {
                      this.setState({ isReceiptModalVisible: false });
                    }
                  }}
                >
                  {strings.sendReceiptToEmail}
                </Checkbox>

                <Checkbox
                  disabled={!customer}
                  className="send-thank-you-checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({ isSendThankSMSModalVisible: true });
                      this.setState({ saleId: id });
                    } else {
                      this.setState({ isSendThankSMSModalVisible: false });
                    }
                  }}
                >
                  {strings.sendThankYouSms}
                </Checkbox>

                {this.props.isFezDeliveryActive && <Checkbox
                  className="mt-20 send-thank-you-checkbox"
                  onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ requestDeliveryModalOpen: true });
                    } else {
                      this.setState({ requestDeliveryModalOpen: false });
                    }
                  }}
                >
                  Request Delivery
                </Checkbox>}

                {/* <Checkbox
                  className="mt-20 send-thank-you-checkbox"
                  onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ isShippingAddressVisible: true });
                      this.setState({ saleId: id });
                    } else {
                      // this.setState({ isShippingAddressVisible: false });
                      this.setState({ isShippingAddressVisible: true });
                      this.setState({ saleId: id });
                    }
                  }}
                >
                  {strings.sendShippingDetails}
                </Checkbox> */}

                {/* <p>
                  {multiLevelResult.messages.length
                    ? multiLevelResult.messages
                    : typeof multiLevelResult === "object"
                    ? `Customer has ${multiLevelResult.points[0].points_value} points`
                    : ""}
                </p> */}
              </div>
            ),
            onOk: () => {
              //update booking payment and clearbooking
              if (this.state.currentBooking) {
                this.state.currentBooking.payment_status === "not paid"
                  ? this.updateBookingPayment(this.state.currentBooking.id)
                  : this.setState({ currentBooking: null });
              }
              if(location.state?.fromInstoreOrder){
                window.history.replaceState({}, document.title)
              }
              
              // If Merchant Account's Expired, Show Renewal Modal
              const typesObj = {
                ngBasic: "basic-pay",
                ngPro: "pro-pay",
                ghBasic: "basic-pay-gh",
                ghPro: "pro-pay-gh",
                intBasic: "basic-pay-int",
                intPro: "pro-pay-int",
              };

              const business = this.props.user.employer
                ? { ...this.props.user.employer }
                : { ...this.props.user };

              if (business.subscription_plan === "Free") {
                const country =
                  business.currency === "NGN"
                    ? "ng"
                    : business.currency === "GHS"
                    ? "gh"
                    : "int";

                let subscription_plan = "Basic";

                const subPaymentType = typesObj[country + subscription_plan];

                const daysLeft = Math.ceil(
                  (new Date(business.subscription_expires_on) - Date.now()) /
                    milisecondsToDaysConst
                );

                daysLeft <= 0 &&
                  Modal.confirm({
                    title: strings.yourSubscriptionHasExpired,
                    okText: (
                      <a
                        href={`https://loystar.co/loystar-${subPaymentType}`}
                        target="new"
                        rel="noopener"
                      >
                        Renew
                      </a>
                    ),
                  });
                
              } else {
                const country =
                  business.currency === "NGN"
                    ? "ng"
                    : business.currency === "GHS"
                    ? "gh"
                    : "int";

                const subPaymentType =
                  typesObj[country + business.subscription_plan];
 
                const daysLeft = Math.ceil(
                  (new Date(business.subscription_expires_on) - Date.now()) /
                    milisecondsToDaysConst
                );

                let secondsToGo = 60;

                const modal =
                  daysLeft <= 0 &&
                  Modal.success({
                    title: `Hold On ${secondsToGo}`,
                    content: <span>{strings.yourSubscriptionHasExpired}</span>,
                    okText: (
                      <a
                        href={`https://loystar.co/loystar-${subPaymentType}`}
                        target="new"
                        rel="noopener"
                      >
                        {strings.renew}
                      </a>
                    ),
                  });

                const timer =
                  daysLeft <= 0 &&
                  setInterval(() => {
                    secondsToGo--;
                    modal.update({
                      title: `Hold On ${secondsToGo}`,
                    });
                  }, 1000);

                daysLeft <= 0 &&
                  setTimeout(() => {
                    clearInterval(timer);
                    modal.destroy();
                  }, secondsToGo * 1000);
              }

              this.setState({
                cartList: [],
                cartTotalAmount: 0,
                payWithCashChange: 0,
                customerValue: [],
                recordingCashSale: false,
                recordingCardSale: false,
                recordingGiftCardSale: false,
                recordingManualTransfer: false,
                addLoyaltyProgramToSale: false,
                isPayingWithPoints: false,
                isTwoPaymentMethod: false,
                isTransactionModalVisible: false,
                customerCash: null,
                checkInCustomer: null,
                shouldBlurIn: true
              });
              // if(location.state?.fromDraftSales){
              //   window.history.replaceState({}, document.title);
              //   window.location.reload();
              // }
            },
            onCancel: () => {
              this.setState({
                cartList: [],
                cartTotalAmount: 0,
                payWithCashChange: 0,
                customerValue: [],
                recordingCashSale: false,
                recordingCardSale: false,
                recordingGiftCardSale: false,
                recordingManualTransfer: false,
                addLoyaltyProgramToSale: false,
                isTwoPaymentMethod: false,
                shouldBlurIn: true
              });
              // if(location.state?.fromDraftSales){
              //   window.history.replaceState({}, document.title)
              // }
              if(location.state?.fromInstoreOrder){
                window.history.replaceState({}, document.title)
              }
            },
          });

          const isPaidWithPoint =
            paidWith === "point" || this.state.isTwoPaymentMethod;

          if (
            !isPaidWithPoint &&
            isSubscribedToMultiLevel &&
            addLoyaltyProgramToSale
          ) {
            const { client } = this.props;

            const data = {
              client,
              user,
              customer,
              amount_spent: hasDiscount
                ? cartTotalAmount - discountedAmount
                : cartTotalAmount,
            };

            handleMultiLevelEnrollment(data);
          }
        // });
      }
    }).catch((err)=>{
      let erro;
      err.response.data === '' 
        ? erro = "Unable to complete sales, please try again" 
          : err.response.data.error === "Insufficient Quantity" ? erro = "Insufficient Quantity, kindly update individual product stock to purchase bundle": erro = err.response.data?.error || err.response.data;
      // console.log("err", err, err.response.data.error);
      Modal.error({
        title: erro,
        onOk: () => {
          this.setState({
            cartList: [],
            cartTotalAmount: 0,
            payWithCashChange: 0,
            customerValue: [],
            recordingCashSale: false,
            recordingCardSale: false,
            recordingGiftCardSale: false,
            recordingManualTransfer: false,
            addLoyaltyProgramToSale: false,
            isPayingWithPoints: false,
            isTwoPaymentMethod: false,
            isTransactionModalVisible: false,
            customerCash: null,
            checkInCustomer: null,
          });
        }
      })
    });
};

export const handleMultiLevelEnrollment = async ({
  client,
  customer,
  user,
  amount_spent,
}) => {
  try {
    const result = await client.query({
      query: LIST_LOYALTIES,
    });

    const multiLevelLoyalties = result.data ? result.data.listLoyalties : [];

    // NB: Merchant should only have one active loyalty program running at a time.
    const activeLoyalty =
      multiLevelLoyalties.length &&
      multiLevelLoyalties.find(
        (loyalty) => loyalty.status.toLowerCase() === "active"
      );

    if (activeLoyalty) {
      // Enroll customer unto active loyalty based on "Amount Spent".
      const { data: enrollMentData, errors } = await client
        .mutate({
          mutation: ENROLL_CUSTOMER_WITH_AMOUNT_SPENT,
          variables: {
            loyalty_id: activeLoyalty.id,
            customer: {
              customer_id: customer.user_id,
              first_name: customer.first_name,
              last_name: customer.last_name,
              email: customer.email,
            },
            amount_spent: Number(amount_spent),
          },
          refetchQueries: [
            {
              query: LIST_TIERS,
              variables: { loyalty_id: activeLoyalty.id },
            },
          ],
        })
        .catch((err) => console.log(err));

      if (errors && errors.length) {
        console.log("Error Enrolling Customer", errors);
        message.error(errors[0].message, 5);
      }

      // console.log("Customer EnrollMent Successful", enrollMentData);

      return enrollMentData && enrollMentData.enrollCustomerWithAmountSpent;
    }
  } catch (error) {
    console.log(error);
  }
};

const handleOfflinePayment = function (
  salesObj,
  formattedData,
  hasDiscount,
  clearDiscount,
  component,
  shared_loyalty_txn,
  payment_reference,
  reference_code,
  created_at
) {
  const { user, getAllProducts, recordSale } = component.props;

  const { transactionObj, cartInfo, paidWith } = salesObj;

  const transactionDate = new Date(formattedData.created_at);

  const payload = {
    transactionObj,
    cartList: cartInfo.cartList,
    addLoyaltyProgramToSale: cartInfo.addLoyaltyProgramToSale,
    shared_loyalty_txn,
    payment_reference,
    created_at,
    reference_code,
  };

  saveOfflineSales({ payload, formattedData });

  component.toggleTransactionModal();
  component.toggleCartOnMobile();
  getAllProducts();

  // Clear Discount Values
  hasDiscount && clearDiscount();

  Modal.success({
    title: strings.saleSuccessfullyRecorded,
    content: (
      <div style={{ display: "flex" }}>
        <ReactToPrint
          trigger={() => <Button type="primary">Print Receipt</Button>}
          content={() => component.componentRef}
        />
        <div style={{ display: "none" }}>
          <SalesReceipt
            currency={user.currency}
            saleTotal={component.getCartTotalAmount()}
            paidWith={paidWith}
            transaction={component.state.cartList}
            saleId="recorded offline"
            purchaseTime={moment(transactionDate).format("DD-MM-YYYY hh:mm a")}
            user={component.props.user}
            ref={(el) => (component.componentRef = el)}
            payment_reference={payment_reference}    
          />
        </div>
      </div>
    ),
    onOk: () => {
      component.setState({
        cartList: [],
        cartTotalAmount: 0,
        payWithCashChange: 0,
        customerValue: [],
        recordingCashSale: false,
        recordingCardSale: false,
        recordingGiftCardSale: false,
        recordingManualTransfer: false,
        addLoyaltyProgramToSale: false,
      });
    },
    onCancel: () => {
      component.setState({
        cartList: [],
        cartTotalAmount: 0,
        payWithCashChange: 0,
        customerValue: [],
        recordingCashSale: false,
        recordingCardSale: false,
        recordingGiftCardSale: false,
        recordingManualTransfer: false,
        addLoyaltyProgramToSale: false,
      });
    },
  });

  component.setState({
    cartList: [],
    cartTotalAmount: 0,
    payWithCashChange: 0,
    customerValue: [],
    recordingCashSale: false,
    recordingCardSale: false,
    recordingGiftCardSale: false,
    addLoyaltyProgramToSale: false,
  });
};

const saveOfflineSales = function (sale) {
  const offlineSalesList = JSON.parse(localStorage.getItem("offlineSales"));

  const newOfflineSales =
    offlineSalesList && offlineSalesList.length
      ? [...offlineSalesList, sale]
      : [sale];

  localStorage.setItem("offlineSales", JSON.stringify(newOfflineSales));
};

export const handleNitroSearch = async function (e) {
  const { value } = e.target;
  const { user } = this.props;

  if(e.code === "Enter"){
    if(value === "") return message.warning("Scan to Filter");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let nitroLocation;

    if(user.employer){
      nitroLocation = userDetails?.employer?.nitro_location;
    }else{
      nitroLocation = userDetails?.nitro_location;
    }
    

    let NITROBASEURL;
    if(nitroLocation === ""){
      NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
    }else if(nitroLocation === null){
      NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
    }else{
      NITROBASEURL = nitroLocation;
    }
    
    // console.log(nitroLocation, value, NITROBASEURL);
    await Axios.get(`${NITROBASEURL}/products/${value}`).then((res)=>{
      if(res.status === 200){
        const products = [ res.data.data ];
        if(products.length === 1){
          if(products[0].has_custom_qty){
            const checks = {...res.data.data};
            // console.log(res.data.data, products);
            checks.quantityCheck = Number(checks.quantity);
            this.setState({ 
              newCustomProduct: checks,
              chooseCustomQty: true,
              products,
              searchTerm: "" 
            })
          }else if(products[0].variants.length !== 0){
            const checks = {...res.data.data};
            checks.quantityCheck = Number(checks.quantity);
            this.setState({ 
              newVariantProduct: checks, 
              products: products,
              chooseVariant: true,
              searchTerm: "" 
            })
          }else{
            const checks = {...res.data.data};
            if(checks.track_inventory){
              if( Number(checks.quantity) < 1){
                return message.warn("Product out of stock. Please re-stock.");
              }
              checks.quantityCheck = Number(checks.quantity);
              this.addProductToCart( checks );
              return this.setState({  searchTerm: "", products: checks,  chooseVariant: false, chooseCustomQty: false });
            }
  
            this.addProductToCart( res.data.data );
            return this.setState({ products: products, searchTerm: "", chooseVariant: false, chooseCustomQty: false });
          }
        }else if(products.length > 1){
          this.setState({ products: products, searchTerm: "" });
        }
      }
    })
    .catch(async (err)=>{
      // message.info("Nitro server is inactive, searching products would take a few seconds. Contact admin to activate Nitro");

      this.setState({ isGettingFromServer: true })
      await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/merchant_product_by_sku/${value}`)
      .then((res)=>{
        const products = [ res.data ];
        if(products.length === 0) return this.setState({ products: [], searchTerm: "", isNitroSearchEmpty: true, isGettingFromServer: false  });
        if(products.length === 1){
          if(products[0].has_custom_qty){
            const checks = {...res.data};
            checks.quantityCheck = Number(checks.quantity);
            this.setState({ 
              newCustomProduct: checks,
              chooseCustomQty: true,
              isGettingFromServer: false,
              products,
              searchTerm: ""
            })
          }else if(products[0].variants.length !== 0){
            const checks = {...res.data};
            checks.quantityCheck = Number(checks.quantity);
            this.setState({ 
              newVariantProduct: checks, 
              products: products,
              chooseVariant: true,
              isGettingFromServer: false,
              searchTerm: ""  
            })
          }else{
            const checks = {...res.data};
            if(checks.track_inventory){
              if( Number(checks.quantity) < 1){
                return message.warn("Product out of stock. Please re-stock.");
              }
              checks.quantityCheck = Number(checks.quantity);
              this.addProductToCart( checks );
              this.setState({  searchTerm: "", isGettingFromServer: false  });
              return;
            }
  
            this.addProductToCart( res.data );
            this.setState({ products: products, searchTerm: "", isGettingFromServer: false  });
          }
        }else if(products.length > 1){
          this.setState({ products: products, searchTerm: "", isGettingFromServer: false  });
        }
      })
      .catch((err)=>{
        console.log(err, err.message);
        this.setState({ products: [], searchTerm: "", isNitroSearchEmpty: true, isGettingFromServer: false  });
      }) 
    })
  }
}

export const handleNitroVariantSelect = function (product, variant) {
  if(Number(variant.quantity) < 1) return message.warn("Product out of stock. Please re-stock.")
  let variantProduct = { ...product};

  
  variantProduct.variants = [variant];

  const { cartList } = this.state;
  const { user } = this.props;

  const isItemPresent = cartList.filter((item)=>{
    return item.id !== product.id
  });

  const isItemInCartList = cartList.filter((item)=>{
    return item.id === product.id;
  });

  if( isItemInCartList.length === 0){
    variantProduct.quantity = 1;

    const price =
    variantProduct.variants.length > 0
        ? variantProduct.variants[0].price
        : variantProduct.price;

        variantProduct.amount = price * variantProduct.quantity;
        variantProduct.points = variantProduct.points ? variantProduct.points : 0;
        variantProduct.stamps = variantProduct.stamps ? variantProduct.stamps : 0;
        variantProduct.product_id = variantProduct.id;
        variantProduct.business_branch_id = user.business_branch
        ? user.business_branch.id
        : null;
        variantProduct.product_type = "product";
        variantProduct.merchant_loyalty_program_id = variantProduct.merchant_loyalty_program_id === 0 ? null : variantProduct.merchant_loyalty_program_id;

        product.quantity = Number(product.quantity) - 1;
    if(isItemPresent.length === 0){
      return this.setState({ cartList: [ variantProduct ], products: [product]});
    }else{
      return this.setState({ cartList: [ variantProduct, ...isItemPresent], products: [product]});
    }
  }else{
    if( isItemInCartList.length === 1){
      
      if( isItemInCartList[0].variants[0].id === variant.id){
        if(isItemInCartList[0].quantity >= Number(variant.quantity)){
          return message.warn("Product out of stock. Please re-stock.");
        };
        variantProduct.quantity = Number(isItemInCartList[0].quantity) + 1;
        const price =
        variantProduct.variants.length > 0
            ? variantProduct.variants[0].price
            : variantProduct.price;

        variantProduct.amount = price * variantProduct.quantity;
        variantProduct.points = variantProduct.points ? variantProduct.points : 0;
        variantProduct.stamps = variantProduct.stamps ? variantProduct.stamps : 0;
        variantProduct.product_id = variantProduct.id;
        variantProduct.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        variantProduct.product_type = "product";

        variantProduct.merchant_loyalty_program_id = variantProduct.merchant_loyalty_program_id === 0 ? null : variantProduct.merchant_loyalty_program_id;
        // product.quantity = Number(product.quantity) - variantProduct.quantity;
        if(isItemPresent.length === 0){
          return this.setState({ cartList: [ variantProduct ], products: [product]});
        }else{
          return this.setState({ cartList: [ variantProduct, ...isItemPresent], products: [product]});
        }
      }else{
        variantProduct.quantity = 1;
    
        const price =
          variantProduct.variants.length > 0
              ? variantProduct.variants[0].price
              : variantProduct.price;
    
        variantProduct.amount = price * variantProduct.quantity;
        variantProduct.points = variantProduct.points ? variantProduct.points : 0;
        variantProduct.stamps = variantProduct.stamps ? variantProduct.stamps : 0;
        variantProduct.product_id = variantProduct.id;
        variantProduct.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        variantProduct.product_type = "product";
        variantProduct.merchant_loyalty_program_id = variantProduct.merchant_loyalty_program_id === 0 ? null : variantProduct.merchant_loyalty_program_id;
  
        if(isItemPresent.length === 0){
          return this.setState({ cartList: [ variantProduct, ...isItemInCartList ]})
        }else{
          return this.setState({ cartList: [ variantProduct, ...isItemInCartList, ...isItemPresent ]});
        }
      }
    }else if( isItemInCartList.length > 1){
      let otherIsItemCart = [];
      let itemInCart = []
      isItemInCartList.map((item)=>{
        if(item.variants[0].id === variant.id){
          item.quantity = Number(isItemInCartList[0].quantity) + 1;
          const price =
          item.variants.length > 0
              ? item.variants[0].price
              : item.price;

          item.amount = price * item.quantity;
          item.points = item.points ? item.points : 0;
          item.stamps = item.stamps ? item.stamps : 0;
          item.product_id = item.id;
          item.business_branch_id = user.business_branch
            ? user.business_branch.id
            : null;
          item.product_type = "product";
          item.merchant_loyalty_program_id = item.merchant_loyalty_program_id === 0 ? null : item.merchant_loyalty_program_id;
          item.variants[0].quantityLeft = Number(item.variants[0].quantity) - Number(variantProduct.quantity)
          itemInCart.push(item)
        }else{
          otherIsItemCart.push(item);
        }
      });
      const q1 = itemInCart.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
      const q2 = otherIsItemCart.reduce((prev, curr) => {return prev + Number(curr.quantity)}, 0);
      product.quantity = Number(product.quantity) - (q1+q2);
      if(isItemPresent.length === 0){
        return this.setState({ cartList: [ ...itemInCart, ...otherIsItemCart ], products: [product]});
      }else{
        return this.setState({ cartList: [ ...itemInCart, ...otherIsItemCart, ...isItemPresent],  products: [product]});
      }
    }
  }
}

export const handleNitroCustomSelect = function (product, custom) {
  // if(Number(variant.quantity) < 1) return message.warn("Product out of stock. Please re-stock.")
  let customProduct = { ...product};

  
  customProduct.custom_quantities = [...custom];

  const { cartList } = this.state;
  const { user } = this.props;

  const isItemPresent = cartList.filter((item)=>{
    return item.id !== product.id
  });

  const isItemInCartList = cartList.filter((item)=>{
    return item.id === product.id;
  });

  if( isItemInCartList.length === 0){
    customProduct.quantity = product.quantity;

    const price =
    customProduct.custom_quantities.length > 0
        ? customProduct.custom_quantities[0].price
        : customProduct.price;

        customProduct.amount = price * customProduct.quantity;
        customProduct.points = customProduct.points ? customProduct.points : 0;
        customProduct.stamps = customProduct.stamps ? customProduct.stamps : 0;
        customProduct.product_id = customProduct.id;
        customProduct.business_branch_id = user.business_branch
        ? user.business_branch.id
        : null;
        customProduct.product_type = "product";
        customProduct.merchant_loyalty_program_id = customProduct.merchant_loyalty_program_id === 0 ? null : customProduct.merchant_loyalty_program_id;

        product.quantity = Number(product.quantityCheck) - (customProduct.quantity * Number(custom[0].quantity));

    if(isItemPresent.length === 0){
      return this.setState({ cartList: [ customProduct ], products: [product], searchTerm: ""});
    }else{
      return this.setState({ cartList: [ customProduct, ...isItemPresent], products: [product], searchTerm: ""});
    }
  }else{
    if( isItemInCartList.length === 1){
      
      if( isItemInCartList[0].custom_quantities[0].id === custom[0].id){
        if(isItemInCartList[0].quantity >= Number(product.quantity)){
          return message.warn("Product out of stock. Please re-stock.");
        };
        customProduct.quantity = Number(isItemInCartList[0].quantity) + product.quantity;
        const price =
        customProduct.custom_quantities.length > 0
            ? customProduct.custom_quantities[0].price
            : customProduct.price;

        customProduct.amount = price * customProduct.quantity;
        customProduct.points = customProduct.points ? customProduct.points : 0;
        customProduct.stamps = customProduct.stamps ? customProduct.stamps : 0;
        customProduct.product_id = customProduct.id;
        customProduct.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        customProduct.product_type = "product";

        customProduct.merchant_loyalty_program_id = customProduct.merchant_loyalty_program_id === 0 ? null : customProduct.merchant_loyalty_program_id;
        product.quantity = Number(product.quantityCheck) - (customProduct.quantity * Number(custom[0].quantity));
        if(isItemPresent.length === 0){
          return this.setState({ cartList: [ customProduct ], products: [product], searchTerm: ""});
        }else{
          return this.setState({ cartList: [ customProduct, ...isItemPresent], products: [product], searchTerm: ""});
        }
      }else{
        customProduct.quantity = product.quantity;
    
        const price =
          customProduct.custom_quantities.length > 0
              ? customProduct.custom_quantities[0].price
              : customProduct.price;
    
        customProduct.amount = price * customProduct.quantity;
        customProduct.points = customProduct.points ? customProduct.points : 0;
        customProduct.stamps = customProduct.stamps ? customProduct.stamps : 0;
        customProduct.product_id = customProduct.id;
        customProduct.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        customProduct.product_type = "product";
        customProduct.merchant_loyalty_program_id = customProduct.merchant_loyalty_program_id === 0 ? null : customProduct.merchant_loyalty_program_id;
  
        const quanl = isItemInCartList.reduce((prev, curr)=>{
          return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
        }, 0);
        product.quantity = Number(product.quantityCheck) - (quanl + (customProduct.quantity * Number(custom[0].quantity)));
        if(isItemPresent.length === 0){
          return this.setState({ cartList: [ customProduct, ...isItemInCartList ], products: [product], searchTerm: ""})
        }else{
          return this.setState({ cartList: [ customProduct, ...isItemInCartList, ...isItemPresent ],  products: [product ], searchTerm: ""});
        }
      }
    }else if( isItemInCartList.length > 1){
      let otherIsItemCart = [];
      let itemInCart = []
      isItemInCartList.map((item)=>{
        if(item.custom_quantities[0].id === custom[0].id){
          item.quantity = Number(item.quantity) + product.quantity;
          const price =
          item.custom_quantities.length > 0
              ? item.custom_quantities[0].price
              : item.price;

          item.amount = price * item.quantity;
          item.points = item.points ? item.points : 0;
          item.stamps = item.stamps ? item.stamps : 0;
          item.product_id = item.id;
          item.business_branch_id = user.business_branch
            ? user.business_branch.id
            : null;
          item.product_type = "product";
          item.merchant_loyalty_program_id = item.merchant_loyalty_program_id === 0 ? null : item.merchant_loyalty_program_id;
          // item.variants[0].quantityLeft = Number(item.variants[0].quantity) - Number(customProduct.quantity)
          itemInCart.push(item)
        }else{
          otherIsItemCart.push(item);
        }
      });
      const quanl = isItemInCartList.reduce((prev, curr)=>{
        return prev + (Number(curr.quantity) * Number(curr.custom_quantities[0].quantity))
      }, 0);

      product.quantity = Number(product.quantityCheck) - quanl;
      if(isItemPresent.length === 0){
        return this.setState({ cartList: [ ...itemInCart, ...otherIsItemCart ], products: [product], searchTerm:""});
      }else{
        return this.setState({ cartList: [ ...itemInCart, ...otherIsItemCart, ...isItemPresent], products: [product], searchTerm: ""});
      }
    }
  }
}

export const hanldeProductSearch = function (e) {
  const { isNitroActive } = this.props;
  if( isNitroActive ){
    const value = e.target.value;
    this.setState({ searchTerm: value });
  }else{
    const func = throttle(() => {
      const value = e.target.value;
  
      this.setState({ searchTerm: value });
      const allProducts = this.props.user.employer
        ? this.props.branchProducts
        : this.props.products;
     
      
  
      const products = allProducts.filter((product) => {
        return (
          product.name.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
          (product.sku &&
            product.sku.toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
          (product.product_sku &&
            product.product_sku.toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
          (product.description &&
            product.description.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        );
      });

     
      // const product2s = allProducts.filter((product) => {
      //   return (
      //     product.name.toLowerCase().includes(value.toLowerCase()) ||
      //     (product.sku &&
      //       product.sku.toLowerCase().includes(value.toLowerCase())) ||
      //     (product.product_sku &&
      //       product.product_sku.toLowerCase().includes(value.toLowerCase())) ||
      //     (product.description &&
      //       product.description.toLowerCase().includes(value.toLowerCase()))
      //   );
      // });
      // console.log("produc", allProducts, value, product2s, products);
  
      if (value === "") {
        this.setState({ products: allProducts });
      } else {
        if (!(products.length === 0)) {
          this.setState({ products });
        } else {
          this.setState({ products: allProducts });
        }
      }
    }, 100);
    func();
  }
};

export const handleCustomerSearchFieldChange = function (value, ...arg) {
  if (value === "add") {
    this.setState({
      isAddCustomer: true
    })
    this.openAddCustomerModal();
  } else {
    this.setState({
      customerValue: value,
      customerName: arg[0] && arg[0].props.children.split(":")[0],
      addLoyaltyProgramToSale: isNaN(value) ? false : true,
    });
  }
};

export const handleCategoryChange = function (value) {
  let products = this.props.user.employer
    ? this.props.branchProducts
    : this.props.products;

  if (value === "all") {
    this.setState({ products });
  } else {
    products = products.filter(
      (product) => product.merchant_product_category_id == value
    );

    this.setState({ products, categoryToShow: value });
  }
};

export const handleServiceCategoryChange = function (value) {
  const isServiceProduct = localStorage.getItem("service_product");
  
  if(isServiceProduct){
    const service = JSON.parse(isServiceProduct);
  
    if (value === "all") {
      this.setState({ allServices: service });
    } else {
      const serviceSearch = service.filter((serv)=>{
        return serv.service_type.toLowerCase() === value.toLowerCase();
      });
  
      this.setState({ allServices: serviceSearch });
    }

  }
  
};

export const hanldeEnterOnSearchInput = function (e) {
    if (e.keyCode === 13) {
      const allProducts = this.props.user.employer
        ? this.props.branchProducts
        : this.props.products;
      const currentProduct = { ...this.state.products[0] };
  
  
      if (
        this.state.products.length === 1 &&
        currentProduct.quantity > 0 &&
        currentProduct.custom_quantities.length > 0
      ) {
        this.props.showCustomQtyModal(currentProduct);
        this.setState({ searchTerm: "", products: allProducts });
      // }else if (
      //   this.state.products.length === 1 &&
      //   (currentProduct.quantity > 0 ||
      //     typeof currentProduct.quantity === "object") &&
      //   currentProduct.custom_quantities.length === 0
      // ) {
      //   console.log("h2");
      //   currentProduct.quantity = 1;
      //   this.addProductToCart(currentProduct);
      //   this.setState({ searchTerm: "", products: allProducts });
      }else if (
        this.state.products.length === 1 &&
        (currentProduct.quantity > 0 ||
          typeof currentProduct.quantity === "object") &&
        currentProduct.variants.length === 0
      ) {
        currentProduct.quantity = 1;
        this.addProductToCart(currentProduct);
        this.setState({ searchTerm: "", products: allProducts });
      } else if (
        this.state.products.length === 1 &&
        currentProduct.quantity > 0 &&
        currentProduct.variants.length > 0
      ) {
        this.props.showVariantModal(currentProduct);
        this.setState({ searchTerm: ""});
      } 
    } else if (e.keyCode === 8 && e.target.value.length === 1) {
      this.props.clearShowVariantModalData();
    }
};

export const handleBundleOnSearchInput = function (e) {
  this.setState({ searchTerm: e.target.value })
  const isBundleProduct = localStorage.getItem("product_bundle");
  
  if(isBundleProduct){
    const bundle = JSON.parse(isBundleProduct);
    const bundleToFind = e.target.value;
    const bundleSearch = bundle.filter((bund)=>{
      return bund.name.toLowerCase().includes(bundleToFind.toLowerCase())
    });

    if(bundleSearch.length > 0){
      this.setState({ allBundles: bundleSearch })
    }

  }
};

export const handleServiceOnSearchInput = function (e) {
  this.setState({ searchTerm: e.target.value })
  const isServiceProduct = localStorage.getItem("service_product");
  
  if(isServiceProduct){
    const service = JSON.parse(isServiceProduct);
    const serviceToFind = e.target.value;
    const serviceSearch = service.filter((bund)=>{
      return bund.service_name.toLowerCase().includes(serviceToFind.toLowerCase())
    });

    if(serviceSearch.length > 0){
      this.setState({ allServices: serviceSearch })
    }

  }
};

const parseLoyaltyPointData = (loyaltyPointData) => {
  if (loyaltyPointData && loyaltyPointData !== "0") {
    const data = loyaltyPointData.split(",");
    return {
      merchant_loyalty_program_id: Number(data[0]),
      program_type: data[1],
    };
  }

  return {};
};

export const updateProductItemInState = (
  productsInStore,
  productToUpdate,
  component
) => {
  const updatedProducts =
    productsInStore &&
    productsInStore.map((product) =>
      product.id === productToUpdate.id ? productToUpdate : product
    );
  component.setState({ products: updatedProducts });
};

export const computeLoyaltyPoints = function (product, loyaltyPrograms) {
  const loyaltyArr = loyaltyPrograms.filter(
    (loyalty) => loyalty.id === product.merchant_loyalty_program_id
  );

  if (loyaltyArr.length > 0) {
    const points =
      loyaltyArr[0].program_type.toLowerCase() === "simplepoints"
        ? Number(product.quantity) * Number(product.price)
        : 0;
    return points;
  }

  return 0;
};

export const computeLoyaltyStamps = function (product, loyaltyPrograms) {
  const loyaltyArr = loyaltyPrograms.filter(
    (loyalty) => loyalty.id === product.merchant_loyalty_program_id
  );

  if (loyaltyArr.length > 0) {
    const stamps =
      loyaltyArr[0].program_type.toLowerCase() === "stampsprogram"
        ? product.quantity
        : 0;
    return stamps;
  }

  return 0;
};

export const handleInvoiceValues = function (event) {
  if (
    event.target.value &&
    (typeof event.target.value !== "string" ||
      event.target.value.trim().length < 1)
  )
    return;
  this.setState({
    [event.target.name]: event.target.value,
  });
};

export const handleSaveInvoiceDone = function () {
  this.setState({
    cartList: [],
    cartTotalAmount: 0,
    payWithCashChange: 0,
    customerValue: [],
    recordingCashSale: false,
    recordingCardSale: false,
    recordingGiftCardSale: false,
    addLoyaltyProgramToSale: false,
    customerName: "",
  });
};

/**
 *
 * @description GraphQL Helper Function to get Merchant Active Loyalty
 * @returns Loyalty Data
 */
export const gqlGetActiveLoyalty = async function () {
  try {
    const { client } = this.props;

    const result = await client.query({
      query: LIST_LOYALTIES,
    });

    const multiLevelLoyalties = result.data ? result.data.listLoyalties : [];

    // NB: Merchant should only have one active loyalty program running at a time.
    const activeLoyalty =
      multiLevelLoyalties.length &&
      multiLevelLoyalties.find(
        (loyalty) => loyalty.status.toLowerCase() === "active"
      );

    return activeLoyalty;
  } catch (error) {}
};
