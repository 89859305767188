import { useMutation } from "@apollo/client";
import React from "react";
import { Modal, Button, Form, Select, Input, message } from "antd";
import { ENROLL_CUSTOMER_WITH_AMOUNT_SPENT } from "../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";

const CreditLoyaltyModal = ({
  visible,
  onCancel,
  customer,
  customers,
  activeLoyalty,
}) => {
  const [form] = Form.useForm();

  const [enrollCustomerWithAmount, { loading: creditCustomerLoading }] =
    useMutation(ENROLL_CUSTOMER_WITH_AMOUNT_SPENT, {
      onCompleted: ({ enrollCustomerWithAmountSpent }) => {
        if (!!enrollCustomerWithAmountSpent) {
          form.resetFields();
          onCancel();
          Modal.success({
            title: "Loyalty points credited successfully to customer",
          });
        }
      },
      onError: (error) => {
        message.error(error.message, 5);
      },
    });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const cstmr =
        customer ||
        customers.find((cstmer) => cstmer.id === values.customer_id);
      enrollCustomerWithAmount({
        variables: {
          loyalty_id: activeLoyalty.id,
          customer: {
            customer_id: cstmr.user_id,
            first_name: cstmr.first_name,
            last_name: cstmr.last_name,
            email: cstmr.email,
          },
          amount_spent: Number(values.amount),
        },
      });
    });
  };

  const customerName = `${customer?.first_name ? customer.first_name : ""} ${
    customer?.last_name ? customer.last_name : ""
  } ${customer?.phone_number ? ":" + customer.phone_number : ""}
`;

  return (
    <Modal
      open={visible}
      title="Credit Loyalty"
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={creditCustomerLoading}
        >
          Credit
        </Button>,
      ]}
    >
      <h3 style={{ padding: 0, marginBottom: 20 }}>
        Active Loyalty: <b>{activeLoyalty && activeLoyalty.name}</b>
      </h3>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Select Customer"
          name="customer_id"
          rules={[{ required: true, message: "Please select customer" }]}
          initialValue={customer ? customerName : null}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Customer"
            optionFilterProp="children"
            size="large"
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            disabled={!!customer}
          >
            {customers &&
              customers.map((customer) => (
                <Select.Option
                  key={customer.user_id}
                  value={customer.id}
                  id={customer.loyalty_id}
                >
                  {`${customer?.first_name ? customer?.first_name : "N/A"} ${
                    customer?.last_name ? customer?.last_name : ""
                  } ${
                    customer?.phone_number ? ":" + customer?.phone_number : ""
                  }
`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Amount Spent"
          name="amount"
          rules={[{ required: true, message: "Please enter amount spent" }]}
        >
          <Input size="large" placeholder="Enter amount spent" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreditLoyaltyModal;
