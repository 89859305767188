import { languages } from "./languages";
import { stringTranslations } from "./allStringTranslations";
import store from "./redux/store";
const strings = {
  cashier: "cashier",
  manager: "manager",
  owner: "owner",
  online: "online",
  offline: "offline",
  changePassword: "Change Password",
  currentPasswordMessage: "Please enter your current password",
  passwordMessage: "Please enter your password",
  currentPassword: "Current Password",
  password: "password",
  loadingProducts: "please wait, yours products are being loaded...",
  youAreYetToHaveAShopLink: "You are yet to have a loystar shop link, click the icon to set your shop link",
  reduceProdInv:"Reduce product inventory",
  confirmPasswordMessage: "Please confirm your password!",
  confirmPassword: "Confirm Password",
  sendViaEmail: "Send Via Email",
  sendViaWhatsapp: "Send Via WhatsApp",
  downloadPdf: "Download PDF",
  paid: "paid",
  unpaid: "unpaid",
  partial: "partial",
  closeInvoice: "Do you want to close the Invoice?",
  closeInvoiceConfirmation: "Invoice may not be saved. Do you want to close?",
  yes: "yes",
  no: "no",
  invoice: "Invoice",
  wasDeducted: "was deducted",
  for: "for",
  item: "Item",
  addProduct: "Add Product",
  paymentReference: "Payment Reference",
  amountPaid: "Amount Paid",
  discountAmount: "Discount Amount",
  amountDue: "Amount Due",
  amount: "Amount",
  dueDate: "Due Date",
  backDate: "Invoice Date",
  paymentType: "Payment Type",
  card: "Card",
  cash: "Cash",
  bankTransfer: "Offline Bank Transfer",
  paymentMessage: "Payment Note",
  description: "Description",
  sendReceipt: "Send Receipt",
  delete: "Delete",
  save: "Save",
  resend: "Re-Send",
  saveAndSend: "Save & Send",
  invoiceSaved: "Invoice saved!",
  selectPaymentMethod: "Please select a Payment Method!",
  selectCustomer: "Please select a Customer!",
  cartEmptyError:
    "Cart List can not be empty, close the invoice and add Item to cart!",
  subscriptionExpired:
    "Your subscription has expired and your account is now limited. Click on the Button below to renew your account",
  renew: "Renew",
  holdOn: "Hold On",
  customerBank: "Customer Bank",
  cancel: "Cancel",
  selectACustomer: "Select Customer",
  invoiceSuccessfulPdfError:
    "Invoice created successfully but PDF Generation is taking longer than usual. Please check back shortly.",
  downloadingInvoice: "Downloading Invoice",
  downloadingReceipt: "Downloading Receipt",
  whatsappReceiptError:
    "An error occured sending the receipt via WhatsApp, Please try again.",
  receiptToWhatsapp: "Receipt forwarded to WhatsApp",
  thankYouForPatronage: "Thank you for your patronage",
  hereIsYourReceipt: "Here is your payment receipt",
  errorSendingEmailReceipt:
    "An error occured sending the receipt via e-mail, please try again or contact support",
  receiptSentToEmail: "Receipt has been sent to customer's email",
  invoiceSentToEmail: "Invoice has been sent to customer's email",
  invoiceSuccessWhatsappError:
    "Invoice created successfully but an error occured sending to WhatsApp. Try again on the invoice list",
  invoiceSuccessfulEmailError:
    "Invoice created successfully but an error occured while sending as e-mail. Please try again from the invoice list",
  invoiceSentToWhatsapp: "Invoice forwarded to WhatsApp",
  hello: "Hello",
  pleaseDownloadInvoice: "Please download invoice",
  pleaseDownloadReceipt: "Please download receipt",
  from: "from",
  email: "Email",
  upgrade: "Upgrade",
  youAreOnFreePlan: "You are on a Free Plan.",
  clickOn: "Click on",
  yourPlanInFewSteps: " your subscription in a few quick steps.",
  to: "to",
  yourSubscriptionHasExpired:
    "Your subscription has expired and your account is now limited.",
  days: "days",
  yourSubscriptionExpiresIn: "Your Loystar subscription expires in",
  createAcount: "Create an Account",
  signIn: "Sign In",
  signInKDS: "Sign In To Loystar KDS",
  continue: "Continue",
  enterOtp: "Enter OTP PIN",
  enterValidOtp: "Please enter a valid PIN",
  pin: "PIN",
  tokenSentToMail: "A token has been sent to your email",
  passwordResetSuccessful: "Password reset was successful",
  somethingWentWrong: "Something went wrong!",
  resetPassword: "Reset Password",
  iHaveResetCode: "I have a password reset code",
  pleaseEnterEmail: "Please enter your email",
  aTokenWillBeSentToEmail: "A token would be sent to your email",
  enterEmail: "Enter your email",
  sendinYourToken: "Sending your token...",
  makeSureItMatchesPassword: "Make sure it matches your new password",
  pleaseChooseNewPassword: "Please choose a new password",
  chooseNewPassword: "Choose a new password",
  enterNewPassword: "Enter your new password to confirm",
  enterTokenSent: "Enter the token that was sent your mail",
  resetToken: "Reset Token",
  resettingPassword: "Resetting your Password...",
  signUp: "Sign Up",
  adminSignInWithEmail:
    " Admin signs in with email while Staff signs in with username.",
  pleaseEnterEmailOrUsername: "Please enter your email or username",
  emailOrUsername: "Email or Username",
  pleaseEnterPassword: "Please enter password",
  createAnAccount: "Create an Account",
  forgotPassword: "Forgot Password?",
  enterPhoneNumber: "Enter Phone Number",
  personalData: "Personal Data",
  validateConfirmationCOde: "Validate Confirmation Code",
  pleaseEnterFirstName: "Please enter your first name",
  pleaseEnterPhoneNumber: "Please enter your phone number",
  pleaseEnterLastName: "Please enter your last name",
  pleaseEnterBusinessName: "Please enter your business name",
  firstName: "First Name",
  lastName: "Last Name",
  businessName: "Business Name",
  previous: "Previous",
  next: "Next",
  pleaseSelectBusinessCategory: "Please select your business category",
  pleaseEnterValidEmail: "Please enter a valid email",
  pleaseEnterPostCode: "Please enter post code",
  postCode: "Post Code",
  phoneNumberInUse: "This phone number is in use already!",
  emailInUse: "This email is in use already!",
  foodAndDrinks: "Food and Drinks",
  salonAndBeauty: "Salon and Beauty",
  fashionAndAccessories: "Fashion and Accessories",
  gymAndFitness: "Gym and Fitness",
  travelAndHotel: "Travel and Hotel",
  homeAndGifts: "Home and Gifts",
  washingAndCleaning: "Washing and Cleaning",
  gadgetsAndElectronics: "Gadgets and Electronics",
  groceries: "Groceries",
  photography: "Photography",
  bookstore: "Bookstore",
  healthcare: "Healthcare",
  others: "Others",
  submit: "Submit",
  accountCreatedSuccessful: "Your account was created successfully.",
  pleaseEnterAddress: "Please Enter Your Address",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  choosePassword: "Choose Password",
  passwordMustBe6Characters: "Password must be at least 6 characters.",
  howDidYouHearLoystar: "How did you hear about Loystar?",
  referralCode: "referral Code",
  byClickingTheButton: " By clicking the button below, you agree to the",
  termsAndSevice: "Terms & Service, Privacy & Data Protection Policy",
  wereCreatingAccount: "We're creating your account",
  proceed: "Proceed",
  verificationCodeMustBe6: "Verification Code must be 6 digits",
  pleaseEnter6DigitCode: "Please Enter 6 digit code",
  enterVerificationCode: "Enter Verification Code",
  resendToken: "Resend Token",
  verify: "Verify",
  transactions: "Transactions",
  todaySales: "Today's Sales",
  salesHistory: "Sales History",
  redemption: "Redemption History",
  customerRedepmtion: "Redemption History",
  supplyHistory: "Supply History",
  purchaseHistory: "Purchase History",
  variantImage: "Variant Image",
  selectVariantImage: "Select Variant Image",
  new: "New",
  invoices: "Invoices",
  disbursements: "Disbursements",
  offlineSales: "Offline Sales",
  products: "Products",
  customers: "Customers",
  multiLevelLoyalty: "Multi-Level Loyalty",
  loyaltyPrograms: "Loyalty Programs",
  members: "Members",
  appStore: "App Store",
  addOns: "Add-ons",
  orderMenu: "Order Menu",
  settings: "Settings",
  referralProgram: "Referral Programs",
  createReferralProgram: "Create Referral Programs",
  staffAndBranches: "Staffs and Branches",
  myAccount: "My Account",
  switchToSellMode: "Switch to Sell Mode",
  signOut: "Sign Out",
  getFreeSubscription: "Get Free Subscription",
  onlyNumbersAllowed: "Only numbers are allowed",
  yourAccountMustBe10Digits: "your account number must be a 10 digit number",
  yourBvnMustBe11: "your BVN must be a 11 digit number",
  pleaseSelectBank: "Please select your bank",
  selectYourBank: "Select your Bank",
  enterBankAccountNumber: "Enter Bank Account Number",
  enterBvnNumber: "Enter your BVN",
  connectBankAccount: "Connect Bank Account",
  passwordResetSuccessfulAndSignOut:
    "Your Password has been reset sucessfully. Click on the Sign Out button below to signin again",
  passwordResetSuccessfulForStaff:
    "This staff Password has been reset sucessfully.",
  areYouSureYouWantArchiveBranch: "Are you sure you want to archive this branch?",
  thisBranchHasBeenArchived: "This branch has been archived",
  plsNoteStaffTransfer: "Please note that staff(s) under this branch will be reassigned to home branch",
  enterCurrentPassword: "Enter current password",
  pleaseEnterCurrentPassword: "Please enter current password",
  phoneNumber: "Phone Number",
  bvn: "BVN",
  sex: "Sex",
  dateOfBirth: "Date of Birth",
  state: "State",
  country: "Country",
  loyaltyId: "Loyalty ID",
  createdAt: "Created At",
  noOfTransactionsMade: "No. of transactions made",
  yourDownloadWillStart: "Your download will start in a moment",
  exportCustomers: "Export Customers",
  youhaveSuccessfullyToppedUp:
    "Your have successfully topped up your SMS Units.",
  youNowHave: "You now have",
  smsUnits: "SMS Units",
  enterNumberOfSmsUnits: "Enter Number of SMS Units You Intend to Purchase",
  enterNumberOfWhatsAppUnits: "Enter an amount to purchase:",
  pleaseEnterANumericValue: "Please enter a numeric value",
  pay: "Pay",
  accountEditedSuccessfully: "Your account was edited successfully.",
  youAeCurrentlyOn: "You're currently on",
  plan: "Plan",
  userData: "User Data",
  payFlutterWave: "Pay With Flutterwave",
  payStripe: "Pay With Stripe",
  businessData: "BUSINESS DATA",
  businessCategory: "Business Catergory",
  pleaseSelectCurrency: "Please select your currency",
  selectYourCurrency: "Select your currency",
  purchaseMoreSmsUnits: "Purchase more SMS Units using the form below",
  purchaseMoreWhatsAppUnit:
    "Purchase more Whatsapp currency value using the form below",
  youHave: "You have",
  atLeast4SmsUnitsRrequired:
    "At least 4 sms units is required for verification, please top up!",
  pleaseVerifyYourAccountToComplyWithKyc:
    "Please verify your bank account to comply with the 'Know Your Customer' (KYC) requirements. This will allow you collect payments via USSD or instant transfer, receive orders from customers and process transaction settlement. Applicable to Nigerian merchants only. Please press the button below to begin.",
  reConnectBankAccount: "Re-connect Bank Account",
  accountName: "Account Name",
  accountNumber: "Account Number",
  bankName: "Bank Name",
  verified: "Verified",
  accountDetails: "Account Details",
  kycBankAccount: "KYC & Bank Account",
  createTier: "Create Tier",
  fileUploadSuccessful: "file uploaded successfully",
  fileUploadFailed: "file upload failed",
  createLoyaltyProgram: "Create a Loyalty Program",
  createLoyalty: "Create Loyalty",
  name: "Name",
  eventCode: "Event Code",
  loyaltyArtwork: "Loyalty Artwork",
  clickToUpload: "Click to upload",
  amountToPointsRatio: "Amount to Points Ratio",
  points: "Points",
  notes: "Notes",
  redemptionDate: "Redemption Date",
  searchCategory: "Search Category",
  redemptionValue: "Redemption Value",
  recommendedAmountToPointRatio:
    "For the Best Loyalty Experience, points to Currency ratio should be Whole-Round Numbers.",
  recommend1: "Recommended : ",
  recommend2: "1 to 1point. i.e for every 1 ",
  recommend3: " spent, your customers get 1 point",
  pleaseTypeIn: "Please type in ",
  toDeleteLoyalty: "to delete this loyalty",
  deleteLoyalty: "Delete Loyalty",
  toConfirm: "to confirm",
  edit: "Edit",
  twoWeeks: "2 Weeks",
  oneMonth: "1 Month",
  threeMonth: "3 Months",
  sixMonth: "6 Months",
  pointsAwardedSuccessfully: "Points Awarded Successfully.",
  enterPointValueToAward: "Enter Point Value You Would like to Award Customers",
  award: "Award",
  awardPointValuesToCustomer: "Award Point Value to Customer",
  enrollMembersToLoyalty: "Enroll Members onto Loyalty",
  awardPoints: "Award Points",
  enroll: "Enroll",
  home: "Home",
  loyalty: "Loyalty",
  enrollCustomers: "Enroll Customers",
  selected: "Selected",
  customer: "Customer Name",
  cust: "Search Customer",
  filter: "Filter",
  customerNo: "Customer phone No",
  loyaltyActivationSuccessful: "Loyalty Activation is successful.",
  loyaltyDeactivationSuccessful: "Loyalty Deactivation is successful.",
  viewTier: "View Tier",
  deactivate: "De-Activate",
  activate: "Activate",
  actions: "Actions",
  nameOfLoyalty: "Name of Loyalty",
  loyaltyStatus: "Loyalty StatusLoyalty Status",
  numberOfTiers: "Number of Tiers",
  createdOn: "Created On",
  createATier: "Create a Tier",
  stopCreatingTierConfirmation: "Do you want to stop creating a tier?",
  stopEditingTierConfirmation: "Do you want to stop editing this tier?",
  nameOfTier: "Name of Tier",
  minimumSpendingTarget: "Minimum Spending Target",
  maximumSpendingTarget: "Maximum Spending Target",
  minimumSpendingTargetRequired: "minimum spending target is requiured",
  maximumSpendingTargetRequired: "maximum spending target is requiured",
  rewardSponsor: "Reward Sponsor",
  pleaseChooseARewardSponsor: "Please choose a reward sponsor",
  self: "Self",
  partner: "Partner",
  rewardPartner: "Reward Partner",
  pleaseSelectRewardPartner: "Please select your reward partner",
  rewards: "Rewards",
  rewardType: "Reward Type",
  orgID: "Organization ID",
  random: "Select winner randomly",
  active: "Active",
  merchant_id: "Merchant ID",
  noOfWinners: "How many participant do you want to reward",
  custTempName: "Custom Template Name",
  optinMssg: "Opt in message",
  sharemsg: "Share Message",
  pleaseSelectAReward: "Please select a reward",
  instructionsOnRedeemingReward:
    "Instructions on How Customer Should Redeem Reward",
  pleaseFillInThisField: "Please Fill in this Field!",
  toDeleteThisTier: " to delete this tier",
  deleteTier: "Delete Tier",
  viewMembers: "View Members",
  membersEnrolled: "Members Enrolled",
  instruction: "Instruction",
  membersIn: "Members in",
  availableTiersInLoyalty: "Available Tier(s) in Loyalty Program",
  tiers: "Tiers",
  totalTier: "TOTAL TIER",
  availableLoyaltyProgramme: "Available Loyalty Programme",
  totalLoyalties: "TOTAL LOYALTIES",
  memberDetails: "Member Details",
  nameOfCustomer: "Name of Customer",
  address: "Address",
  allEnrolledMembers: "All Enrolled Members",
  thisIsToWishYouHappyBirthday:
    "This is to wishing you a very happy birthday and prosperous life. Thanks for all that you are to Loystar. Happy Celebrations!",
  inputAnOfferPlease: "Input an Offer please",
  pleaseSelectTheInsertPoint:
    "Please select the insert point in the message and click again",
  birthdayOfferAndMessage: "Birthday Message and Offer",
  birthdayOffer: "Birthday Offer",
  birthdayMessage: "Birthday Message",
  noOfferFound: "No offer found",
  settingABirthdayOffer:
    "Setting a birthday offer allows customers to receive this offer via SMS on their birthday",
  createOffer: "Create Offer",
  editOffer: "Edit Offer",
  setBirthdayOfferAndMessages: "Set Birthday Offers",
  viewChnageHistory: "View Change History",
  changeHistory: "Change History",
  
  whatIsTheOffer: "What is the offer?",
  userExistError: "User with this username already exist!",
  usernameMustBeUnique: "Username must be unique",
  editMessage: "Edit Message",
  insert: "Insert",
  theNameOfCustomerInserted: "The name of the customer will be inserted here",
  theNameOfCustomersInserted: "The name of the customers will be inserted here",
  theBirtdayOfferInserted: "The birthday offer will be inserted here",
  youSuccessfullyAddedNewBranch: "You've successfully added a new branch!",
  youSuccessfullyAddedNewBranches: "You've successfully added new branches!",
  addNewBranch: "Add New Branch",
  addBranch: "Add Branch",
  netTax: "Net VAT",
  createBranch: "Create Branch",
  additionalBranchWillIncur: "Additional Branch would incur",
  perBranch: "per branch",
  ecommerceBranchCosts: "Ecommerce Branch costs",
  pleaseEnterBranchName: "Please enter the branch name",
  pleaseEnterBranchAddress1: "Please enter the branch's address line 1",
  enterBranchAddress1: "Enter the branch's address line 1",
  enterBranchAddress2: "Enter the branch's address line 1",
  pleaseEnterBranchAddress2: "Please enter the branch's address line 2",
  enterBranchName: "Enter the branch name",
  successfullyAddedStaff: "You've successfully added a new staff!",
  addNewStaff: "Add New Staff",
  addStaff: "Add Staff",
  additionalStaffWillIncur: "Additional Staff would incur",
  perStaff: "per staff.",
  pleaseEnterStaffEmail: "Please enter the staff's email",
  pleaseEnterStaffUsername: "Please enter the staff's username",
  pleaseEnterStaffPassword: "Please enter the staff's password",
  pleaseSelectStaffRole: "Please select the staff's role",
  selectStaff: "Select staff to filter",
  selectStaffRole: "Select the staff's role",
  enterStaffEmail: "Enter the staff's email",
  enterStaffUsername: "Enter the staff's username",
  enterStaffPassword: "Enter the staff's password",
  spacesNotAllowedInUsername: "spaces not allowed in username",
  sendAllItemsToBranch: "Send All Items to Branch",
  admin: "Admin",
  pleaseSelectBusinessToAttachStaff: "Please select business to attach staff",
  searchForBranchToAttachStaff: "Search for branch to attach staff",
  username: "Username",
  role: "Role",
  areYouSureToDeleteThis: "Are you sure to delete this",
  areYouSureToArchiveThis: "Are you sure to archive this",
  supplyDetails: "Supply Details",
  supplyDeleteSuccess: "Supplier has been deleted successfully",
  areYouSureDeleteSupply: "Are you sure you want to delete this supplier?",
  editSupply: "Edit Supply",
  branches: "Branches",
  upgradeYourPlan: "Upgrade your Plan.",
  add: "ADD",
  addNew: "Add New",
  customerWithEmailAlreadyExists:
    "Customer with email/phone number already exists!",
  successfullyAddedNewCustomer: "You've successfully added a new customer!",
  addCustomer: "Add Customer",
  pleaseEnterCustomerFirstName: "Please enter customer's first name",
  pleaseEnterCustomerLastName: "Please enter customer's last name",
  pleaseEnterCustomerPhoneNumber: "Please enter customer's phone number",
  pleaseEnterBVN: "Please enter customer's BVN",
  pleaseEnterCustomerEmail: "Please enter customer's email",
  pleaseEnterCustomerAddressLine: "Please enter customer's Address Line",
  pleaseEnterCustomerOtherAddress: "Please enter customer's other Address",
  pleaseSelectCustomerGender: "Please select customer's gender",
  gender: "Gender",
  male: "Male",
  female: "Female",
  bank: "Bank",
  selectBank: "Select Bank",
  stateOrRegionOrProvince: "State/Region/Province",
  customerNotes: "Customer Notes",
  sendSms: "Send SMS",
  editCustomer: "Edit Customer",
  redeemReward: "Redeem Reward",
  issueLoyaltyCard: "Issue Loyalty Card",
  deleteCustomer: "Delete Customer",
  youveSuccessfullyAssignedLoyaltyMembership:
    "You've successfully Assigned a Loyalty Membership ID",
  noMembershipIdAvailable:
    "No membership IDs available. Please contact us on hello@loystar.co",
  sendEmail: "Send Email",
  membershipPoints: "Membership Points",
  customerDetails: "Customer Details",
  close: "Close",
  loyaltyBalance: "Loyalty Balance",
  pointsBalance: "Points Balance",
  starBalance: "Star Balance",
  requiredPoints: "Required Points",
  requiredStars: "Required Stars",
  reddemCode: "Redeem Code",
  customData: "Custom Data",
  toDeleteThisCustomer: " to delete this customer",
  customerHasBeenDeletedSuccessfully: "Customer has been deleted successfully!",
  customerWithPhoneAlreadyExists: "Customer with phone number already exists",
  customerWasSuccessfullyEdited: "Customer was successfully edited",
  anErrorOccured: "An error occured",
  phoneNumberIncludeCountry: "Phone Number (Include Country Code)",
  yourFileQueuedForUpload:
    "Your file has been queued for upload. Please refresh the page after a few seconds.",
  thereWasAnErrorPerformingOperation:
    "There was an error performing the operation!",
  pleaseSelectFile: "Please Select a File!",
  oopsOnlyCsvFilesAllowed:
    "Oops! Only CSV files allowed. Please upload a .csv file.",
  fileShouldBeSmallerThan5Mb:
    "File should be smaller than 5MB. If you have a larger file, please email support@loystar.co. Thank you",
  customerFirstNameIsRequired: "customer first name is required on row",
  customerPhoneNumberIsRequired: "customer phone number is required on row",
  customerPhoneNumberAndEmailIsRequired: "customer phone number or email is required on row",
  importCustomers: "Import Customers",
  upload: "Upload",
  clickIconToDownloadCsv: "Click this icon to download the CSV file template.",
  getGoogleSheetVersion: "Get the Google sheet version",
  clickOrDragFileToUpload:
    "Click or drag simple product file to this area to upload",
  clickOrDragVariantFileToUpload:
    "Click or drag product with variant file to this area to upload",
  clickOrDragToAddMultipleCustomQty:
    "Click or drag product to add multiple custom quantity to different products",
  clickOrDragToAddSingleServices: "Click or drag service to add single service",
  clickOrDragToAddMultipleServices:
    "Click or drag service to add multiple services",
  missingOrInvalidColumnHeader:
    "Missing or invalid column header. Please follow the template format. Thank you.",
  youHaveImported: "You Have Imported",
  youSuccessfullyRedeemedReward: "You've successfully redeemed your reward!",
  redeemRewardFailed: "You're not eligibe to redeem this reward!",
  sixDigitCode: "Six digit code",
  pleaseEnterCustomerRewardCode: "Please enter customer's reward code",
  enterRewardCode: "Enter reward code.(The reward code is case sensitive)",
  selectLoyaltyProgram: "Select loyalty program",
  stamps: "Stamps",
  smsUnitsLow: "SMS Units Low",
  whatsAppbalance: "whatsapp balance",
  pleaseTopUpSmsUnits: "Please Top Up SMS Units",
  smsSuccessfullySentForDelivery: "SMS successfully sent for delivery!",
  sendSmsTo: "Send SMS to",
  allCustomers: "All Customers",
  unitsAvailable: "Units Available",
  pleaseTypeInTheMessage: "Please type in the message",
  message: "Message",
  charactersRemaining: "characters remaining",
  avoidUseOfSpecialCharacters:
    "Avoid the use of Special Characters and Emojis to conserve SMS Units.",
  note: "Note",
  errorFetchingCustomersMultilevelDetail:
    "Error Fetching Customers MultiLevel Details",
  search: "Search",
  reset: "Reset",
  importCustomer: "Import Customer",
  addNewCustomer: "Add New Customer",
  sendSmsBroadcast: "Send SMS Broadcast",
  totalCustomers: "TOTAL CUSTOMERS",
  disbursementDetails: "Disbursement Details",
  paidBy: "Paid By",
  disbursed: "Disbursed",
  bankAccountName: "Bank Account Name",
  bankAccountNumber: "Bank Account Number",
  transferInitiated: "Transfer Initiated",
  transferCompleted: "Transfer Completed",
  pleaseEnterAValid: "Please enter a valid",
  begin: "begin",
  end: "end",
  date: "date",
  paymentDate: "Payment Date",
  selectDisbursementDuration: "Select Disbursement Duration",
  totalSettled: "Total Settled",
  totalUnsettled: "Total Unsettled",
  toDeleteThisSale: "to delete this sale",
  draftSaleDeletedSuccessfully: "Draft Sale has been deleted successfully!",
  deleteSale: "Delete Sale",
  pleaseTypeInYourTotal: "Please type in your total to confirm deletion",
  billDetails: "Bill Details",
  printBill: "Print Bill",
  servedBy: "Served By",
  total: "Total",
  transDate: "Transaction Date",
  createdDate: "Created Date",
  createdTime: "Created Time",
  status: "Status",
  loginSuccessful: "Login Successful",
  pleaseWaitWhileWeConnectAccount: "Please wait while we connect your account",
  connectionFailedTryAgain: "Connection failed. Please try again.",
  connectionSuccessful: "Connection Successful",
  viewDetails: "View Details",
  enable: "Enable",
  free: "Free",
  cost: "Cost",
  visitWebsite: "Visit Website",
  pleaseUpgradeYourPlanToPro:
    "Please Upgrade your plan to Pro Plus to enable this app",
  connectYourBankAccount:
    "Connect your Bank Account to be able to receive payments.",
  disable: "Disable",
  enableApp: "Enable App",
  addNewProductToInvoice: "Add New Product to Invoice",
  addNewProductToDraft: "Add New Product to Draft",
  toDeleteThisInvoice: "to delete this Invoice",
  invoiceDeletedSuccessfully: "Invoice has been deleted successfully!",
  deleteInvoice: "Delete Invoice",
  youveSuccessfullyAddedInvoicePaymentMessage:
    "You've successfully added an Invoice payment Note!",
  pleaseEnterPaymentInstructions: "Please enter payment instructions",
  invoiceId: "Invoice ID",
  paidDate: "Paid Date",
  reference: "Reference",
  productAdded: "Product added",
  productOutOfStock: "Product out of stock. Please re-stock.",
  oneBundleProductOutOfStock: "is out of stock. Please re-stock.",
  moreBundleProductOutOfStock: "More than one product in bundle is out of stock. Please re-stock",
  totalInvoices: "TOTAL INVOICES",
  totalPaidInvoices: "TOTAL PAID INVOICES",
  totalUnpaidInvoices: "TOTAL UNPAID INVOICES",
  loyaltyProgramDeleted: "Loyalty Program Deleted",
  thisLoyaltyProgramDeletedSuccessfully:
    "This loyalty program has been deleted successfully",
  thisLoyaltyProgramEditedSuccessfully:
    "This loyalty program has been edited successfully",
  loyaltyProgramAddedSuccessfully:
    "Loyalty program has been added successfully",
  loyaltyProgramEdited: "Loyalty Program Edited",
  loyaltyProgramAdded: "Loyalty Program Added",
  loyaltyDetails: "Loyalty Details",
  doYouWantToCloseDialog: "Do you want to close this dialog?",
  pleaseEnterLoyaltyName: "Please enter the name of your loyalty",
  programType: "Program Type",
  pleaseSelectProgramType: "Please select a program type",
  simplePoints: "Simple Points",
  stampsProgram: "Stamps Program",
  threshold: "Threshold",
  pleaseEnterLoyaltyThreshold: "Please enter the loyalty threshold",
  reward: "Reward",
  pleaseEnterLoyaltyReward: "Please enter the reward of the loyalty",
  totalUserPoints: "Total User Points",
  totalUserStamps: "Total User Stamps",
  loyaltyImages: "Loyalty Images",
  spendingTarget: "Spending target",
  spendingTargetHint1:
    "Spending target is how much a customer must spend to earn the reward. 1 currency value = 1 point.",
  spendingTargetHint2:
    "Stamps target is how many stamps a customer must collect to earn the reward. eg. 5",
  addNewLoyaltyProgram: "Add New Loyalty Program",
  addLoyaltyProgram: "Add Loyalty Program",
  loyaltyProgramType: "Loyalty Program Type",
  paygWeekly: "PayG weekly",
  paygMonthly: "PayG Monthly",
  paygWeeklyNote: "of your total sales weekly",
  paygMonthlyNote: "of your total sales monthly",
  pleaseSelectLoyaltyProgramType: "Please select loyalty program type",
  nameOfProgram: "Name of program",
  pleaseEnterProgramName: "Please enter program name",
  creditOneStampPerVisit: "Credit 1 Stamp Per Visit",
  whatIsTheReward: "What is the reward?",
  egNextPurchaseIsFree: "E.g. Next purchase is free",
  customerName: "Customer Name",
  guestCustomer: "Guest Customer",
  orderConfirmedSuccessfully: "Order confirmed successfully",
  orderCancelledSuccessfully: "Order cancelled successfully",
  orderProcessedSuccessfully: "Order processed successfully",
  paymentConfirmSuccessfully: "Payment confirmed successfully",
  confirmOrder: "Ready order",
  confirmPayment: "Confirm Payment",
  cancelOrder: "Cancel order",
  printOrder: "Print order",
  processOrder: "Process order",
  processedBy: "Processed by",
  allOrders: "All Orders",
  onlineOrders: "Online Orders",
  onPoint: "In-store Orders",
  totalpoint: "TOTAL ORDERS",
  totalonline: "TOTAL ONLINE ORDERS",
  totalOrders: "TOTAL ORDERS",
  orderId: "Order No",
  doYouWantToAcceptOrder: "Do you want to accept this order?",
  doYouWantToCancelOrder: "Do you want to cancel this order?",
  doYouWantToProcessOrder: "Do you want to process this order?",
  doYouWantToPayOrder: "Do you want to pay for this order?",
  doYouWantToConfirmPayment: "Do you want to confirmorder payment?",
  payOrder: "Pay for Order",
  orderDetails: "Order Details",
  orderCreatedAt: "Order created at",
  supplier: "Supplier",
  productName: "Product Name",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  receivedBy: "Received By",
  changeBy: "Changed By",
  dateTime: "Date/Time",
  event: "Event",
  oldValue: "Old Value",
  newValue: "New Value",
  reportFrom: "Report from",
  invoiceFrom: "Invoice from",
  viewBranchSales: "Select branch sales",
  totalSupplies: "Total Supplies",
  allRightsReserved: "All Rights Reserved",
  toDeleteThisTransaction: "to delete this Transaction",
  transactionDeletedSuccessfully:
    "Transaction has been deleted successfully. Stock has been returned to inventory.",
  deleteTransaction: "Delete Transaction",
  transactionDetails: "Transaction Details",
  printReceipt: "Print Receipt",
  channel: "Channel",
  discount: "Discount",
  profit: "Profit",
  discountedSales: "Discounted Sales",
  errorFetchingRecord: "Error Fetching Record",
  exportTransactions: "Export Transactions",
  exportSupplies: "Export Supplies",
  errorFetchingSalesRecord: "Error fetching Sales record for Export.",
  totalSellingPrice: "Total Selling Price",
  totalCostPrice: "Total Cost Price",
  inventoryValue: "Inventory Value",
  appliedDiscount: "Applied Discount",
  noOfItems: "No. of Items",
  sales: "Sales",
  export: "Export",
  totalProfit: "Total Profit",
  totalBalanceInPeriod: "Total Balance in Period",
  allTimeSales: "All Time Sales",
  records: "Records",
  todaysSales: "Today's Sales",
  transaction: "transaction",
  youHaveExceededTotalNumberOfProducts:
    "You have exceeded the total number of products allowed on your plan. Upgrade your plan to enjoy a higher a limit.",
  youNeedToEnableNitroPlan: "You have exceeded the total number of products allowed on your plan. Upgrade to Nitro plan increase the limit",
  youNeedToHaveLoyaltyProgram:
    "You Need to have a Loyalty Program to use this feature!",
  price: "Price",
  category: "Category",
  stockTracking: "Stock Tracking",
  stockCount: "Stock Count",
  taxed: "Taxed",
  addMArgin: "Add Margin",
  markupPercentage: "Markup Percentage",
  markupType: "Markup Type",
  markupAmount: "Markup Amount",
  showMarkupPercent: "Add markup percent",
  hideMarkupPercent: "Remove markup percent",
  priceMargin: "Price Margin",
  costPriceShouldBeSetToUseThisFeature: "Cost price should be set to add margin",
  enterYourPriceMargin: "Input your margin in percentage to continue",
  satchet:"SACHET",
  tablet: "TABLET",
  originalPrice: "Original Price",
  costPrice: "Cost Price",
  unit: "Unit",
  publish: "Available for Online Orders?",
  productImage: "Product Image",
  extraImage: "Extra Product Images",
  taxRate: "Tax Rate",
  taxType: "Tax Type",
  trackInventory: "Track Inventory",
  variants: "Variants",
  hasVariants: "Has Variants",
  importProduct: "Import Product",
  exportProducts: "Export Products",
  sendReportToMail: "Send Report To Mail",
  addNewProduct: "Add New Product",
  viewCategory: "View Category",
  viewSuppliers: "View Suppliers",
  receiveInventory: "Receive Inventory",
  addCustomQty: "Add Custom Quantity",
  printAllProductsTag: "Print All Products Tag",
  deleteAll: "Delete All",
  totalProducts: "TOTAL PRODUCTS",
  youveSuccessfullyAddedANewCategory:
    "You've successfully added a new category",
  addNewCategory: "Add New Category",
  addCategory: "Add Category",
  categoryName: "Category Name",
  pleaseEnterCategoryName: "Please enter category name",
  stampsTarget: "Stamps Target",
  sendInventory: "Send Inventory",
  productDetails: "Product Details",
  youveSuccessfullyEditedThisCategory:
    "You've successfully edited this category",
  update: "Update",
  categoryList: "Category List",
  categories: "Categories",
  enterQuantityToUpdate: "Enter a quantity to update",
  inventorySentSuccessfully: "The inventory was sent successfully!",
  updateInventory: "Update Inventory",
  currentQuantity: "Current Quantity",
  pleaseEnterQuantityToAdd: "Please enter the quantity you want to add",
  pleaseSelectABranch: "Please select a Branch",
  pleaseSelectAVariant: "Please select a Variant",
  searchForBranch: "Search for branch",
  searchForVariant: "Select variant",
  youCantSendMoreThanStock: "You can't send more than you have in stock",
  send: "Send",
  pleaseEnterQuantityToSend: "Please enter the quantity you want to send",
  productNameIsRequiredOnRow: "product name is required on row",
  productCategoryIsRequiredOnRow: "product category is required on row",
  productPriceIsRequiredOnRow: "product price is required on row",
  productUnitIsRequiredOnRow: "product unit is required on row",
  productQuantityIsRequiredOnRow: "product quantity is required on row",
  productVariantsRequireTracking: "product variants requires tracking!",
  pleaseAddVariantClickButton:
    "Please add a variant by clicking the add button!",
  totalVariantsMoreThanSelectedQuantity:
    "Total Variants are more than selected product quantity, Pls reduce variant quantity",
  productWithSameName:
    "Product with this name already exist, please change product name!",
  productEditedSuccessfully: "Product has been edited successfully!",
  productsEditedSuccessfully: "Products have been edited successfully!",
  fileTooLargeLessThan4Mb:
    "File size is too large! File size should be less than 4MB.",
  fileMostNotBeMoreThan4: "Can only add maximum of four images.",
  fileMostNotBeMoreThan3: "Can only add maximum of three extra images.",
  suchVariantAlreadyExist: "Such variant already exist",
  suchUnitAlreadyExist: "Such custom unit already exist",
  addVariants: "Add Variants",
  addVariant: "Add Variant",
  addCustom: "Add Custom Quantity",
  customQty: "Custom Quantities",
  editProduct: "Edit Product",
  pleaseEnterProductName: "Please Enter Product Name",
  pleaseEnterProductPrice: "Please Enter Product Price",
  pleaseEnterProductOriginalPrice: "Please Enter Product Original Price",
  productDescription: "Product Description",
  selectProductCategory: "Select Product Category",
  pleaseSelectProductCategory: "Please select product category",
  productCostPrice: "Product Cost Price",
  productSellingPrice: "Product Selling Price",
  productOriginalPrice: "Product Original Price",
  maxFileSizeAllowedIs4Mb: "Max. File Size allowed is 4mb",
  productsWithPicturesArePublished:
    "Products with pictures are published on Discover to receive orders",
  shouldThisProductBeTracked: "Should this product be tracked?",
  pleaseSelectStockUnit: "Please select stock unit",
  stockUnit: "Stock Unit",
  bag: "BAG",
  bottle: "BOTTLE",
  bunch: "BUNCH",
  can: "CAN",
  carton: "CARTON",
  crate: "CRATE",
  cup: "CUP",
  dozen: "DOZEN",
  gigabytes: "GIGABYTES",
  gram: "GRAM",
  kilogram: "KILOGRAM",
  litre: "LITRE",
  pack: "PACK",
  pair: "PAIR",
  pieces: "PIECES",
  plate: "PLATE",
  tonne: "TONNE (MT)",
  uNIT: "UNIT",
  yard: "YARD",
  pleaseEnterProductQuantity: "Please enter product quantity",
  productQuantity: "Product Quantity",
  isThisProductTaxed: "Is this product taxed?",
  selectTaxType: "Select Tax Type",
  pleaseSelectTaxType: "Please select Tax Type",
  progressive: "Progressive",
  proportional: "Proportional",
  pleaseEnterProductTaxRate: "Please enter product tax rate",
  doesProductHaveVariants: "Does this product have variants?",
  doesProductHaveCustom: "Does this product have custom quantities?",
  type: "Type",
  value: "Value",
  customName: "Name",
  customPrice: "Price",
  pleaseEnterVariantType: "Please enter variant's type",
  pleaseEnterCustomName: "Please enter custom's Unit",
  pleaseEnterCustomPrice: "Please enter custom's price",
  pleaseEnterVariantValue: "Please enter variant's value",
  pleaseEnterVariantPrice: "Please enter variant's price",
  pleaseEnterVariantQuantity: "Please enter variant's quantity",
  productDeletedSuccessfully: "Product has been deleted successfully!",
  categoryDeletedSuccessfully: "Category has been deleted successfully!",
  toDeleteThisProduct: "to delete this Product",
  invalidProductQuantity: "Invalid Product Quantity",
  quantityAddedIsOverStock:
    "The quantity you're adding is over what you have in stock.",
  itemInventoryNotTracked: "Item inventory is not tracked",
  addBulkQuantity: "Add Bulk Quantity",
  enterBulkQuantity: "Enter Bulk Quantity",
  pleaseEnterBulkQuantity: "Please enter Bulk Quantity",
  pleaseEnterServicePrice: "Please enter Service Price",
  youveSuccessfullyAddedANewProduct: "You've successfully added a new product!",
  pleaseEnterProductSellingPrice: "Please enter product selling price",
  doYouWantToTrackProductStock: "Do you want to track product stock?",
  sellingPrice: "Selling Price",
  setProductExpiryReminder: "Set Product Expiry Reminder",
  productExpiryDate: "Product Expiry date",
  startRemindingFrom: "Start Reminding From",
  productSuppliesAddedSuccessfully:
    "You have added product supplies successfully.",
  addProductSupplies: "Add Product Supplies",
  pleaseSelectSupplier: "Please Select Supplier",
  nameOfProduct: "Name Of Product",
  pleaseSelectProduct: "Please Select Product",
  productVariant: "Product Variant",
  pleaseSelectAVariant: "Please Select A Variant",
  pleaseSelectACustom: "Please Select A Custom Quantity",
  pleaseEnterUnitPrice: "Please Enter Unit Price",
  businessBranch: "Business Branch",
  pleaseSelectBranch: "Please select branch",
  youveSuccessfullyAddedANewSupplier:
    "You've successfully added a new supplier",
  youveSuccessfullyEditedThisSupplier:
    "You've successfully edited this supplier",
  addSupplier: "Add Supplier",
  pleaseEnterSupplierEmail: "Please enter supplier's email",
  pleaseAddADescription: "Please add a description",
  anErrorOccuredProductsDeleted:
    "An error occurred while performing the operation. Please take note, few products might have gotten deleted in the process",
  anErrorOccuredCustomersDeleted:
    "An error occurred while performing the operation. Please take note, few customer's details might have gotten deleted in the process",
  bulkDelete: "Bulk Delete",
  youAreAboutToDelete: "You are about to delete",
  product: "Product",
  isDueToRedeem: "is due to redeem",
  aReward: "a reward",
  pleaseSelectCustomerToReeemReward:
    "Please select a customer to redeem reward.",
  youHaveNoLoyaltyProgramRunning: "You have no active loyalty program running",
  customerHhasNoPointsInLoyaltyProgram:
    "Customer has no points in this loyalty program",
  proceedWithPayment: "Proceed with Payment?",
  youAreAboutToPayForTransactionUsingPoints:
    "You are about to pay for the transaction using points.",
  customerHas: "Customer Has",
  worth: "worth",
  andIsNotSufficientToPayForTransaction:
    "and is not sufficient to pay for this transaction. Would they want to add the balance using another payment method?",
  addCustomerLoyalty: "Add Customer Loyalty",
  pleaseAddCustomerFirst: "Please add or select a customer first.",
  pleaseWaitWhileWeGetReady: "Please wait, while we get ready",
  lowStock: "Low Stock",
  pleaseEnterAmountTendered: "Please enter the amount tendered",
  areYouSureToBookSaleOffline:
    "Are you sure you want to book this sale offline?",
  saleWouldBeBookedAutomatically:
    "Sale would be booked automatically when when you turn on your internet",
  offlineSalesBookingCancelled: "Offline sales booking canceled",
  covid19Message:
    "COVID19: Wash your hands with soap or Sanitize to stop the spread. Stay Safe Always",
  saleSuccessfullyRecorded: "Sale Successfully Recorded!",
  youCanNowAddUpToFourImages: "You can now add up to four images",
  youCanNowAddUpToThreeExtraImages: "You can now add up to three extra images",
  sendReceiptToEmail: "Send receipt to Email",
  sendThankYouSms: "Send thank you SMS",
  sendShippingDetails: "Send Shipping details",
  addLoyalty: "Add Loyalty",
  searchCustomerNameOrNumber: "Search customer name or number",
  clickTheSearchCustomerBox: "Click the Search Customer Box and Scan Card",
  enterProductPrice: "Enter Product Price",
  enterPriceFor: "Enter Price for",
  searchForProduct: "Search for product",
  all: "All",
  backToDashboard: "Back to Dashboard",
  viewDraftSales: "View Draft Sales",
  variantSelected: "variant selected",
  variant: "variant",
  thePreviousVariantSelectionNotAvailable:
    "The previous variant selection is not available for the new variant selected based on price, please change your selection.",
  pleaseSupplyPositiveQuantityNumber: "Please supply a postive quantity number",
  lowStockFor: "Low stock for",
  clearVariants: "Clear variants",
  clearCustQty: "Clear selection",
  pleaseEnterQuantity: "Please enter Quantity",
  picture: "Picture",
  redemptionToken: "Redemption Token",
  token: "Token",
  totalSpent: "Total Spent",
  confirmPayment: "Confirm Payment",
  hasPaymentBeenMade: "Has Payment been processed succesfully?",
  enterTransactionReference: "Enter the transaction reference you paid with",
  pleaseSelectACustomer: "Please select a customer!",
  areYouSureToApplyDiscount: "Are you sure you want to apply discount?",
  addYourBankAccountToEnableUssd:
    "Add your bank account to enable Instant USSD transfer by uPay",
  totalAmountToPay: "Total Amount to Pay",
  doYouWantToCancelTransaction: "Do you want to cancel this transaction?",
  savePrintBill: "Save/Print Bill",
  enterAmountCollectedForCustomer: "Enter amount collected for customer",
  recordSale: "Record Sale",
  checkOutWith: "Check out with",
  instantTransfer: "Instant Transfer ",
  sendKitchenScreen: "Kitchen screen",
  paywithCredPal: "",
  dialTheUSSDCode: "Dial the USSD code",
  pleaseSelectABank: "Please select a bank",
  payWithUSSD: "Pay With USSD",
  sendBillTo: " - Send Bill to ",
  waitingForUSSDTransfer: "Waiting for USSD Transfer",
  percent: "Percent",
  applyDiscount: "Apply Discount",
  thisBillHasBeenSaved: "This Bill has been saved",
  saveDraft: "Save Draft",
  pleaseTypeANameToIdentifyCustomer:
    "Please type in a name to identify the customer",
  paymentDetails: "Payment Details",
  basePrice: "Base Price",
  staff: "Staff",
  subTotal: "SubTotal",
  durationMonths: "Duration (months)",
  selectADuration: "Select a Duration",
  oneMonth: "1 Month",
  twoMonths: "2 Months",
  threeMonths: "3 Months",
  sixMonths: "6 Months",
  twelveMonths: "12 Months",
  eighteenMonths: "18 Months",
  twentyFourMonths: "24 Months",
  twoMonthsFree: "(2 Months Free)",
  threeMonthsFree: "(3 Months Free)",
  fiveMonthsFree: "(5 Months Free)",
  yourAccountHasBeen: "Your Account has been",
  yourAccountSubscribed: "You have subscribed to",
  renewed: "renewed",
  upgraded: "upgraded",
  saved: "Saved",
  successfully: "successfully",
  yourSubscription: "Your Subscription",
  youAreCurrentlyEnrolledOnA: "You are currently enrolled on a",
  pleaseChooseAPaymentOption: "Please Choose a Payment Option",
  pleaseChooseAnAction: "Please Select an Option",
  pleaseSelectPlanToSubscribeTo: "Please select the plan you to subscribe to",
  planRenewal: "Plan Renewal",
  planUpgrade: "Plan Upgrade",
  pleaseSelectDurationToPayFor: "Please Select Duration you intend to pay for",
  staffAccounts: "Staff Accounts",
  ecommerce: "Ecommerce",
  pleaseSelectAPlan: "Please select a plan",
  includeAddons: "Include Add-ons",
  viewTransactions: "View Transactions",
  viewRedemption: "View Redemption",
  createWallet: "Create Wallet",
  customerWalletBalance: "Customer's Wallet Balance",
  customerOverdraftLimit: "Customer's Overdraft Limit",
  allowOverdraft: "Allow Wallet Overdraft",
  payWtihWallet: "Pay from customer's wallet",
  youAreAboutToPay: "You are about to pay",
  fromCustomersWalletBalanceOf: "from customer's wallet, with balance of",
  deleteWallet: "Delete Wallet",
  fundWallet: "Fund Wallet",
  fundOverDraftLimit: "Fund Overdraft Wallet Limit",
  withdrawWallet: "Withdraw Wallet",
  walletHasBeenFunded: "Wallet has been funded successfully!",
  walletHasBeenCreated: "Wallet has been created successfully",
  walletHasBeenDeleted: "Wallet has been deleted successfully",
  enterAmountFundWith: "Input amount to fund with",
  deleteWallet: "Delete Wallet",
  customerHasNoCompletedTansactions:
    "Customer has no completed tansactions yet",
  customerHasNoCompletedRedemption:
    "Customer has no redemption history yet",
  branch: "Branch",
  apps: "Apps",
  enterNumberOfEcommerceBranches: "Enter Number of Ecommerce Branches",
  enterNumberOfEcommerceBranchesToPay:
    "Enter Number of Ecommerce Branches You Intend to Pay For",
  ecommerceIntegration: "Ecommerce Integration",
  enterNumberOfBranches: "Enter Number of Branches",
  enterNumberOfAdditionalBranchesToPay:
    "Enter Number of Additional Branches You Intend to Pay For",
  enterNumberOfStaffs: "Enter Number of Staffs",
  enterNumberOfStaffsToPayFor: "Enter Number of Staffs You Intend to Pay For",
  discountApplies: "Discount Applies",
  starsOnThe: "stars on the",
  offer: "offer",
  get: "Get",
  moreStarsToRedeem: "more stars to redeem",
  taxVat: "Tax (VAT)",
  callCashierToCompletePayment: "Call Cashier to Complete Payment",
  receipt: "Receipt",
  dear: "Dear",
  thankYouForYourOrderFindGoods:
    "Thank you for your order. Please find the following goods supplied, as agreed.",
  shippingNote: "Shipping Note",
  enterShippingNote: "Enter Shipping Note",
  shippingAddress: "Shipping address",
  enterShippingAddress: "Enter Shipping address",
  wellDoneYouAreDueToRedeem: "Well done! You are due to redeem",
  toGetYourRewardNextVisit: "to get your reward on your next visit. Thank you",
  pointsOnThe: "Points on the",
  morePointsToRedeem: "more points to redeem",
  showCode: "Show code",
  toGetYourRewardOnNextVisit:
    "to get your reward on your next visit. Thank you",
  earn: "Earn",
  delivaryNote: "Delivary Note",
  draftSales: "Draft Sales",
  startDate: "Start date",
  endDate: "End date",
  orders: "Orders",
  checkout: "Checkout",
  noProductItem: "No Product Item",
  scanProductItem: "Scan To Add Product To Cart",
  nitroEmpty: "Scan to add products",
  nitroEmptyProducts: "No product found",
  selectProductImage: "Select Product Image",
  selectExtraImage: "Select Extra Images",
  selectCountry: "Select country",
  selectRegion: "Select State/Region",
  printProductTag: "Print Product Tag",
  transactionReference: "Transaction reference",
  amountPaidSplit: "Enter amount to be paid",
  Cashier: "Cashier",
  Manager: "Manager",
  Owner: "Owner",
  Admin: "Admin",
  addPartners: "Add Partners",
  addCustomStaff: "Add Staff",
  hideCashierInventory: "Hide cashier's inventory",
  addNewLoyaltyPartner: "Add New Loyalty Partner",
  pleaseEnterCompanyName: "Please enter Company Name",
  pleaseEnterCompanyId: "Please enter Company ID",
  companyName: "Company Name",
  companyId: "Company ID",
  pleaseEnterCompanyRepName: "Please enter company representative Name",
  companyRepName: "Name of Company Representative",
  pleaseEnterCompanyRepEmail: "Please enter email of company representative",
  companyRepEmail: "Email of company rep",
  pleaseEnterCompanyRepPhone:
    "Please enter phone number of company representative",
  companyRepPhone: "Phone Number of company rep",
  addReward: "Add reward",
  pleaseEnterRewardName: "Please enter reward name",
  rewardName: "Reward Name",
  rewardQuantity: "Reward Quantity",
  rewardDescription: "Reward Description",
  rewardType: "Reward Type",
  pleaseEnterRewardType: "Please enter reward type",
  pleaseEnterRewardQuantity: "Please enter reward quantity",
  pleaseEnterRewardDescription: "Please enter reward description",
  fineDining: "Fine Dining",
  luxuryFashion: "Luxury Fashion",
  hotels: "Hotels",
  travel: "Travel",
  foodAndBeverage: "Food and Beverage",
  fashion: "Fashion",
  health: "Health",
  furniture: "Furniture",
  entertainment: "Entertainment",
  automobile: "Automobile",
  education: "Education",
  beautyAndSpa: "Beauty and Spa",
  staycation: "Staycation",
  events: "Events",
  trips: "Trips",
  oilAndGas: "Oil and Gas",
  laundry: "Laundry",
  partnerCategory: "Partner Category",
  freeItem: "Free Item",
  customerID: "Customer ID",
  referenceCode: "Reference code",
  valentine: "Valentine Offers",
  blackFriday: "Black Friday Offers",
  orders: "View My Orders",
  terminalId: "Terminal ID",
  terminalName: "Terminal Name",
  accountName: "Account Name",
  accountNum: "Account Number",
  terminalBank: "Bank",
  dedicatedId: "Decicated ID",
  type: "Type",
  cloneProduct: "Clone Product",
  bulkPriceUpdate: "Bulk Update Price",
  bulkEditStock: "Bulk Quantity Update",
  BulkEdit: "Bulk Update",
  priceChecker: "Price Checker",
  productBundle: "Product Bundle",
  sendBulkInvent: "Send Inventory",
  cloneProduct: "Clone Product",
  subscriptionNotYetActive:
    "Your Subscription is not yet active, Kindly pay to activate subscription",
    latitude: "latitude",
    longitude: "longitude",
    editBranch: "Edit Branch",
  deliveryRequests: "Delivery Requests",
  customerInformation: "Customer Information",
  deliveryAddress: "Delivery Address",
  deliveryAmount: "Delivery Amount",
  orderAmount: "Order Amount",
  deliveryDate: "Created Date",
  lehjahService: "Accounting",
  manageReturnedItems: "Manage Return Items",
  viewReturnedItems: "Returned Items",
  viewDamagedItems: "Damaged Items",
  expiredItems: "Expired Items",
  selectNoOfDays: "Select Number of Days",
  selectExpringNoOfDays: "Search Expiring Items",
  dateReceived: "Date Received",
  filterByVat: "Filter By VAT",
  showTransactionWithVat: "Show only transaction with VAT",
  backdatePurchaseOrder: "Backdate this purchase order",
  vat: "VAT",
  magnification: "Magnification",
  expiryDate: "Expiry Date",
  datePaid: "Date Paid",
  transactionId: "Transaction ID",
  acceptPurchaseOrderForSupplier: "Accept Purchse for Supplier",
  youAreAboutToAcceptForSupplier: "You are about to accept purchase order for supplier",
  returnedQuantity:"Returned Quantity",
  cannotReturnMore:"Cannot return more quantity than that purchased!",
  cannotReturnDamaged: "Cannot return damaged item(s) to inventory",
  checktheboxtoreturnItem: "Check the box to return item(s) to inventory",
  itemIsDamaged: "Item is damaged",
  itemDoesNotFit: "Item is not the appropriate size",
  others: "Others",
  returnToInventory: "Return to inventory",
  returnedDate: "Date Returned",
  Date:"Date",
  forBestLoyaltyExperience: "For the Best Loyalty Experience, points to Currency ratio should be Whole-Round Numbers",
  purchaseOrder: "Create Purchase Order",
  acceptOrder: "Receive Purchase Orders",
  CreatePurchaseOrder: "Create Purchase Order",
  purchaseOrderHistory: "Purchase Order History",
  createPurchaseStep1: "Step 1: Select Supplier",
  createPurchaseStep2: "Step 2: Add Products",
  createPurchaseStep3: "Step 3: Review Order",
  selectSuppliers: "Select Suppliers",
  suppliersPage: "Supplier's page",
  submitOrder: "Submit Order",
  back: "Back",
  selectSuppliers: "Select supplier",
  remove: "Remove",
  purchaseOrderHasBeenCreated: "Purchase order has been created",
  totalAmount: "Total Amount",
  receiveSuppliesAndUpdateInventory: "Receive Supplies and Update Inventory",
  step1AcceptPurchaseOrder: "Step 1: Select Purchase Order",
  step2AcceptPurchaseOrder: "Step 2: Receive Products",
  step3AcceptPurchaseOrder: "Step 3: Confirm and Update Inventory",
  purchaseOrder: "Purchase Order",
  orderedQuantity: "Ordered Quantity",
  orderedQty: "Ordered Qty",
  receivedQty: "Received Qty",
  returnedQty: "Returned Qty",
  markAsDelivered: "Mark As Delivered",
  returnItem: "Return Item",
  selectQuantityAndReeasonForReturn: "Select quantity and reason for return",
  markAsPaid: "Mark As Paid",
  markAsUnpaid: "Mark As Unpaid",
  receivedQuntity: "Received Quantity",
  orderedItems: "Ordered Items",
  currentStock: "Current Stock",
  newStock: "New Stock",
  confirmAndUpdate: "Confirm And Update",
  itemsHaveBeenReceivedSuccessfully: "Items have been added to inventory",
  merchantAddress: "Merchant's Address",
  merchantName: "Merchant's Name",
  orderItems: "Items ordered",
  rejectRequest: "Reject",
  acceptRequest: "Accept",
  action: "Action",
  kindlyPerformAction: "Purchase order request",
  pleaseSelectSupplier: "Please Select Supplier",
  receivedBy: "Received By",
  viewProductHistory: "View Product History",
  productHistory: "Product History",
  productChangeHistory: "Product Change History",
  changeType: "Change Type",
  changeAction: "Change Action",
  productWithThisNameAlreadyExist: "Product with this name already exist!, would you like to edit",
  multipleProductWithThisName: "Want to edit instead? Click an option below",
  signingYouOut:"Signing you out..."
};

const newStrings = {};

async function translate(array, keysArr, language) {
  const text = array.join("&q=");
  const languageName = language.name;
  const languageCode = language.language;
  const params = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    body: `q=${text}&target=${languageCode}&format=text&source=en&model=nmt&key=AIzaSyCrdjiL5ZaaQwxaWm1S-Aq5uQJwVkMLdHk`,
  };
  const res = await fetch(
    `https://translation.googleapis.com/language/translate/v2`,
    params
  );
  const data = await res.json();
  const translations = data.data ? data.data.translations : "";

  const object = JSON.parse(localStorage.getItem("translations"));

  for (let i in array) {
    object[languageName][keysArr[i]] = translations[i].translatedText;
  }
  localStorage.setItem("translations", JSON.stringify(object));
}

const exportText = () => {
  const object = stringTranslations;
  object.English = strings;
  const language = store.getState().language.language;
  const exported = object[language] ? object[language] : strings;
  return exported;
};

export const translateText = () => {
  localStorage.setItem("translations", JSON.stringify(stringTranslations));
  languages.forEach((language) => {
    translate(Object.values(newStrings), Object.keys(newStrings), language);
  });
};

//translateText();

export default exportText();

//export default Object.assign(strings, newStrings);
