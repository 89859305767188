import React, { useState } from 'react';
import { Button, Input, message as mess, Modal} from "antd"; 
import strings from '../../../../../../strings';

const SendGroupMessages = ({
    open,
    onCancel,
    selectedGroup,
    sendSmsToCustomerGroup
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState("Hello [CUSTOMER_NAME], thank you for being a loyal customer");
    const [ insertPoint, setInsertPoint ] = useState(0);

    const handleSendGroupMessage = () => {
        if(!selectedGroup) return mess.info("Select a group to continue!");
        if(!message) return mess.info("Type the message continue!");

        setLoading(true);
        sendSmsToCustomerGroup(selectedGroup.id, { message })
        .then(res => {
            if(res.status === 200 || res.status === 201) {
                mess.success(res.data.message || "Message will be sent shortly");
                setLoading(false);
                onCancel();
            }
        })
        .catch(err => {
            mess.error(`${err.response?.data ? err?.response?.data.message : err.message}`);
            setLoading(false);
        })
    }

    const insertValue = () => {
        if (!insertPoint) {
            mess.info(strings.pleaseSelectTheInsertPoint);
            return;
        }
        const firstHalf = message.substring(0, insertPoint);
        const secondHalf = message.substring(insertPoint);
        // console.log(`${firstHalf} [CUSTOMER_NAME] ${secondHalf}`)
        setMessage(`${firstHalf} [CUSTOMER_NAME] ${secondHalf}`);
    }
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`Send Group Message to ${selectedGroup.name}`}
        onOk={handleSendGroupMessage}
        confirmLoading={loading}
    >
        <Input.TextArea
            onMouseUp={event => {
                setInsertPoint(event.target.selectionStart);
            }}
            onChange={(e) => {
                setMessage(e.target.value);
                setInsertPoint(e.target.selectionStart);
            }}
            rows={4}
            placeholder=""
            name="message"
            value={message}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Button
              onClick={() => {
                insertValue("name");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} CUSTOMER_NAME
            </Button>
          </div>
          <div>
            [CUSTOMER_NAME]: {strings.theNameOfCustomersInserted}
          </div>
    </Modal>
  )
}

export default SendGroupMessages