import React from "react";
import { Card, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getAllSalesByDuration,
  getAllSales,
  getBranchSales,
  getBranchSalesByDuration,
  getCashierSales,
  merchantHasSale,
  getAllPaymentLabel
} from "../../../../redux/actions/saleActions";
import numberFormatter from "../../../../utils/numberFormatter";
import { parseReport } from "./functions";
import Transactions from "./Transactions";

import "./index.scss";
import PaymentMethodTotal from "./PaymentMethodTotal";
import strings from "../../../../strings";

class TodayReports extends React.Component {
  state = {
    loading: false,
    reports: [],
    products: [],
    spenders: [],
    transactions: [],
    duration: "today",
    currentPage: 1,
    filterValue: "",
    end: moment(),
    begin: moment(),
    isBranchSelected: false,
    selectedBranch: null,
    isPaymentTypeSelected: false,
    selectedPaymentType: null,
    searchTransactionId: null,
    filterByVat: false,
    paymentLabelLoading: false,
    allPaymentLabels: [],
  };

  componentWillMount() {
    const { pageNumber, begin, end } = this.state;
    this.handleGetSalesRange(pageNumber, begin, end);
    this.handleGetAllSales();
    this.handleGetPaymentOptions();
  }

  handleGetAllSales = (pageNumber = 1) => {
    // Clear any Present Filters
    const { user } = this.props;
    this.updateFilterValue("");
    console.log("Fetching new sales...");
    this.setState({
      loading: true,
    });

    if(user.business_branch){
        this.props
        .getBranchSales(
          {
            pageNumber,
            id: user.business_branch.id,
            type: "default"
          }
        )
        .then(response => {
          this.setState({
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          message.error(strings.errorFetchingRecord);
        });
    }else{
      this.props
      .getAllSales({current: pageNumber})
      .then(response => {
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        message.error(strings.errorFetchingRecord);
      });
    }
  };

  handleGetPaymentOptions = async () => {
    this.setState({
      paymentLabelLoading: true
    })
    this.props.getAllPaymentLabel()
    .then((res) => {
      this.setState({
        paymentLabelLoading: false,
        allPaymentLabels: res.data,
      })
    })
    .catch((err) => {
      this.setState({
        paymentLabelLoading: false
      })
      message.error(`unable to get payment options, ${err.message}`);
    });
  };

  handleGetSalesByPaymentType = (selectedPaymentType, pageNumber, pageSize) => {
    const { begin, end,  } = this.state;
    const { allBranches, user, getBranchSalesByDuration } = this.props;

    const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));

    const staff_id = user.business_branch ? user.id : null;
    const chosenBranch = user.business_branch ? user.business_branch.id : homBranch.id

    let type = "default";
    this.setState({ loading: true });
    getBranchSalesByDuration(
      begin.format().split("T")[0],
      end.format().split("T")[0],
      chosenBranch, 
      pageNumber, 
      type, 
      selectedPaymentType,
      staff_id
    )
    .then((response) => {
      if (response?.data.length > 0) {
        this.props.merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalRangeProfit: response.headers["total-range-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard:
          response.headers["total-range-amount-with-card"],
        totalPaymentWithCash:
          response.headers["total-range-amount-with-cash"],
        totalPaymentWithMobile:
          response.headers["total-range-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-range-amount-with-mtransfer"],
        begin,
        end,
        currentPage: pageNumber,
      });
    })
    .catch((err) => {
      console.log("err",err);
      message.error(strings.errorFetchingRecord);
      this.setState({ loading: false })
    })
  }

  handleGetSalesRange(pageNumber, begin, end, id) {
    // Clear any Present Filters
    const { user } = this.props;
    const { isBranchSelected, selectedBranch } = this.state;
    this.updateFilterValue("");
    this.setState({
      loading: true,
    });
/* begin.format().split("T")[0],
      end.format().split("T")[0],
      chosenBranch, 
      pageNumber, 
      type, 
      selectedPaymentType,
      staff_id*/ 
      const type = "default";
    if(user.business_branch){
      if(user.role.id === 3 || user.role.id === 2){
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          user.business_branch.id,
          pageNumber,
          type,
          null,
          user.id,
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else{
        const id = user.business_branch.id;
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }
      
    }else{
      if(id){
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id = id,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else if(isBranchSelected){
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id = selectedBranch,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else{
        this.props
        .getAllSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          pageNumber
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: response.headers.total,
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }
    }
  }

  handleSelectPaymentType = (value, paymentType) => {
    if(value){
      return this.setState({
        isPaymentTypeSelected: value,
        selectedPaymentType: paymentType
      });
    }

    this.setState({
      isPaymentTypeSelected: value,
      selectedPaymentType: paymentType
    });
  }

  handleGetSalesByTransactionId = (transactionId, pageNumber, pageSize) => {
    const { selectedBranch, selectedPaymentType, begin, end  } = this.state;
    const { allBranches, getBranchSalesByDuration } = this.props;
    let chosenBranch;
    if(!selectedBranch){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));
      chosenBranch = homBranch.id;
    }else{
      chosenBranch = selectedBranch
    }
  
    this.setState({ loading: true });
    getBranchSalesByDuration(
      begin.format().split("T")[0],
      end.format().split("T")[0],
      chosenBranch,
      pageNumber,
      "default",
      selectedPaymentType,
      null,
      transactionId
    )
    .then((response) => {
      if (response?.data.length > 0) {
        merchantHasSale(true);
      }
      this.setState({
        reports: parseReport(response?.data),
        totalRecords: Number(response.headers.total),
        loading: false,
        totalAmount: response.headers["total-amount"],
        totalProfit: response.headers["total-profit"],
        amountInRange: response.headers["total-range-amount"],
        totalPaymentWithCard: response.headers["total-amount-with-card"],
        totalPaymentWithCash: response.headers["total-amount-with-cash"],
        totalPaymentWithMobile: response.headers["total-amount-with-mobile"],
        totalPaymentWithMTransfer:
          response.headers["total-amount-with-mtransfer"],
        begin: begin,
        end: end,
        currentPage: Number(response.headers.page),
        pageSize: Number(response.headers["per-page"])
      });
    })
    .catch((err) => {
      console.log("err",err);
      message.error(strings.errorFetchingRecord);
      this.setState({ loading: false })
    });
  }

  resetTransactions = (transactions = []) => {
    this.setState({
      reports: transactions,
    });
  };

  updateFilterValue = filterValue =>{
    const { pageNumber, begin, end } = this.state;
    if(filterValue !== ""){
      let filtered;
      if(!isNaN(filterValue)){
        filtered = filterValue;
      }else if(filterValue.toLowerCase().includes("bank transfer")){
        filtered = `is_paid_with_mtransfer`;
      }else if(filterValue.toLowerCase().includes("wallet")){
        filtered = `is_paid_with_customer_account`;
      } else if(filterValue.toLowerCase().includes("payment label")){
        filtered = `payment_label`;
      }else if(filterValue === "Split payment"){
        filtered = `split_payment`;
      }else{
        filtered = `is_paid_with_${filterValue.toLowerCase()}`;
      }

      this.handleSelectPaymentType(true, filtered);
      this.handleGetSalesByPaymentType(filtered);
      const namey = this.state.allPaymentLabels.find(label => label.id == filterValue);
      return this.setState({ 
        filterValue: filtered, 
        paymentName: !isNaN(filterValue) ? namey.label_name : filterValue
      })
    }

    this.setState({ filterValue: "" });
    // this.handleGetSalesRange(pageNumber, begin, end);
  }

    handleBranchSelect = value => {
      const { pageNumber, begin, end } = this.state;
      this.setState({ selectedBranch: value });
      if(this.props.allBranches.length === 1) return this.handleGetSalesRange(1, begin, end);
      this.setState({ isBranchSelected: true, selectedBranch: value });
      this.handleGetSalesRange(1, begin, end, value);
   }

  handleTransactionIdSelect = value => {
    this.setState({ 
      searchTransactionId: value 
    });

    this.handleGetSalesByTransactionId(value, 1);
  }

  render() {
    const {
      reports,
      totalRecords,
      currentPage,
      amountInRange,
      totalRangeProfit,
      totalPaymentWithCard,
      totalPaymentWithCash,
      totalPaymentWithMobile,
      totalPaymentWithMTransfer,
      filterValue,
      begin,
      end,
      selectedBranch,
      allPaymentLabels
    } = this.state;

    const { user } = this.props;

    const isAdmin = user.role_id === 1;

    return (
      <main>
        <Card
          title={`${strings.todaysSales}:
          ${this.props.user.currency ||
            this.props.user.employer.currency}${numberFormatter(
            amountInRange || 0
          )}
          (${totalRecords || 0} ${strings.transaction}${
            totalRecords > 1 ? "s" : ""
          })`}
          loading={this.state.loading}
        >
          <div style={{ display: "flex" }}>
            {isAdmin && (
              <span style={{ margin: "0 10px" }}>
                {strings.totalProfit}: {totalRangeProfit}
              </span>
            )}

            <PaymentMethodTotal
              user={user}
              totalPaymentWithCard={totalPaymentWithCard}
              totalPaymentWithCash={totalPaymentWithCash}
              totalPaymentWithMTransfer={totalPaymentWithMTransfer}
              totalPaymentWithMobile={totalPaymentWithMobile}
              filterValue={filterValue}
            />
          </div>

          <section className="invoices-table mt-40">
            <Transactions
              transactions={reports || []}
              total={totalRecords}
              currentPage={currentPage}
              resetTransactions={this.resetTransactions}
              getAllSales={pageNumber => {
                this.setState({
                  loading: true,
                });
                this.handleGetAllSales(pageNumber);
              }}
              getSalesRange={pageNumber => {
                this.setState({
                  loading: true,
                });
                this.handleGetSalesRange(pageNumber, begin, end);
              }}
              updateFilterValue={this.updateFilterValue}
              handleSelectPaymentType={this.handleSelectPaymentType}
              handleTransactionIdSelect={this.handleTransactionIdSelect}
              loading={this.state.loading}
              user={this.props.user}
              begin={begin}
              end={end}
              handleBranchSelect={this.handleBranchSelect}
              selectedBranch={selectedBranch}
              todaysReport
              allPaymentLabels={allPaymentLabels}
            />
          </section>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  products: state.product.allProducts,
  customers: state.customer.allCustomers,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, {
  getAllSalesByDuration,
  getAllSales,
  getBranchSales,
  getBranchSalesByDuration,
  getCashierSales,
  merchantHasSale,
  getAllPaymentLabel
})(TodayReports);
