import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  Avatar,
  Modal,
  Popover,
  Button,
  Tree,
  Spin,
  Input,
  Table,
  Select,
  Tag,
  message,
  InputNumber,
  Row,
  Col,
} from "antd";
import { Form, Icon } from "@ant-design/compatible";
import {
  EditOutlined,
  DeleteOutlined,
  GifOutlined,
  GiftOutlined,
  CreditCardOutlined,
  ShoppingOutlined,
  MailOutlined,
  RocketOutlined,
  HistoryOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  LIST_GIFT_CARDS,
  EDIT_GIFT_CARD,
  GET_MERCHANT,
  GET_CUSTOMER,
  CONFIRM_ORGANISATION_GIFTCARD,
  USE_ORGANISATION_GIFTCARD,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { useQuery, useMutation } from "@apollo/client";
import numberFormatter from "../../../../../../utils/numberFormatter";
import {
  assignLoyaltyIdToCustomer,
  createWallet,
  fundWallet,
  deleteWallet,
  fundWalletOverdraftLimit,
} from "../../../../../../redux/actions/customerActions";
import moment from "moment";
import { connect } from "react-redux";
import { getAllCustomers } from "../../../../../../redux/actions/customerActions";
import {
  getAllSavedSales,
  getCustomerSales,
  getCustomersLoyalty,
} from "../../../../../../redux/actions/saleActions";
import { setCurrentUser } from "../../../../../../redux/actions/authActions";
import {
  getAllProducts,
  addNewProduct,
} from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";
import numberWithCommas from "../../../../../../utils/numberFormatter";
import api from "../../../../../../api";
import { Redirect } from "react-router-dom";
import AddCardRecipient from "./AddCardRecipient";
import ResendCardRecipient from "./ResendCardRecipient";
import { toJpeg } from "html-to-image";
import { exportComponentAsJPEG } from "react-component-export-image";
// import { QRCode } from "react-qrcode-logo";
import CreditLoyaltyModal from "../../../../../Sales/_partials/CreditLoyalty";
import RedeemLoyaltyModal from "../../../../../Sales/_partials/RedeemLoyaltyModal";
import BVNModal from "./BVNModal";
import Transactionmodal from "../TransactionModal";

// import { getShock } from "../../functions";
const { TreeNode } = Tree;
const { Option } = Select;

const CustomerDetails = ({
  user,
  customer = {},
  closeModal,
  toggleEditCustomerModal,
  toggleDeleteCustomerModal,
  toggleSmsModal,
  isMembersLoading,
  isProgramsLoading,
  activeLoyalty,
  showCustomerDetailModal,
  toggleRedeemRewardModal,
  assignLoyaltyIdToCustomer,
  getAllSavedSales,
  allLoyaltyPrograms,
  customerMultiLevelDetails,
  toggleAwardPointsModal,
  isSubscribedToMultiLevel,
  allSales,
  getAllCustomers,
  products,
  addNewProduct,
  getAllProducts,
  isCredPalActive,
  pageNumber,
  pageSize,
  getCustomersLoyalty,
  isCustomerWalletActive,
  createWallet,
  fundWallet,
  deleteWallet,
  fundWalletOverdraftLimit,
  getCustomerSales
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editField, setEditField] = useState(null);
  const [assignLoyaltyVisible, setAssignLoyaltyVisible] = useState(false);
  const [assignGiftCardModalVisible, setAssignGiftCardModalVisible] =
    useState(false);
  const [loyaltyId, setLoyaltyId] = useState(null);
  const [editCustomDataVisible, setEditCustomDataVisible] = useState(false);
  const [topupModalVisible, setTopupModalVisible] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [showGiftCardDetails, setShowGiftCardDetails] = useState(null);
  const [addGiftCardRecipient, showAddGiftCardRecipient] = useState(false);
  const [resendGiftCardRecipient, showResendGiftCardRecipient] =
    useState(false);
  const [downloadGiftCardLoading, setDownloadGiftCardLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [points, setPoints] = useState([]);

  const [creditLoyaltyModal, setCreditLoyaltyModal] = useState(false);
  const [redeemLoyaltyModal, setRedeemLoyaltyModal] = useState(false);
  const [openBVN, setOpenBVNModal] = useState(false);
  const [customerRedemption, setCustomerRedemption] = useState(null);
  const [walletLoading, setWalletLoading] = useState(false);
  const [walletOverdraftLoading, setWalletOverdraftLoading] = useState(false);
  const [deleteWalletLoading, setDeleteWalletLoading] = useState(false);
  const [ openTransaction, setOpenTransaction ] = useState(false);

  const { data: getCustomer, loading: getCustomerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        id: customer.user_id,
      },
      skip: !customer.user_id,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const customerPoints = getCustomer?.customer?.points;
    if (customerPoints) {
      setPoints(customerPoints);
    }
  }, [activeLoyalty?.id, getCustomer]);

  const componentRef = useRef();

  useEffect(() => {
    getAllSavedSales(1, "export");
  }, [getAllSavedSales]);

  useEffect(() => {
    if (!products || products.length < 1) {
      getAllProducts();
    } else localStorage.setItem("products", JSON.stringify(products));
  }, [getAllProducts, products]);

  useEffect(() => {
    if (showGiftCardDetails) {
      if (!showGiftCardDetails.gift_card_url) {
        toJpeg(componentRef.current, { quality: 0.95 })
          .then(function (dataUrl) {
            setImageUrl(dataUrl);
          })
          .catch((err) => console.log({ err }));
      }
    }
  }, [showGiftCardDetails]);

  useEffect(() => {
    const user_id = customer.id;
    getCustomerRedemption(user_id);
  }, [customer]);

  const {
    id,
    first_name,
    last_name,
    date_of_birth,
    sex,
    phone_number,
    email,
    state,
    token,
    country,
    address_line1,
    loyalty_id,
    transaction_ids = [],
    customer_custom_fields,
    wallet,
    allow_overdraft,
  } = customer;

  const stamps_program = customer.loyalty_points?.stamps_program;
  const simple_points = customer.loyalty_points?.simple_points;

  const transactions = [];
  const allTransactions = [];

  const { data: listCards, loading: listCardLoading } =
    useQuery(LIST_GIFT_CARDS);

  const { data: getMerchant, loading: getMerchantLoading } =
    useQuery(GET_MERCHANT);

  useEffect(() => {
    if (!user.merchant_id) {
      !getMerchantLoading &&
        getMerchant?.getMerchant &&
        setCurrentUser({ ...user, ...getMerchant?.getMerchant });
    }
  }, [getMerchant?.getMerchant, getMerchantLoading, user]);

  //all gift cards assigned to customers
  const giftCardCustomers = {};
  !listCardLoading &&
    listCards?.listGiftCards?.length > 0 &&
    listCards?.listGiftCards?.forEach((card) => {
      if (card?.customer_id) {
        if (giftCardCustomers[card?.customer_id]) {
          giftCardCustomers[card?.customer_id].push(card);
        } else giftCardCustomers[card?.customer_id] = [card];
      }
    });

  allSales.allSales.length > 0 &&
    allSales.allSales.forEach(
      (sale) =>
        sale.transactions.length > 0 &&
        sale.transactions.forEach((saleTransactions) =>
          allTransactions.push(saleTransactions)
        )
    );

  // if (
  //   products.length > 0 &&
  //   listCards?.listGiftCards.length > 0
  // ) {
  //   const topupProduct = products.find((one) => one.name === "Topup Gift Card");
  //   if (!topupProduct) {
  //     addNewProduct(
  //       transformInToFormObject({
  //         name: "Topup Gift Card",
  //         price: 0,
  //         quantity: 0,
  //         merchant_product_category_id: 4,
  //       })
  //     );
  //   }
  // }

  transaction_ids.forEach((transaction) => {
    const myTransaction = allTransactions.find(
      (savedTransaction) => savedTransaction.id === transaction
    );
    myTransaction && transactions.push(myTransaction);
  });

  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);

  const isAdmin = user.role_id === 1;

  const loyalties = [...simple_points, ...stamps_program];

  let merchantLoyalties = [];

  loyalties.forEach((loyalty) => {
    if (
      allLoyaltyPrograms.find(
        (loyaltyProgram) =>
          loyaltyProgram.id === loyalty.merchant_loyalty_program_id
      )
    ) {
      merchantLoyalties.push(loyalty);
    }
  });

  const showTransactionsModal = () => {
    setOpenTransaction(true);
    // Modal.info({
    //   title: strings.transactions,
    //   cancelText: strings.cancel,
    //   width: 826,
    //   centered: true,
    //   content: (
    //     <div>
    //       {transactions.length > 0 && (
    //         <Table
    //           dataSource={transactions}
    //           columns={columns}
    //           onRowClick={openTransactionDetails}
    //         ></Table>
    //       )}
    //       {transactions.length < 1 && strings.customerHasNoCompletedTansactions}
    //     </div>
    //   ),
    // });
  };

  const showRedemptionsModal = () => {
    Modal.info({
      title: strings.customerRedepmtion,
      cancelText: strings.cancel,
      width: 826,
      centered: true,
      content: (
        <div>
          {customerRedemption.length > 0 && (
            <Table
              dataSource={customerRedemption}
              columns={redemptionColumns}
              onRowClick={openTransactionDetails}
            ></Table>
          )}
          {customerRedemption.length < 1 &&
            strings.customerHasNoCompletedRedemption}
        </div>
      ),
    });
  };

  const [editCard, { loading: editCardLoading }] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        Modal.success({
          title: `You have successfully edited gift card - ${data.editGiftCard.card_number}`,
          onOk: () => setShowGiftCardDetails(null),
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const activateGiftCard = async () =>
    showGiftCardDetails.reciepient_email
      ? editCard({
          variables: { data: { id: showGiftCardDetails.id, isActive: true } },
        })
      : Modal.error({
          title: "You must add a recipient to gift card to activate",
        });
  const deActivateGiftCard = async () =>
    editCard({
      variables: { data: { id: showGiftCardDetails.id, isActive: false } },
    });

  const handleCreateWallet = () => {
    let amount;
    Modal.info({
      title: strings.createWallet,
      cancelText: strings.cancel,
      // width: 826,
      centered: true,
      content: (
        <div>
          <InputNumber
            style={{ width: "100%" }}
            placeholder={strings.enterAmountFundWith}
            onChange={(value) => (amount = value)}
          />
        </div>
      ),
      onOk: () => {
        if (!amount) return message.error("Enter amount to continue!");
        if (amount) handleCreateWalletFinale(amount);
      },
      closable: true,
    });
  };

  const handleCreateWalletFinale = (amount) => {
    const payload = {
      phone_number: customer.phone_number,
      amount,
    };

    setWalletLoading(true);
    createWallet(payload)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          message.success(strings.walletHasBeenCreated);
          getAllCustomers(pageNumber, pageSize);
          closeModal(customer);
        }
      })
      .catch((err) => {
        console.log("err", err.message, err.response);

        message.error(err.message);
      });
  };

  const handleFundWalletFinale = (amount) => {
    const payload = {
      phone_number: customer.phone_number,
      amount,
    };

    setWalletLoading(true);
    fundWallet(payload)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setWalletLoading(false);
          message.success(strings.walletHasBeenFunded);
          getAllCustomers(pageNumber, pageSize);
          closeModal(customer);
        }
      })
      .catch((err) => {
        setWalletLoading(false);
        console.log("err", err.message, err.response);

        message.error("unable to fund wallet");
      });
  };

  const handleFundWalletOverdraftFinale = (amount) => {
    const payload = {
      phone_number: customer.phone_number,
      overdraft_limit: amount,
    };

    setWalletOverdraftLoading(true);
    fundWalletOverdraftLimit(payload)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        setWalletOverdraftLoading(false);
        message.success(strings.walletHasBeenFunded);
        getAllCustomers(pageNumber, pageSize);
        closeModal(customer);
      }
    })
    .catch((err) => {
      setWalletOverdraftLoading(false);
      console.log("err", err.message, err.response);

      message.error("unable to fund overdraft");
    });
  };

  const handleFundWallet = () => {
    let amount;
    Modal.info({
      title: strings.fundWallet,
      cancelText: strings.cancel,
      // width: 826,
      centered: true,
      content: (
        <div>
          <div>
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.enterAmountFundWith}
              onChange={(value) => (amount = value)}
            />
          </div>
        </div>
      ),
      onOk: () => {
        if (!amount) return message.error("Enter amount to continue!");
        if (amount) handleFundWalletFinale(amount);
      },
      closable: true,
    });
  };

  const handleFundWalletOverdraft = () => {
    let amount;
    Modal.info({
      title: strings.fundOverDraftLimit,
      cancelText: strings.cancel,
      // width: 826,
      centered: true,
      content: (
        <div>
          <div>
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.enterAmountFundWith}
              onChange={(value) => (amount = value)}
            />
          </div>
        </div>
      ),
      onOk: () => {
        if (!amount) return message.error("Enter amount to continue!");
        if (amount) handleFundWalletOverdraftFinale(amount);
      },
      closable: true,
    });
  };

  const handleDeletWallet = () => {
    setDeleteWalletLoading(true);
    deleteWallet({ phone_number: customer.phone_number })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setDeleteWalletLoading(false);
          message.success(strings.walletHasBeenDeleted);
          getAllCustomers(pageNumber, pageSize);
        }
      })
      .catch((err) => {
        setDeleteWalletLoading(false);
        console.log("err", err.message, err.response);

        message.error("unavle to ");
      });
  };
  // Content of the action column
  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key="mail"
        icon={<MailOutlined />}
        onClick={() => toggleSmsModal(false)}
      >
        {strings.sendSms}
      </Button>
      <Button
        key="edit"
        icon={<EditOutlined />}
        onClick={toggleEditCustomerModal}
      >
        {strings.editCustomer}
      </Button>
      <Button
        key="redeem"
        icon={<GifOutlined />}
        onClick={() => toggleRedeemRewardModal(false)}
      >
        {strings.redeemReward}
      </Button>
      {/* {isSubscribedToMultiLevel && (
        <Button
          key="enroll"
          icon="man"
          loading={isProgramsLoading}
          onClick={() => toggleAwardPointsModal()}
        >
          Enroll Customer
        </Button>
      )} */}
      {isAdminIsManager && (
        <Button
          key="assign"
          loading={isLoading}
          icon={<CreditCardOutlined />}
          onClick={assignLoyaltyId}
          disabled={!!customer.loyalty_id}
        >
          {strings.issueLoyaltyCard}
        </Button>
      )}
      {isAdminIsManager && (
        <Button
          key="issue"
          loading={isLoading}
          icon={<CreditCardOutlined />}
          disabled={listCardLoading}
          onClick={() => setAssignGiftCardModalVisible(true)}
        >
          Issue Gift Card
        </Button>
      )}
      {isCredPalActive && isAdminIsManager && (
        <Button
          key="issue"
          loading={isLoading}
          icon={<CreditCardOutlined />}
          // disabled={listCardLoading}
          onClick={() => setOpenBVNModal(true)}
        >
          Update BVN
        </Button>
      )}
      {/* {isAdminIsManager && (
        <Button
          key="assign"
          loading={isLoading}
          icon={<CreditCardOutlined />}
          onClick={() => topUpCard(giftCardCustomers[customer.id])}
        >
          Topup Gift Card
        </Button>
      )} */}

      {activeLoyalty && (
        <Button
          key="credit_loyalty"
          icon={<GiftOutlined />}
          onClick={() => setCreditLoyaltyModal(true)}
        >
          Credit Loyalty Points
        </Button>
      )}
      {activeLoyalty && (
        <Button
          key="redeem_loyalty"
          icon={<GiftOutlined />}
          onClick={() => setRedeemLoyaltyModal(true)}
        >
          Redeem Loyalty Points
        </Button>
      )}

      {isAdmin && (
        <Button
          key="delete"
          icon={<DeleteOutlined />}
          onClick={toggleDeleteCustomerModal}
        >
          {strings.deleteCustomer}
        </Button>
      )}
      <Button
        key="view-transactions"
        icon={<ShoppingOutlined />}
        onClick={showTransactionsModal}
      >
        {strings.viewTransactions}
      </Button>
      {isAdmin && (
        <Button
          key="view-redmption"
          icon={<HistoryOutlined />}
          onClick={showRedemptionsModal}
        >
          {strings.viewRedemption}
        </Button>
      )}
      {isAdmin && isCustomerWalletActive && (
        <Button
          key="view-isCustomerWalletActive"
          icon={<HistoryOutlined />}
          loading={walletLoading}
          onClick={() =>
            customer && customer.wallet
              ? handleFundWallet()
              : handleCreateWallet()
          }
        >
          {customer && customer.wallet
            ? strings.fundWallet
            : strings.createWallet}
        </Button>
      )}
      {isAdmin && isCustomerWalletActive && customer && customer.wallet && (
        <Button
          key="overdraft"
          icon={<HistoryOutlined />}
          loading={walletOverdraftLoading}
          onClick={() => handleFundWalletOverdraft()}
        >
          {strings.fundOverDraftLimit}
        </Button>
      )}
      {isAdmin && isCustomerWalletActive && customer && customer.wallet && (
        <Button
          key="delete-wallet"
          icon={<HistoryOutlined />}
          onClick={handleDeletWallet}
          loading={deleteWalletLoading}
        >
          {strings.deleteWallet}
        </Button>
      )}
    </div>
  );

  const cashierActionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key="redeem"
        icon={<RocketOutlined />}
        onClick={() => toggleRedeemRewardModal(false)}
      >
        {strings.redeemReward}
      </Button>
      {activeLoyalty && (
        <Button
          key="credit_loyalty"
          icon={<GiftOutlined />}
          onClick={() => setCreditLoyaltyModal(true)}
        >
          Credit Loyalty Points
        </Button>
      )}
      {activeLoyalty && (
        <Button
          key="redeem_loyalty"
          icon={<GiftOutlined />}
          onClick={() => setRedeemLoyaltyModal(true)}
        >
          Redeem Loyalty Points
        </Button>
      )}
    </div>
  );

  const exportImage = () => {
    setDownloadGiftCardLoading(true);
    toJpeg(componentRef.current, { quality: 0.95 }).then(function (dataUrl) {
      setDownloadGiftCardLoading(false);
      console.log({ dataUrl });
      var link = document.createElement("a");
      link.download = `${showGiftCardDetails.card_number}.jpeg`;
      link.href = dataUrl;
      link.click();
    });
  };

  const giftCardMenu = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={5}
        disabled={
          !showGiftCardDetails?.customer_id || showGiftCardDetails?.isActive
        }
        loading={editCardLoading}
        onClick={activateGiftCard}
      >
        Activate card
      </Button>
      <Button
        key={6}
        disabled={!showGiftCardDetails?.isActive}
        loading={editCardLoading}
        onClick={deActivateGiftCard}
      >
        Deactivate card
      </Button>

      <Button
        key={8}
        disabled={!!showGiftCardDetails?.recipient_email}
        onClick={() => showAddGiftCardRecipient(true)}
      >
        Add Card Recipient
      </Button>

      <Button
        key={8}
        disabled={!showGiftCardDetails?.recipient_email}
        onClick={() => showResendGiftCardRecipient(true)}
      >
        Re-send gift card
      </Button>

      <Button
        // onClick={exportImage}
        onClick={() =>
          exportComponentAsJPEG(componentRef, {
            fileName: showGiftCardDetails.card_number,
          })
        }
        loading={downloadGiftCardLoading}
      >
        Export As JPG
      </Button>
      <Button
        key={3}
        disabled={!showGiftCardDetails?.customer_id}
        onClick={() => topUpCard(showGiftCardDetails)}
      >
        Topup card
      </Button>
    </div>
  );

  function assignLoyaltyId() {
    setIsLoading(true);
    api.HttpClient.get("/get_loyalty_ids?page[number]=1&page[size]=100000")
      .then((res) => {
        setIsLoading(false);
        if (res.status && res.status === 200) {
          setAssignLoyaltyVisible(true);
          setLoyaltyId(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message);
      });
  }
  // console.log({points});

  const multiLevelDetails = getCustomerLoading ? (
    <Spin />
  ) : points.length > 0 ? (
    <p>
      <strong>{strings.membershipPoints}</strong>:
      <br />
      {points.map((point, idx) => (
        <Fragment key={idx}>
          <span>
            <Icon type="minus" style={{ marginLeft: "0.3rem" }} />
          </span>

          <span style={{ marginLeft: ".5rem" }}>
            {point.loyalty_name}: {`${Number(point.points_value).toFixed(2)} Point(s)`}
          </span>
        </Fragment>
      ))}
    </p>
  ) : (
    <p>
      <strong>{strings.membershipPoints}</strong>: --
    </p>
  );

  const columns = [
    {
      title: strings.picture,
      dataIndex: "product_picture",
      key: "product_picture",
      render: (picture) => (
        <div className="image">
          <Avatar size="small" shape="square" src={picture} alt="" />
        </div>
      ),
    },
    {
      title: strings.name,
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: strings.quantity,
      dataIndex: "product_quantity",
      key: "product_quantity",
      defaultSortOrder: "ascend",
      sorter: (a, b) => Number(b.amount) - Number(a.amount),
      render: (qty) => Number(qty).toFixed(2),
    },
    {
      title: strings.amount,
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => Number(b.amount) - Number(a.amount),
      render: (qty) => Number(qty).toFixed(2),
    },
    {
      title: strings.createdDate,
      dataIndex: "created_at",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      key: Math.random(),
      render: (record) =>
        record ? (
          <span>{moment(record).format("YYYY-MM-DD")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
    {
      title: strings.createdTime,
      dataIndex: "created_at",
      key: Math.random(),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      render: (record) =>
        record ? (
          <span>{moment(record).format("hh:mm:ss A")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
  ];

  const redemptionColumns = [
    {
      title: strings.programType,
      dataIndex: "program_type",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      filterDropdown: (...props) => {
        const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
          props[0];
        return (
          <div style={{ padding: 8 }}>
            <section className="filter-container">
              <Input
                onChange={(e) => setSelectedKeys([e.target.value])}
                value={selectedKeys[0]}
                placeholder="Search event code"
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <footer
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderTop: "solid 1px #E6E6E6",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Ok
                </Button>
                <Button
                  size="small"
                  style={{ width: 90 }}
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                >
                  {strings.reset}
                </Button>
              </footer>
            </section>
          </div>
        );
      },
      onFilter: (value, record) => {
        if (!record.program_type) return;
        return record.program_type
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      filterMultiple: false,
    },
    {
      title: strings.redemptionValue,
      dataIndex: "points",
      render: (record, details) => {
        return record ? <span>{record}</span> : <span>{details.stamps}</span>;
      },
      // ...this.getColumnSearchProps("active"),
    },
    {
      title: strings.redemptionDate,
      dataIndex: "created_at",
      render: (record) =>
        record ? (
          <span>{moment(record).format("YYYY/MM/DD h:mm:ss A")}</span>
        ) : (
          <span>N/A</span>
        ),
      sorter: (a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      },
    },
  ];

  const getCustomerRedemption = (user_id) => {
    getCustomersLoyalty(user_id)
      .then((res) => {
        if (res.status === 200) {
          setCustomerRedemption(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const openTransactionDetails = (transaction) => {
    Modal.info({
      title: strings.transactionDetails,
      content: (
        <div>
          <p>
            <strong>{strings.name}</strong>: {transaction.product_name}
          </p>
          <p>
            <strong>{strings.amount}</strong>: {transaction.amount}
          </p>
          <p>
            <strong>{strings.quantity}</strong>: {transaction.product_quantity}
          </p>
          <p>
            <strong>{strings.createdAt}</strong>:{" "}
            {moment(transaction.created_at).format("YYYY-MM-DD hh:mm:ss A")}
          </p>
          <p>
            <strong>{strings.profit}</strong>: {transaction.profit}
          </p>
          <p>
            <strong>{strings.points}</strong>: {transaction.points}
          </p>
          <p>
            <strong>{strings.redemptionToken}</strong>:{" "}
            {transaction.redemption_token}
          </p>
          <p>
            <strong>{strings.stamps}</strong>: {transaction.stamps}
          </p>
          <p>
            <strong>{strings.token}</strong>: {transaction.token}
          </p>
        </div>
      ),
    });
  };

  const topUpCard = (card) => {
    setTopupModalVisible(true);
    setCardNumber(card.id);
  };

  const TopupCardForm = ({
    visible,
    onCancel,
    setRedirect,
    cardId,
    customer,
  }) => {
    const [amount, setAmount] = useState(0);
    const handleTopup = () => {
      if (!amount) return message.error("Enter topup amount to continue!");
      else
        return setRedirect(
          `/sales?topup&custmr=${customer.user_id}&card=${cardId}&amt=${amount}`
        );
    };
    return (
      <Modal
        title="Enter topup amount"
        open={visible}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancel</Button>,
          <Button type="primary" onClick={handleTopup}>
            Top Up
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Amount" name="topupAmount">
            <InputNumber
              size="large"
              min={0}
              value={amount}
              onChange={(val) => setAmount(val)}
            />
            <div style={{ marginTop: "10px", fontSize: "15px" }}>
              <em>A transaction charge of NGN100 will be added</em>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const EditCustomDataForm = ({ form, field, onCancel, visible }) => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = form;
    const [editLoading, setEditLoading] = useState(false);

    const { name, value } = field;

    const handleEditData = () => {
      let custom_data = [];

      form.validateFields(async (err, values) => {
        if (err) return;

        if (values[name] === value) {
          return message.error("Change custom data value to edit!");
        } else {
          setEditLoading(true);

          custom_data = [
            {
              name: Object.keys(values)[0],
              value: Object.values(values)[0],
            },
          ];
          await api.HttpClient.put(
            `/merchant/customer_custom_fields/${customer.id}/edit`,
            { custom_data }
          )
            .then((res) => {
              if (res.status === 200) {
                setEditLoading(false);
                Modal.success({
                  title: "Edit successful",
                  // content: "You have deleted custom data successfully.",
                });
                getAllCustomers(pageNumber, pageSize);
                closeModal();
              } else {
                setEditLoading(false);
                Modal.success({
                  title: "Unable to edit, please try again",
                  // content: "You have deleted custom data successfully.",
                });
              }
            })
            .catch((err) => {
              setEditLoading(false);
              message.error(err.message);
            });
        }
      });
    };

    const dropdownOptions = [
      "Saloon",
      "SUV & Space Bus",
      "Truck",
      "14 Seater Bus",
      "18 Seater Bus",
      "32 Seater Bus",
      "36 Seater Bus",
      "Pickup Truck",
      "Cargo Truck",
    ];

    return (
      <Modal
        open={visible}
        footer={[
          <Button key={1} type="ghost" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key={2}
            type="primary"
            onClick={() => handleEditData()}
            loading={editLoading}
          >
            Edit
          </Button>,
        ]}
      >
        <FormItem label={name}>
          {getFieldDecorator(name, { initialValue: value })(
            name.slice(0, 12).toLowerCase() === "vehicle type" ? (
              <Select size="large">
                {dropdownOptions.map((option) => (
                  <Select.Option value={option}>{option}</Select.Option>
                ))}
              </Select>
            ) : (
              <Input size="large" />
            )
          )}
        </FormItem>
      </Modal>
    );
  };

  const EditCustomData = Form.create()(EditCustomDataForm);

  const handleDeleteField = (field) => {
    Modal.confirm({
      title: "Delete Custom Data",
      content: "Do you want to delete this custom data?",
      onOk: async () => {
        // console.log(field);
        await api.HttpClient.delete(
          `/customers/custom_fields/${customer.id}/${field.name}`
        )
          .then((res) => {
            if (res.status === 204) {
              Modal.success({
                title: "Delete successful",
                content: "You have deleted custom data successfully.",
              });
              getAllCustomers(pageNumber, pageSize);
              closeModal();
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      },
    });
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      {editField && (
        <EditCustomData
          field={editField}
          visible={editCustomDataVisible}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onCancel={() => {
            setEditField(null);
            setEditCustomDataVisible(false);
          }}
        />
      )}
      {creditLoyaltyModal && (
        <CreditLoyaltyModal
          visible={creditLoyaltyModal}
          onCancel={() => setCreditLoyaltyModal(false)}
          customer={customer}
          activeLoyalty={activeLoyalty}
        />
      )}

      {openBVN && (
        <BVNModal
          open={openBVN}
          onCancel={() => setOpenBVNModal(false)}
          phone_number={phone_number}
        />
      )}

      {redeemLoyaltyModal && (
        <RedeemLoyaltyModal
          visible={redeemLoyaltyModal}
          onCancel={() => setRedeemLoyaltyModal(false)}
          customer={customer}
          activeLoyalty={activeLoyalty}
          user={user}
        />
      )}

      {topupModalVisible && (
        <TopupCardForm
          visible={topupModalVisible}
          onCancel={() => setTopupModalVisible(false)}
          cardId={cardNumber}
          customer={customer}
          setRedirect={setRedirect}
        />
      )}
      {loyaltyId && (
        <AssignLoyalty
          id={id}
          loyaltyId={loyaltyId}
          visible={assignLoyaltyVisible}
          onCancel={() => {
            setAssignLoyaltyVisible(false);
            setLoyaltyId(null);
          }}
          getAllCustomers={() => getAllCustomers(pageNumber, pageSize)}
          assignLoyaltyIdToCustomer={assignLoyaltyIdToCustomer}
          closeModal={closeModal}
          customerName={`${first_name} ${last_name ? last_name : ""}`}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />
      )}
      {assignGiftCardModalVisible && !listCardLoading && (
        <AssignGiftCard
          giftCards={listCards?.listGiftCards || []}
          visible={assignGiftCardModalVisible}
          onCancel={() => setAssignGiftCardModalVisible(false)}
          customerName={`${first_name} ${last_name ? last_name : ""}`}
          customerId={id}
          closeModal={closeModal}
        />
      )}
      {showGiftCardDetails && (
        <Modal
          open={!!showGiftCardDetails}
          width={800}
          onCancel={() => setShowGiftCardDetails(null)}
          title="Gift Card Details"
          footer={[
            <Button key="back" onClick={() => setShowGiftCardDetails(null)}>
              {strings.close}
            </Button>,
            <Popover title={strings.actions} content={giftCardMenu}>
              <Button type="primary">{strings.actions}</Button>
            </Popover>,
          ]}
        >
          {addGiftCardRecipient && (
            <AddCardRecipient
              card={showGiftCardDetails}
              visible={addGiftCardRecipient}
              onCancel={() => showAddGiftCardRecipient(false)}
              customer={customer}
              imageUrl={imageUrl}
            />
          )}

          {resendGiftCardRecipient && (
            <ResendCardRecipient
              card={showGiftCardDetails}
              visible={resendGiftCardRecipient}
              onCancel={() => showResendGiftCardRecipient(false)}
              customer={customer}
              imageUrl={imageUrl}
            />
          )}
          <div>
            <b style={{ marginBottom: 10 }}>
              Card Amount: {showGiftCardDetails.amount}
            </b>

            <div className="gift-card" ref={componentRef}>
              <div style={{ position: "absolute" }}>
                <div
                  className="card-number"
                  style={{
                    color:
                      getMerchant?.getMerchant.gift_card_template_text_color ||
                      "#fff",
                    marginTop: "35%",
                    marginLeft: "25%",
                    width: "100%",
                  }}
                >
                  <span>{showGiftCardDetails.card_number.slice(0, 4)}</span>
                  <span>{showGiftCardDetails.card_number.slice(4, 8)}</span>
                  <span>{showGiftCardDetails.card_number.slice(8, 12)}</span>
                  <span>{showGiftCardDetails.card_number.slice(12, 16)}</span>
                </div>
                <div
                  className="card-expiry"
                  style={{
                    color:
                      getMerchant?.getMerchant.gift_card_template_text_color ||
                      "#fff",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <span>
                    <b>VALID THRU</b>
                  </span>
                  <p>
                    <b style={{ marginLeft: 15 }}>
                      {" "}
                      {new Date(Number(showGiftCardDetails.expiry_date))
                        .toLocaleDateString()
                        .slice(3)
                        .slice(0, 3) +
                        new Date(Number(showGiftCardDetails.expiry_date))
                          .toLocaleDateString()
                          .slice(3)
                          .slice(5)}
                    </b>
                  </p>
                </div>
              </div>
              <img
                src={
                  getMerchant?.getMerchant.gift_card_template_image_url ||
                  "https://loystar.co/wp-content/uploads/2022/06/default_loystar_giftcard.png"
                }
                alt="card template"
              />
            </div>
            <p style={{ marginTop: 20 }}>
              Card Recipient:{" "}
              {showGiftCardDetails.recipient_name || "Not yet assigned"}
            </p>
            {showGiftCardDetails.recipient_email && (
              <p>
                <p>Recipient email: {showGiftCardDetails.recipient_email}</p>
                <p>
                  Recipient Phone Number:{" "}
                  {showGiftCardDetails.recipient_phone_number}
                </p>
                <p>Secret Code: {showGiftCardDetails.secret_code || "N/A"}</p>
              </p>
            )}
          </div>
        </Modal>
      )}
      <Modal
        title={strings.customerDetails}
        okText={strings.actions}
        cancelText={strings.cancel}
        open={showCustomerDetailModal}
        width={600}
        destroyOnClose
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {strings.close}
          </Button>,

          isAdminIsManager ? (
            <Popover title={strings.actions} content={actionsContent}>
              <Button type="primary">{strings.actions}</Button>
            </Popover>
          ) : (
            <Popover title={strings.actions} content={cashierActionsContent}>
              <Button type="primary">{strings.actions}</Button>
            </Popover>
          ),
        ]}
      >
        {isMembersLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Row>
            <Col md={14} sm={24}>
              <div>
                <p>
                  <strong>{strings.name}</strong>:{" "}
                  {`${first_name ? first_name : "-"} ${
                    last_name ? last_name : "-"
                  }`}
                </p>
                <p>
                  <strong>{strings.email}</strong>: {email || "-"}
                </p>
                <p>
                  <strong>{strings.dateOfBirth}</strong>: {date_of_birth || "-"}
                </p>
                <p>
                  <strong>{strings.sex}</strong>: {sex || "-"}
                </p>
                <p>
                  <strong>{strings.phoneNumber}</strong>: {phone_number || "-"}
                </p>
                <p>
                  <strong>{strings.address}</strong>: {address_line1 || "-"}
                </p>
                <p>
                  <strong>{strings.state}</strong>: {state || "-"}
                </p>
                <p>
                  <strong>{strings.country}</strong>: {country || "-"}
                </p>
                <p>
                  <strong>{strings.totalSpent}</strong>: {user.currency}
                  {numberWithCommas(
                    transactions
                      .map((transaction) => transaction.amount)
                      .reduce((a, b) => Number(a) + Number(b), 0)
                      .toFixed(2)
                  )}
                </p>
                <p>
                  <strong>{strings.loyaltyId}</strong>: {loyalty_id || "-"}
                </p>
                {isCustomerWalletActive && (
                  <p>
                    <strong>{strings.customerWalletBalance}</strong>:{" "}
                    {wallet && wallet.balance && user.currency}{" "}
                    {(wallet && wallet.balance) || "-"}
                  </p>
                )}
                {isCustomerWalletActive && (
                  <p>
                    <strong>{strings.allowOverdraft}</strong>:{" "}
                    {allow_overdraft ? "Yes" : "No"}
                  </p>
                )}
                {isCustomerWalletActive && (
                  <p>
                    <strong>{strings.customerOverdraftLimit}</strong>:{" "}
                    {wallet && wallet.overdraft_limit && user.currency}{" "}
                    {(wallet && wallet.overdraft_limit) || "-"}
                  </p>
                )}
                <p>
                  <strong>Gift Cards</strong>:{" "}
                  {listCardLoading ? (
                    <Spin style={{ marginLeft: "20px" }} />
                  ) : (
                    giftCardCustomers[customer.id]?.map((card) => (
                      <p style={{ marginLeft: 80 }}>
                        <Button onClick={() => setShowGiftCardDetails(card)}>
                          {card.card_number}
                        </Button>
                      </p>
                    ))
                  )}
                </p>

                {customer_custom_fields.length > 0 &&
                  customer_custom_fields.map((field) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <strong>{field.name}</strong>: {field.value}
                      </div>
                      <div>
                        <Icon
                          type="edit"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditField(field);
                            setEditCustomDataVisible(true);
                          }}
                        />
                        <Icon
                          type="delete"
                          style={{ cursor: "pointer", marginLeft: "20px" }}
                          onClick={() => handleDeleteField(field)}
                        />
                      </div>
                    </div>
                  ))}

                <div style={{ marginBottom: "1rem" }}>
                  <p style={{ marginBottom: "0" }}>
                    <strong>{strings.loyaltyBalance}</strong>:{" "}
                    {merchantLoyalties.length === 0 && "--"}
                  </p>

                  <Tree
                    showLine
                    switcherIcon={<Icon type="down" />}
                    defaultExpandedKeys={["0-0-0"]}
                  >
                    {merchantLoyalties.length > 0
                      ? merchantLoyalties.map((loyalty, key) => (
                          <TreeNode key={key} title={loyalty.program_name}>
                            <TreeNode
                              key={`${strings.loyaltyBalance}: - ${
                                loyalty.accumulated_points
                              }__${Math.random() * 1.93}`}
                              title={`${
                                loyalty.program_type === "SimplePoints"
                                  ? strings.pointsBalance
                                  : strings.starBalance
                              } - ${
                                loyalty.program_type === "SimplePoints"
                                  ? loyalty.accumulated_points + " Point(s)"
                                  : loyalty.accumulated_stamps + " Star(s)"
                              }`}
                            />

                            <TreeNode
                              key={`Threshold - ${loyalty.threshold}}__${
                                Math.random() * 1.93
                              }`}
                              title={`${
                                loyalty.program_type === "SimplePoints"
                                  ? strings.requiredPoints
                                  : strings.requiredStars
                              } - ${
                                loyalty.program_type === "SimplePoints"
                                  ? numberFormatter(loyalty.threshold) +
                                    " Points"
                                  : numberFormatter(loyalty.threshold) +
                                    " Stars"
                              }`}
                            />
                            <TreeNode
                              key={`Reward - ${loyalty.reward}}__${
                                Math.random() * 1.93
                              }`}
                              title={`Reward - ${loyalty.reward}`}
                            />
                          </TreeNode>
                        ))
                      : "--"}
                  </Tree>
                </div>

                <p>
                  <strong>{strings.reddemCode}</strong>: {token || "-"}
                </p>

                {isSubscribedToMultiLevel && multiLevelDetails}
              </div>
            </Col>
            {/* <Col md={10} sm={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <QRCode
                  crossorigin="anonymous"
                  size={200}
                  quietZone={20}
                  value={`${process.env.REACT_APP_UREWARDS_BASE_URL}/my/${phone_number}`}
                  enableCORS={true}
                  qrStyle="dots"
                  ecLevel="H"
                  eyeRadius={[
                    {
                      outer: [10, 10, 0, 10],
                      inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0],
                    [10, 0, 10, 10],
                  ]}
                />
              </div>
            </Col> */}
          </Row>
        )}
      </Modal>
      {openTransaction && (
        <Transactionmodal 
          open={openTransaction}
          onCancel={() => setOpenTransaction(false)}
          customer={customer}
          user={user}
          getCustomerSales={getCustomerSales}
        /> 
      )}
    </>
  );
};

const AssignLoyalty = ({
  loyaltyId,
  visible,
  onCancel,
  id,
  getAllCustomers,
  assignLoyaltyIdToCustomer,
  closeModal,
  pageNumber,
  pageSize,
  customerName,
}) => {
  const [selectedCodeId, setSelectedCodeId] = useState(null);

  const columns = [
    {
      title: "Loyalty Number",
      dataIndex: "code",
      key: "code",
      defaultSortOrder: "ascending",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "customer_id",
      render: (status) =>
        status === "used" ? (
          <Tag color="green">Assigned</Tag>
        ) : (
          <Tag color="red">Unassigned</Tag>
        ),
      filters: [
        {
          text: "Assigned",
          value: true,
        },
        {
          text: "Unassigned",
          value: false,
        },
      ],
      onFilter: (value, record) => !!record.status === value,
    },
  ];

  const assignIdToCustomer = (codeId = selectedCodeId) => {
    Modal.confirm({
      title: "Assign Loyalty ID",
      content: "Do you want to assign this loyalty ID to customer?",
      onOk: () => {
        let code = loyaltyId.find((one) => one.id === codeId);
        if (code && code.customer_id) {
          message.error("Code already assigned to a customer");
        } else
          return assignLoyaltyIdToCustomer({
            customer_id: id,
            loyalty_id: codeId,
          }).then((res) => {
            if (res.status === 200) {
              getAllCustomers(pageNumber, pageSize);
              Modal.success({
                title: `${strings.youveSuccessfullyAssignedLoyaltyMembership} - ${res.data.loyalty_code.code} to ${customerName}`,
                onOk: () => {
                  onCancel();
                  closeModal();
                },
              });
            }
          });
      },
    });
  };

  const onCodeSearch = (val) => setSelectedCodeId(val);

  return (
    <Modal
      title="Assign Loyalty ID"
      open={visible}
      onCancel={onCancel}
      onOk={onCancel}
    >
      {loyaltyId.length === 0 && (
        <div>
          <p>{strings.noMembershipIdAvailable}</p>
          <p>
            {" "}
            <a href="mailto: hello@loystar.co">{strings.sendEmail}</a>
          </p>
        </div>
      )}
      {loyaltyId.length > 0 && (
        <div>
          <Select
            allowClear
            showSearch
            value={selectedCodeId}
            placeholder="Search code"
            optionFilterProp="children"
            onChange={onCodeSearch}
            size="large"
            style={{ width: "80%" }}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            onDeselect={(e) => console.log(e)}
            disabled={false}
          >
            <Option value={null}>Search code</Option>
            {loyaltyId.map((loyaltyCode) => (
              <Option key={loyaltyCode.code} value={loyaltyCode.id}>
                {loyaltyCode.code}
              </Option>
            ))}
          </Select>
          {selectedCodeId && (
            <Button
              style={{ marginTop: "20px" }}
              type="primary"
              onClick={() => assignIdToCustomer()}
            >
              Assign Code
            </Button>
          )}

          <h4 style={{ marginTop: "20px" }}>Total IDs: {loyaltyId.length} </h4>

          <Table
            dataSource={loyaltyId}
            columns={columns}
            onRowClick={(val) => assignIdToCustomer(val.id)}
          />
        </div>
      )}
    </Modal>
  );
};

const AssignGiftCard = ({
  giftCards,
  visible,
  onCancel,
  customerId,
  customerName,
}) => {
  const [selectedCardNumber, setSelectedCardNumber] = useState(null);

  const columns = [
    {
      title: "Card Number",
      dataIndex: "card_number",
      key: "card_number",
      defaultSortOrder: "ascending",
      sorter: (a, b) => a.card_number - b.card_number,
    },
    {
      title: "Status",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (data) =>
        data ? (
          <Tag color="green">Assigned</Tag>
        ) : (
          <Tag color="red">Unassigned</Tag>
        ),
      filters: [
        {
          text: "Assigned",
          value: true,
        },
        {
          text: "Unassigned",
          value: false,
        },
      ],
      onFilter: (value, record) => !!record.status === value,
    },
  ];

  const [editCard] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        Modal.success({
          title: `You have successfully assigned gift card - ${data.editGiftCard.card_number} to ${customerName}`,
          onOk: () => {
            onCancel();
          },
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const saveCustomerGiftCard = (customerId, card) =>
    editCard({
      variables: { data: { id: card.id, customer_id: customerId } },
    });

  const assignCardToCustomer = (cardNumber = selectedCardNumber) => {
    Modal.confirm({
      title: "Assign Gift Card",
      content: "Do you want to assign this Gift Card to customer?",
      onOk: async () => {
        let card = giftCards.find((one) => one.card_number === cardNumber);
        if (card && card.customer_id) {
          return message.error("Gift card already assigned to a customer");
        }
        return saveCustomerGiftCard(customerId, card);
      },
    });
  };

  const onCodeSearch = (val) => setSelectedCardNumber(val);

  return (
    <Modal
      title="Assign Gift Card"
      open={visible}
      onCancel={onCancel}
      onOk={onCancel}
    >
      {!giftCards.length && (
        <div>
          <p>You have no gift cards available. Contact admin for support.</p>
          <p>
            {" "}
            <a href="mailto: hello@loystar.co">{strings.sendEmail}</a>
          </p>
        </div>
      )}
      {giftCards.length > 0 && (
        <div>
          <Select
            allowClear
            showSearch
            value={selectedCardNumber}
            placeholder="Search card number"
            optionFilterProp="children"
            onChange={onCodeSearch}
            size="large"
            style={{ width: "80%" }}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            onDeselect={(e) => console.log(e)}
            disabled={false}
          >
            <Option value={null}>Search card number</Option>
            {giftCards.map((card) => (
              <Option key={card.id} value={card.card_number}>
                {card.card_number}
              </Option>
            ))}
          </Select>
          {selectedCardNumber && (
            <Button
              style={{ marginTop: "20px" }}
              type="primary"
              onClick={() => assignCardToCustomer()}
            >
              Assign Gift Card
            </Button>
          )}
        </div>
      )}
      <h4 style={{ marginTop: "20px" }}>
        Total Gift Cards: {giftCards.length}{" "}
      </h4>

      <Table
        dataSource={giftCards}
        columns={columns}
        onRow={(val) => ({
          onClick: () => assignCardToCustomer(val.card_number),
        })}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  isSubscribedToMultiLevel: state.multiLevel.isSubscribed,
  products: state.product.allProducts,
  allSales: state.allSales,
  isCredPalActive: state.appStore.isCredPalActive,
  isCustomerWalletActive: state.appStore.isCustomerWalletActive,
});

export default connect(mapStateToProps, {
  assignLoyaltyIdToCustomer,
  getAllSavedSales,
  addNewProduct,
  getAllProducts,
  setCurrentUser,
  getAllCustomers,
  getCustomersLoyalty,
  createWallet,
  fundWallet,
  deleteWallet,
  fundWalletOverdraftLimit,
  getCustomerSales
})(CustomerDetails);
