import { Avatar, Card, DatePicker, message, Modal, Tag } from 'antd'
import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../../../../../components/CustomDataTable';
import strings from '../../../../../../strings';
import moment from 'moment';
import CartList from '../../../../../Sales/_partials/CartList';


const { RangePicker } = DatePicker;
const Transactionmodal = ({
    open,
    onCancel,
    customer,
    getCustomerSales,
    user
}) => {
    const [ customerTransactions, setCustomerTransaction ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ begin, setBegin ] = useState(null);
    const [ end, setEnd ] = useState(null);
    const [ openDetails, setOpenDetails ] = useState(false);
    const [ transaction, setTransaction ] = useState(null);

    useEffect(() => {
        if(customer) handleGetTransaction(customer.id)
    }, [customer]);

    const handleGetTransaction = (id, begin, end) => {
        setLoading(true);
        getCustomerSales(id, begin, end)
        .then(res => {
            if(res.status === 200 || res.status === 204){
                setLoading(false);
                // let transaction = [];
                // res.data.length > 0 && res.data.map(trans => {
                //     transaction.push(...trans.transactions)
                // })
                setCustomerTransaction(res.data)
            }
        })
        .catch(err => {
            console.log("err");
            message.error(err.response?.data.error ? err.response.data.error : `Unable to get transaction - ${err.message}`)
        })
    }

    const handleDurationChange = async (duration, ...rest) => {
        if (duration) {
            const [begin, end] = duration;
            setBegin(begin);
            setEnd(end);
            handleGetTransaction(
                customer.id, 
                begin.format().split("T")[0],
                end.format().split("T")[0])
        } else if (!duration) {
            handleGetTransaction(customer.id);
        }
    };

    const openTransactionDetails = (transaction) => {
      setTransaction(transaction);
      setOpenDetails(true); 
    };

    function getPaymentType(transaction) {   
      const paymentTypes = {
        is_paid_with_card: "Card",
        is_paid_with_cash: "Cash",
        is_paid_with_point: "Points",
        is_paid_with_mtransfer: "Bank Transfer",
        is_paid_with_mobile: "Bank Transfer (USSD)",
        is_paid_with_customer_account: "Wallet"
      };
      let paymentType = "";

      Object.keys(paymentTypes).map((item, index) => {
        if(transaction[item]) paymentType = Object.values(paymentTypes)[index];
      });

      return paymentType;
    }

    const columns = [
        {
          title: strings.transactionId,
          dataIndex: "id",
          key: "id",
          // render: (product_picture) => (
          //   <div className="image">
          //       <Avatar size="small" shape="square" src={product_picture} alt="" />
          //   </div>
          // ),
        },
        // {
        //   title: strings.name,
        //   dataIndex: "transactions",
        //   key: "product_name",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{tran.product_name}</p>
        //     ))
        //   ),
        // },
        // {
        //   title: strings.quantity,
        //   dataIndex: "transactions",
        //   key: "product_quantity",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{(tran.product_quantity)}</p>
        //     ))
        //   ),
        //   // defaultSortOrder: "ascend",
        //   // sorter: (a, b) => Number(b.amount) - Number(a.amount),
        //   // render: (qty) => Number(qty).toFixed(2),
        // },
        // {
        //   title: strings.amount,
        //   dataIndex: "transactions",
        //   key: "5",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{user.currency} {tran.amount}</p>
        //     ))
        //   ),
        //   // sorter: (a, b) => Number(b.amount) - Number(a.amount),
        //   // render: (qty) => Number(qty).toFixed(2),
        // },
        {
          title: strings.total,
          dataIndex: "total",
          key: "amount",
          // render: (transaction) => (
          //   transaction.map(tran => (
          //      <p style={{margin: 0, fontSize: 12}}>{user.currency} {Number(tran.amount).toFixed(2)}</p>
          //   ))
          // ),
          sorter: (a, b) => Number(b.amount) - Number(a.amount),
          render: (qty) => Number(qty).toFixed(2),
        },
        {
          title: strings.netTax,
          dataIndex: "net_vat",
          key: "net_vat",
          render: (record, value) => {
            return <span>{Number(record).toFixed(2)}</span>;
          },
          sorter: (a, b) => {
            return Number(a) - Number(b);
          },
        },
        {
          title: strings.paymentType,
          dataIndex: "paymentType",
          render: (record, details) => (details ? <span>
                {getPaymentType(details)}
              {details.payment_label && <Tag className="ml-1" color="green">
                          {details.payment_label.label_name}
                        </Tag>}
              </span> : <span>N/A</span>),
          onFilter: (value, record) => {
            // return String(record.paymentType).indexOf(value) === 0;
            return String(record.paymentType).toLowerCase().includes(value.toLowerCase());
          },//todaysReport ? null :
        },
         {
          title: strings.branch,
          dataIndex: "business_branch",
          render: (record) => (record ? <span>{record.name}</span> : <span>Home Branch</span>),
          // className: !this.props.handleBranchSelect || this.props.user.business_branch ? "show" : "hide"
        },
        {
          title: strings.createdDate,
          dataIndex: "created_at",
          defaultSortOrder: "ascend",
          sorter: (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
          key: Math.random(),
          render: (record) =>
            record ? (
              <span>{moment(record).format("YYYY-MM-DD")}</span>
            ) : (
              <span>N/A</span>
            ),
        },
        {
          title: strings.createdTime,
          dataIndex: "created_at",
          key: Math.random(),
          defaultSortOrder: "ascend",
          sorter: (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
          render: (record) =>
            record ? (
              <span>{moment(record).format("hh:mm:ss A")}</span>
            ) : (
              <span>N/A</span>
            ),
        },
         {
          title: strings.servedBy,
          dataIndex: "staff",
          render: (record) => (record ? <span>{record && record.username }</span> : <span>{strings.admin}</span>),
          // ...this.getColumnSearchProps("staff"),
        },
    ];
  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        title={`${customer && customer.first_name}'s transactions`}
        width={800}
    >
         <Card
            loading={loading}
            extra={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
                  <RangePicker
                    placeholder={[strings.startDate, strings.endDate]}
                    onChange={handleDurationChange}
                    allowClear="false"
                  />
                </div>
              }
        >
            <CustomDataTable 
                columns={columns}
                dataSource={customerTransactions}
                loading={loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                          openTransactionDetails(record);
                        },
                    };
                }}
            // rowSelection={rowSelection}

            />
      </Card>
    </Modal>

    {openDetails && <Modal 
      open={openDetails}
      onCancel={() => setOpenDetails(false)}
    >
      <div>
              <p>
                <strong>{strings.paymentType}</strong>: {getPaymentType(transaction) || "-"}
                {transaction.payment_label && <Tag className="ml-1" color="green">
                  {transaction.payment_label.label_name}
                </Tag>}
              </p>
               <p>
                  <strong>{strings.paymentReference}</strong>:{" "}
                  {transaction.payment_reference || (transaction.invoice_id && `INV-${transaction.invoice_id}`) || "-"}
                </p>
               <p>
                  <strong>{strings.servedBy}</strong>:{" "}
                  {transaction.staff && transaction.staff && transaction.staff.username || strings.admin}
                </p>
               <p>
                  <strong>{strings.products}</strong>
                </p>
                <div className="cart details-cart">
                  {transaction.transactions && transaction.transactions.length && (
                    <CartList
                      data={transaction.transactions}
                      key={transaction}
                      increaseItemQuantity={null}
              removeItemFromCart={null}
              reduceItemFromCart={null}
              handleShowBulkModal={null}
              />
                  )}
                </div>
                <p>
                  <strong>{strings.transDate}</strong>: {transaction.created_at && moment(transaction.created_at).format(
                      "DD-MM-YYYY hh:mm a"
                    )}
                </p>
                <p>
                  <strong>{strings.total}</strong>: {transaction.total && user.currency}
                  {transaction.total || "0"}
                </p>
              <p>
                <strong>{strings.profit}</strong>: {transaction.total_profit}
              </p>
              
            </div>
    </Modal>}
    </>
    
  )
}

export default Transactionmodal