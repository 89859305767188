import React, { useEffect, useState } from "react";
import { Button, Card, message, Breadcrumb } from "antd";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import CustomTable from "../../../../../../../../components/CustomDataTable";
import { LIST_TIERS } from "../../constants";
import CreateTierModal from "./_partials/CreateTierModal";
import { tierColumns } from "./functions";
import { Link } from "react-router-dom";
import ViewMembersModal from "./_partials/ViewMembersModal";
import EditTierModal from "./_partials/EditTierModal";
import DeleteTierModal from "./_partials/DeleteTierModal";
import TierDetailModal from "./_partials/TierDetailModal";
import strings from "../../../../../../../../strings";

const Tier = () => {
  const [isCreateTierModalOpen, setIsCreateTierModalOpen] = useState(false);
  const [isDeleteTierModalOpen, setIsDeleteTierModalOpen] = useState(false);
  const [isEditTierModalOpen, setIsEditTierModalOpen] = useState(false);
  const [isTierDetailModalOpen, setIsTierDetailModalOpen] = useState(false);

  const [isViewMembersModalOpen, setIsViewMembersModalOpen] = useState(false);
  const [currentTier, setCurrentTier] = useState(null);

  const { params, path } = useRouteMatch();

  const loyalty_id = params.id;
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.isFromCreateLoyalty) {
      toggleCreateTierModal();
    }
  }, [location]);

  const { loading, data, refetch } = useQuery(LIST_TIERS, {
    variables: { loyalty_id: loyalty_id },
    onError: error => {
      message.error(error.message, 5);
    },
  });

  const toggleViewMembersModal = () => {
    setIsViewMembersModalOpen(open => !open);
  };

  const toggleCreateTierModal = () => setIsCreateTierModalOpen(open => !open);
  const toggleTierDetailModal = () => setIsTierDetailModalOpen(open => !open);
  const toggleEditTierModal = () => setIsEditTierModalOpen(open => !open);
  const toggleDeleteTierModal = () => setIsDeleteTierModalOpen(open => !open);

  const tierList = data ? data.listTiers : [];

  const loyaltyUrl = path.split("/:id")[0];

  const columns = tierColumns(toggleViewMembersModal);

  const is_owner = localStorage.getItem("is_owner") === "false" ? false : true;

  return (
    <section className="loyalty-tier">
      <Card
        title={strings.availableTiersInLoyalty}
        extra={
          <Button
            onClick={() => toggleCreateTierModal()}
            type="primary"
            disabled={!is_owner}
          >
            {strings.createTier}
          </Button>
        }
      >
        {/* <div className="no-loyalty-info">
          <p>
            You Have No Loyalty Program Running, click on the button below to
            create a Loyalty.
          </p>

          <Button type="primary">Create Loyalty</Button>
        </div> */}

        <Breadcrumb className="page_breadcrumb">
          <Breadcrumb.Item>{strings.home}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${loyaltyUrl}`}>{strings.loyalty}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="*">{strings.tiers}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="customers-table mt-40">
          <h6>
            {strings.totalTier}: {tierList.length}
          </h6>
          <CustomTable
            columns={columns}
            dataSource={
              data &&
              data.listTiers
                .map(item => {
                  return {
                    ...item,
                    key: item.id,
                    nameOfTier: item.name,
                    customersEnrolled: item.customers.length,
                    min_spending_target: item.min_spending_target,
                    max_spending_target: item.max_spending_target,
                    reward: item.reward,
                    created_at: item.created_at,
                  };
                })
                .sort((a, b) => b.created_at - a.created_at)
            }
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setCurrentTier(record);
                  toggleTierDetailModal();
                },
              };
            }}
          />
        </div>
      </Card>

      {isCreateTierModalOpen ? (
        <CreateTierModal
          visible={isCreateTierModalOpen}
          closeModal={() => {
            toggleCreateTierModal();
            refetch();
          }}
          tierList={tierList}
          loyalty_id={loyalty_id}
        />
      ) : null}

      {isEditTierModalOpen ? (
        <EditTierModal
          visible={isEditTierModalOpen}
          closeModal={toggleEditTierModal}
          currentTier={currentTier}
          loyalty_id={loyalty_id}
        />
      ) : null}

      {isDeleteTierModalOpen ? (
        <DeleteTierModal
          visible={isDeleteTierModalOpen}
          closeModal={toggleDeleteTierModal}
          tierId={currentTier.id}
          loyalty_id={loyalty_id}
        />
      ) : null}

      {isTierDetailModalOpen ? (
        <TierDetailModal
          visible={isTierDetailModalOpen}
          closeModal={toggleTierDetailModal}
          currentTier={currentTier}
          toggleEditTierModal={toggleEditTierModal}
          toggleDeleteTierModal={toggleDeleteTierModal}
          toggleViewMembers={toggleViewMembersModal}
          is_owner={is_owner}
        />
      ) : null}

      {isViewMembersModalOpen ? (
        <ViewMembersModal
          visible={isViewMembersModalOpen}
          closeModal={toggleViewMembersModal}
          currentTier={currentTier}
          loyalty_id={loyalty_id}
        />
      ) : null}
    </section>
  );
};

export default Tier;
