import React, { useState, useEffect} from "react";
import { Input, Modal, Button, Badge, message } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { clearShowVariantModalData } from "../../../../redux/actions/saleActions"

// Redux
import { connect } from "react-redux";

import "./index.scss";
import strings from "../../../../strings";

const VariantsModal2 = ({
    showVariantModal, 
    toggleModal, 
    product, 
    form,
    addNewCategory,
    getAllCategories, 
    currency,
    onClick,
    clearShowVariantModalData,
    handleBlurFactor
  })=> {
        
    const FormItem = Form.Item;
    const [ loading, setLoading ] = useState(false);
    const [ selectedVariants, setSelectedVariants ] = useState({});
    const [ groupedVariants, setGroupedVariants ] = useState({});
    const [ quantity, setQuantity ] = useState(1.0);
    const [ modalIsUp, setModalIsUp ] = useState(false);
    const [ tryUpdate, setTryUpdate ] = useState(false);
    const [ trackChange, setTrackChange ] = useState(false);
    const [ isSelected, setIsSelected ]= useState({})
    const { getFieldDecorator } = form;

    
    useEffect(()=>{
        form.setFieldsValue({ quantity: quantity });
    }, [quantity]);

    useEffect(()=>{
        const groupedVariants = {};
        const selectVariantsObj = {};
        product.variants.forEach(variant => {
            if (groupedVariants[variant.type]) {
                groupedVariants[variant.type].push(variant);
            } else {
                selectVariantsObj[variant.type] = [];
                groupedVariants[variant.type] = [variant];
            }
        });
        setSelectedVariants(selectVariantsObj);
        setGroupedVariants(groupedVariants);
        setModalIsUp(true);
        handleBlurFactor(false);
    }, [product])

    useEffect(()=>{
        setSelectedVariants(selectedVariants);

    }, [selectedVariants])

    useEffect(()=>{
        const groupedVariants = {};
        const selectVariantsObj = {};
        product.variants.forEach(variant => {
            if (groupedVariants[variant.type]) {
                groupedVariants[variant.type].push(variant);
            } else {
                selectVariantsObj[variant.type] = [];
                groupedVariants[variant.type] = [variant];
            }
        });
        setSelectedVariants(selectVariantsObj);
        setGroupedVariants(groupedVariants);
        setModalIsUp(true);
    }, [])

    const handleAddCategory = () => {
        // const { form, addNewCategory, getAllCategories, toggleModal } = this.props;
    
        form.validateFields((err, values) => {
            if (err) return;
    
            setLoading(true);
            addNewCategory(values).then(res => {
            if (res && res.status === 200) {
                form.resetFields();
                setLoading(false)
                getAllCategories();
    
                Modal.success({
                title: strings.youveSuccessfullyAddedANewCategory,
                });
                toggleModal();
            }
            });
        });
    };

    const getSelectedVariants = () => {
        let variants = [];
        Object.keys(selectedVariants).forEach(variant => {
        variants = [...variants, ...selectedVariants[variant]];
        });
        return variants;
    };

    const inStock = quantity => {
    let variants = getSelectedVariants();
    let result = true;
    let outOfStockVariant = {};
    try {
      variants.forEach(variant => {
        if (variant.quantityLeft) {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        } else {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        }
      });
    } catch (error) {
      return [result, outOfStockVariant];
    }
    return [result, outOfStockVariant];
  };

    const changeQuantity = (type) => {
    const variants = getSelectedVariants();
    if (variants.length > 0) {
      if (type === "plus") {
        if(quantity > Number(variants[0].quantity)){
            Modal.warning({
                title: `${strings.lowStockFor} ${variants[0].type}: ${variants[0].value}`,
            });
            return;
        }
        setQuantity(quantity+1);
        form.setFieldsValue({ quantity: quantity });
      } else if (type === "minus") {
        if (quantity <= 1) {
          return;
        }
        setQuantity(quantity-1);
      } else {
        if (!isNaN(type) && Number(type) >= 0) {
          setQuantity(Number(type));
          form.setFieldsValue({ quantity: quantity });
        } else {
          Modal.warning({
            title: strings.pleaseSupplyPositiveQuantityNumber,
          });
        }
      }
    } else {
      Modal.warning({
        title: strings.pleaseSelectAVariant,
      });
    }
  };

    const selectVariant = (variant, key) => {
        setQuantity(1)
        const keys = Object.keys(selectedVariants);
        let check = false;
        keys.forEach(selectedKey => {
          if (selectedVariants[selectedKey].length > 0 && key !== selectedKey) {
            if (selectedVariants[selectedKey][0].price !== variant.price) {
              check = true;
            }
          }
        });
        if (check) {
          Modal.warning({
            title: strings.thePreviousVariantSelectionNotAvailable,
          });
          return;
        }
        selectedVariants[key] = [variant];

        setIsSelected(variant)
        setSelectedVariants(selectedVariants);
    };

    const clearSelectedVariants = () => {
        Object.keys(selectedVariants).forEach(key => {
            selectedVariants[key] = [];
        });

        setSelectedVariants(selectedVariants);
        setQuantity(1);
        setIsSelected({})
        form.setFieldsValue({ quantity: quantity });
    };

    const handleOnClick = () => {
        let variants = getSelectedVariants();

        let productCopy = { ...product };

        delete productCopy.variants;
        const item = { ...productCopy, variants };

        item.variants = variants;
        item.quantity = quantity;    
        
        if(Number(variants[0].quantity) < Number(quantity)) return message.warning(strings.productOutOfStock);
        

        if (variants.length === 0) {
          Modal.warning({
            title: strings.pleaseSelectAVariant,
          });
        } else {
          onClick(item, "single");
          form.setFieldsValue({ quantity: quantity });
          setModalIsUp(true);
          setQuantity(1.0);
          setIsSelected({})
          clearShowVariantModalData();
        }

        handleBlurFactor(true);
    };

    const displayVariants = () => {
        const variants = groupedVariants;
        const keys = Object.keys(variants);
        return keys.map(key => {
          return (
            <div key={key}>
              <span className="type">{`${key}:`}</span>
              <div className="variant-wrapper">
                {variants[key].map(variant => {
                  return (
                    <Badge
                      key={variant.id}
                      count={variant.quantity}
                    //   className={
                    //     selectedVariants[key].indexOf(variant) >= 0
                    //       ? strings.variantSelected
                    //       : strings.variant
                    //   }
                      className={
                        isSelected?.id === variant.id
                          ? strings.variantSelected
                          : strings.variant
                      }
                      onClick={() => {
                        selectVariant(variant, key);
                      }}
                    >
                      <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                      <span style={{ paddingRight: "10px" }}>-</span>
                      <span>
                        {currency}
                        {product.tax_rate
                          ? (
                              (product.tax_rate / 100) * variant.price +
                              parseFloat(variant.price)
                            ).toFixed(2)
                          : variant.price}
                      </span>
                    </Badge>
                  );
                })}
              </div>
            </div>
          );
        });
      };

    return (
        <Modal
            title={`${product.name} ${product.price}`}
            okText={strings.add}
            cancelText={strings.cancel}
            onOk={handleOnClick}
            onCancel={()=>{
              handleBlurFactor(true);
              toggleModal();
            }}
            destoryOnClose
            open={showVariantModal}
            okButtonProps={{ loading: loading }}
        >
            <div className="variants">
              <span>Variants:</span>
              <Button
                  htmlType="button"
                  className="clear-button"
                  disabled={!(getSelectedVariants().length > 0)}
                  onClick={clearSelectedVariants}
              >
                  {strings.clearVariants}
            </Button>
              <div>{displayVariants()}</div>
            </div>

            <Form layout="vertical" >
            <span>{strings.quantity}:</span>
            <div className="variant_quantity_container">
                <div>
                <Button
                    htmlType="button"
                    onClick={() => {
                        changeQuantity("minus");
                    }}
                >
                    -
                </Button>
                </div>
                <FormItem label="">
                {getFieldDecorator("quantity", {
                    rules: [
                    {
                        required: true,
                        message: strings.pleaseEnterQuantity,
                    },
                    ],
                })(
                    <Input
                    size="large"
                    onChange={(e) => {
                        const variants = getSelectedVariants();
                        if(variants.length === 0 ){
                            Modal.warning({
                                title: strings.pleaseSelectAVariant,
                            });
                            return;
                        }
                        setQuantity(e.target.value)
                        form.setFieldsValue({ quantity: quantity });
                    }}
                    className="variant_input center"
                    type="number"
                    />
                )}
                </FormItem>
                <div>
                <Button
                    htmlType="button"
                    onClick={() => {
                        changeQuantity("plus");
                    }}
                >
                    +
                </Button>
                </div>
            </div>
            </Form>
        </Modal>
    );
}


VariantsModal2.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  sales: PropTypes.object.isRequired,
}; 
const VariantsModalForm = Form.create()(VariantsModal2);

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  branchProducts: state.product.branchProducts,
});

export default connect(mapStateToProps, {clearShowVariantModalData})(VariantsModalForm);
