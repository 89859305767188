import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Spin } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_MERCHANT_SENDERID, UPDATE_MERCHANT_SENDERID } from '../../../Apps/MultiLevelLoyalty/Loyalties/constants';
import { WhatsAppOutlined, MailOutlined, MessageOutlined } from "@ant-design/icons";
import { Icon } from "@ant-design/compatible";
import strings from '../../../../../../strings';

const ShopLinkModal = ({ open, onCancel, user }) => {
  const [ urewardsContact, setUrewardsContact] = useState("");
  const [ isEditShopLink, setIsEditShopLink ] = useState(false);
  const [ senderID, setSenderID ] = useState("");

  const [ 
    updateID, 
      { 
        loading: sendLoading,  
        refetch: refetc 
      } 
  ] = useLazyQuery(UPDATE_MERCHANT_SENDERID);



  const { loading: getLinkLoading, refetch } = useQuery(GET_MERCHANT_SENDERID,
  {
    variables: {merchant_id: user.id},
    onCompleted: (data) => {
      if( data.getMerchantSenderId !== null || data.getMerchantSenderId !== undefined){
        setUrewardsContact(data.getMerchantSenderId.uRewardsId);
      }else{
        setUrewardsContact("");
      }
    },
    onError: (err) => {
      setUrewardsContact("");
      Modal.error({
        title: `${err.message}`
      })
    }
  })

  useEffect(() => {
    refetch();
  }, [user])

  const handleUpdateSenderId = async ( uRewardsId ) => {
    if(senderID === "" || senderID === null ){
        return Modal.info({
            title: "Please input value"
        });
    }

    if(senderID.length < 4){
      return Modal.info({
        title: "Shop link should be more than four characters"
      });
    }

    // if(data !== undefined && getLinkLoading === false){
    //   refetc();
    // }
    updateID({
      variables: {uRewardsId: senderID.toLowerCase(), merchant_id: user.id},
      onCompleted: (data) => {

        setUrewardsContact(data.updateMerchantSenderId.uRewardsId);

        setIsEditShopLink(false);
        setSenderID("");

        Modal.success({
          title: "Shop link URL updated successfully",
          content: 
            <div>
              Shop link URL updated successfully, Your new Loystar Shop link is <br/>
              <a 
                href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`} 
                target="_blank"
                rel="nonreferrer"
              >{process.env.REACT_APP_UREWARDS_BASE_URL}/{data.updateMerchantSenderId.uRewardsId}
              </a> 
              <p style={{margin: "0"}}>Share via: </p>
              <p style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem"
              }}>
                <a  
                  href= {`whatsapp://send?text=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                  data-action="share/whatsapp/share"
                  target="_blank" 
                  rel="noreferrer"
                >
                  <WhatsAppOutlined style={{scale: "1.5"}}/>
                </a>
                <a
                  href={`mailto:?subject=Feedback&body=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                  target="_blank"
                  rel="noreferrer"
                ><MailOutlined style={{scale: "1.5"}}/></a>
                <a
                  href={`sms: ?body=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                  target="_blank"
                  rel="noreferrer"
                ><MessageOutlined style={{scale: "1.5"}}/></a>
              </p>
            </div> 
        })
      },
      onError: (error) => {
        setIsEditShopLink(false);
        setSenderID("");
        Modal.error({
          title: `${error.message}`
        })
      }
    });
  
  }

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title="Shop Link"
        footer={null}
    >
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem"
      }}>
        {isEditShopLink && (
          <p style={{margin: 0}}> 
          {`${process.env.REACT_APP_UREWARDS_BASE_URL}`}
          <Input 
            placeholder={urewardsContact}
            value={senderID}
            onChange={(e)=>setSenderID(e.target.value)}
            autoFocus
          />
          </p> 
        )}
        {!isEditShopLink && (
          <p style={{margin: "0"}}>
            {getLinkLoading 
            ? <Spin /> 
            : urewardsContact ? <a 
              href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`} 
              target="_blank"
            >
              {`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact || ""}`}
            </a> : <span>{strings.youAreYetToHaveAShopLink}</span>
            }
          </p>
        )}
        
        {isEditShopLink && (
          <Button 
            onClick={()=>handleUpdateSenderId(urewardsContact, senderID)}
            type="primary"
            loading={sendLoading}
          >
            Update 
          </Button> 
        )}
        {!isEditShopLink && (
          <span>
          <Icon 
            type="edit" 
            onClick={()=>setIsEditShopLink(true)}
            />
        </span> 
        )}
      </div>
    </Modal>
  )
}

export default ShopLinkModal;