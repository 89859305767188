import React, { useState, useEffect} from 'react';
import strings from '../../../../../../strings';
import { Modal, InputNumber, Button, Popover } from "antd";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Form } from "@ant-design/compatible";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from '../../../../../../components/StripePayment/CheckoutForm';
import { Elements } from "@stripe/react-stripe-js"
import { usePaystackPayment } from 'react-paystack';



const WhatsAppTopUpModal = ({ 
    form, 
    user,
    updateBalanceLoading,
    whatsAppWalletID,
    setUpdateBalanceLoading,
    getWhatsAppId 
}) => {
    const [ amount_inputed, setAmountInputed ] = useState(null);
    const [ isStripeLoading, setIsStripeLoading ] = useState(false);
    const [ message, setMessage ] = useState(null);
    const [ clientSecret, setClientSecret ] = useState(null);
    const [ checkoutFormOpen, setCheckoutFormOpen ] = useState(false);
    // const [ isStripeLoading, setIsStripeLoading ] = useState(false);
    const { getFieldDecorator, validateFields, resetFields } = form;
    const api_key = process.env.REACT_APP_WHATSAPP_API_KEY;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const clientKey = process.env.REACT_APP_STRIPE_CLIENT_KEY;
    const stripePromise = loadStripe(clientKey)
    // const stripe = useStripe();
    // const elements = useElements();


    useEffect(()=>{
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        const isClientSecret = localStorage.getItem("client_stripe_secret");
        const isAmountInputed = localStorage.getItem("amount_inputed");

        if(!clientSecret) return;
        if(!isClientSecret) return;
        if(!isAmountInputed) return;
        // console.log(isClientSecret, clientSecret, isAmountInputed);
        setClientSecret(JSON.parse(isClientSecret));
        setAmountInputed(JSON.parse(isAmountInputed));
        setCheckoutFormOpen(true);
    }, []);

    const getClientSecret = async () => {

        const data = {
            amount: amount_inputed * 100,
            currency: user.currency.toLowerCase()
        }
        // ${REACT_APP_MULTI_LEVEL_BASE_URL}
        setIsStripeLoading(true);
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/create-payment-intent`, data , {headers: {
            ["Access-Control-Allow-Origin"]: "*",
            ["multi-level-token"]: "",
            ["user-auth"]: "",
        }})
        .then((res)=>{
            if(res.status === 200){
                setIsStripeLoading(false);
                setClientSecret(res.data.clientSecret);
                setCheckoutFormOpen(true);
                localStorage.setItem("client_stripe_secret", JSON.stringify(res.data.clientSecret));
                localStorage.setItem("amount_inputed", JSON.stringify(amount_inputed))
            }
        })
        .catch((err)=>{
            setIsStripeLoading(false);
            message.error(err.message);
            console.log(err);
        })
    }

    const handlePayment = async () => {

        const data = {
            currency: user.currency,
            amount_inputed
        }

        const walledId = localStorage.getItem("whatsAppWalletID");
        const walletID = whatsAppWalletID ? whatsAppWalletID : JSON.parse(walledId);

        await axios.post(
            `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/whatsapp_wallet_topup/${walletID}`, 
            data,
            {
                headers: {
                    api_key,
                    client: '',
                    ["access-token"]: "",
                    ["multi-level-token"]: "",
                    ["user-auth"]: "",
                    ["Access-Control-Allow-Origin"]: "*"
                }
            }
        )
        .then((res)=>{
            Modal.success({
                title: "Wallet topup successful",
                onOk: () => {
                    setUpdateBalanceLoading(false);
                    getWhatsAppId();
                    resetFields();
                    setCheckoutFormOpen(false);
                    setClientSecret(null);
                    setAmountInputed(null);
                    localStorage.removeItem("amount_inputed");
                    localStorage.removeItem("client_stripe_secret");
                }
            });
        })
        .catch((err)=>{
            Modal.error({
                title: `unable to topup wallet balance, ${err.message}`,
                onOk: () => {
                    setUpdateBalanceLoading(false);
                }
            });
        })
    }

    const FLUTTERWAVE_PUBLIC_KEY = process.env.REACT_APP_FLUTTERWAVE_KEY;
    const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_KEY;

    const config = {
        public_key: FLUTTERWAVE_PUBLIC_KEY,
        tx_ref: Date.now(),
        amount: amount_inputed,
        currency: user.currency,
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: user.email,
            phonenumber: user.contact_number,
            name: `${user.first_name} ${user.last_name}`,
        },
    };

    const paystackConfig = {
        reference: new Date().getTime().toString(),
        email: user.email,
        currency: user.currency,
        amount: Number(amount_inputed) * 100,
        // amount:
        //   user.currency === "USD" || user.currency === "GBP"
        //     ? ((Number(total) * noOfMonth * 100) / nairaDollarRate).toFixed(2)
        //     : Number(total) * noOfMonth * 100,
        publicKey: PAYSTACK_PUBLIC_KEY,
    };

    const paymentCallback = (response) => {
        closePaymentModal();

        handlePayment();
    };

    const handleFlutterPayment = useFlutterwave(config);
     const handlePaystackPayment = usePaystackPayment(paystackConfig);

    const africanCurrency = [
        "DZD",
        "AOA",
        "XOF",
        "BWP",
        "NOK",
        "XOF",
        "BIF",
        "XAF",
        "CVE",
        "KMF",
        "CDF",
        "DJF",
        "EGP",
        "ERN",
        "ETB",
        "GMD",
        "GHS",
        "GNF",
        "KES",
        "LSL",
        "LRD",
        "LYD",
        "MGA",
        "MWK",
        "MRO",
        "MUR",
        "MAD",
        "MZN",
        "NAD",
        "NGN",
        "RWF",
        "SCR",
        "SLL",
        "SOS",
        "ZAR",
        "SDG",
        "SZL",
        "TZS",
        "TND",
        "UGX",
        "ZMK",
        "ZWL"
    ];

    const handlePayFlutterWave = () => {
        validateFields((errors, values) => {
            if (errors) return;
      
            setAmountInputed(values.amount_inputed);
      
            // handlePaystackPayment({
            //   callback: paymentCallback,
            //   onClose: () => {},
            // });
            handlePaystackPayment(paymentCallback, () => {});
        });
    }

    const options = {
        clientSecret: clientSecret && clientSecret
    }
    
    const ifAfricanCurrency = africanCurrency.includes(user.currency);
    // console.log(ifAfricanCurrency);
    // const actionsContent = (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //         {ifAfricanCurrency && <Button
    //             key="flutterwave"
    //             onClick={() => handlePayFlutterWave()}
    //             loading={updateBalanceLoading}
    //         >
    //             {strings.payFlutterWave}
    //         </Button>}
    //         {<Button
    //                 key="stripe"
    //                 onClick={() => getClientSecret()}
    //                 loading={isStripeLoading}
    //                 disabled={amount_inputed === null}
    //             >
    //                 {strings.payStripe}
    //             </Button>}
    //     </div>
    // );
  return (
    <>
    <Form layout="vertical">
      <Form.Item label={strings.enterNumberOfWhatsAppUnits}>
        {getFieldDecorator("amount_inputed", {
          rules: [
            {
              required: true,
              type: "number",
              message: strings.pleaseEnterANumericValue,
            },
          ],
        })(<InputNumber className="sms_topup_input" size="large"  onChange={(e)=>setAmountInputed(e)}/>)}
      </Form.Item>

      <Button 
        type="primary"
        onClick={()=>{
            ifAfricanCurrency 
                ? handlePayFlutterWave() 
                    : getClientSecret()
        }}
        loading={ifAfricanCurrency ? updateBalanceLoading : isStripeLoading}
        disabled={!amount_inputed}
      >
        {/* <Popover key="actions" title="Actions" content={actionsContent} > */}
            {strings.pay}
        {/* </Popover> */}
      </Button>
    </Form>

    {clientSecret && checkoutFormOpen &&
        <Elements options={options} stripe={stripePromise}>
            <CheckoutForm 
                open={checkoutFormOpen}
                onCancel={()=>setCheckoutFormOpen(false)}
                handlePayment={handlePayment}
                updateBalanceLoading={updateBalanceLoading}
                fromStripeSubscription={false}
            />
        </Elements>
    }
    </>
  )
}

const WhatsAppTopUpModalForm = Form.create()(WhatsAppTopUpModal);

export default WhatsAppTopUpModalForm;