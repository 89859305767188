import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Card,
  notification,
  Dropdown,
  Menu,
  message,
} from "antd";
import { useLocation } from "react-router-dom";
import { Icon } from "@ant-design/compatible";
import { 
  PlusOutlined, 
  SearchOutlined, 
  DeleteOutlined, 
  UserOutlined,
  HeartOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
// import { getTableColumns } from "./functions";

import { getAllCustomers, searchForCustomer, sendSmsToCustomerGroup } from "../../../../redux/actions/customerActions";
import { getAllLoyaltyPrograms } from "../../../../redux/actions/loyaltyActions";
import CustomDataTable from "../../../../components/CustomDataTable";
import AddCustomerModal from "./_partials/AddCustomerModal";
import EditCustomerModal from "./_partials/EditCustomerModal";
import DeleteCustomerModal from "./_partials/DeleteCustomerModal";
import ShortSMSModalForm from "./_partials/ShortSMSModal";
import { reconcileDataToState } from "../../../../utils";
import CustomerDetails from "./_partials/CustomerDetails";
import RedeemReward from "./_partials/RedeemReward";
import CustomerGroups from "./_partials/CustomerGroups";
import BulkDeleteCustomerModal from "./_partials/bulkDelete";

import "./index.scss";
import ImportCustomerModal from "./_partials/ImportCustomerModal";
import MenuItem from "antd/lib/menu/MenuItem";
import api from "../../../../api";
import WithApolloClient from "../../../../utils/withApolloClient";
import {
  LIST_CUSTOMERS,
  LIST_LOYALTIES,
} from "../Apps/MultiLevelLoyalty/Loyalties/constants";
import AwardPointsModal from "../Apps/MultiLevelLoyalty/Loyalties/_partials/EnrollMembers/AwardPointsModal";
import strings from "../../../../strings";
import BirthDayModal from "./_partials/BirthDayModal";
import { getCustomerSales } from "../../../../redux/actions/saleActions";
import Transactionmodal from "./_partials/TransactionModal";


const Customers = ({
  isSubscribedToMultiLevel,
  getAllLoyaltyPrograms,
  // location,
  client,
  customers, 
  allLoyaltyPrograms, 
  user,
  getAllCustomers,
  searchForCustomer,
  getCustomerSales,
  sendSmsToCustomerGroup
}) => {
  const location = useLocation();
  const [ loading, setLoading ] = useState(false);
  const [ sms_balance, setSms_balance ] = useState(null);
  const [ showSMSModal, setShowSMSModal ] = useState(false);
  const [ loadingCustomers, setLoadingCustomers ] = useState(false);
  const [ showAddCustomerModal, setShowAddCustomerModal ] = useState(false);
  const [ showEditCustomerModal, setShowEditCustomerModal ] = useState(false);
  const [ showDeleteCustomerModal, setShowDeleteCustomerModal ] = useState(false);
  const [ isCustomerImportModalVisible, setIsCustomerImportModalVisible ] = useState(false);
  const [ isAwardPointsModalOpen, setIsAwardPointsModalOpen ] = useState(false);
  const [ multiLevelMembers, setMultiLevelMembers ] = useState([]);
  const [ isProgramsLoading, setIsProgramsLoading ] = useState(false);
  const [ isMembersLoading, setIsMembersLoading ] = useState(false);
  const [ customerMultiLevelDetails, setCustomerMultiLevelDetails ] = useState(null);
  const [ activeLoyalty, setActiveLoyalty ] = useState(null);
  const [ showCustomerGroupModal, setShowCustomerGroupModal ] = useState(false);
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
  const [ isBulkDeleteModalVisible, setIsBulkDeleteModalVisible ] = useState(false);
  const [ showCustomerDetailModal, setShowCustomerDetailsModal ] = useState(false);
  const [ isBroadcast, setIsBroadCast ] = useState(false);
  const [ showRedeemRewardModal, setShowRedeemRewardModal ] = useState(false);
  const [ search, setSearch ] = useState("");
  const [searchedColumn, setSearchedColumn] = useState('');
  const [ customer, setCustomer ] = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ pageSize, setPageSize ] = useState(30);
  const [ customerTotal, setCustomerTotal ] = useState(null);
  const searchInput = useRef(null);
  const [ searchedData, setSearchedData ] = useState(null);
  const [ fetchAllLoading, setFetchAllLoading ] = useState(false);
  const [ allCustomers, setAllCustomers ] = useState([]);
  const [ openBirthdayModal, setOpenBirthdayModal ] = useState(false);



  ///////////////useEffects////////////////////
  // useEffect(() => {
  //   if(search.length > 0){
  //     const customer = customers && customers.find(
  //       (cstmer) => cstmer.phone_number === search
  //     );

  //     if (customer) {
  //       toggleCustomerDetailModal(customer);
  //       setSearch("")
  //     }
  //   }
  // }, [search]);
  useEffect(() => {
    const { pathname } = location;
    if(pathname && pathname.includes("me")){
      const phoneNumber = pathname.split("/").length === 3 ? pathname.split("/")[2] : null;
      if(phoneNumber && phoneNumber.split("+").length > 1){
        handleGetCustByNumber(phoneNumber.split("+")[1])
      } 
      if(phoneNumber && phoneNumber.split("+").length === 1){
        handleGetCustByNumber(phoneNumber);
      } 
    }
  }, [location, allCustomers]);

  const handleGetCustByNumber = (search) => {
    const customer = allCustomers && allCustomers.find(
      (cstmer) => cstmer.phone_number.includes(search)
    );

    if (customer) {
      toggleCustomerDetailModal(customer);
      setSearch("")
    }
  }

  useEffect(() => {
    fetchCustomers(pageNumber, pageSize);
    fetchAllCustomers();

    getAllLoyaltyPrograms().catch((err) => console.log(err));
    getMerchantSMSBalance();
  }, []);

  useEffect(() => {
    if (isSubscribedToMultiLevel) {
      // console.log({ isSubscribedToMultiLevel });
      fetchMultiLevelMembers();
      fetchMultiLevelPrograms();
    }
  }, [isSubscribedToMultiLevel]);

  const customerHeaders = [
    {
      label: strings.firstName,
      key: "first_name",
    },
    {
      label: strings.lastName,
      key: "last_name",
    },
    {
      label: strings.email,
      key: "email",
    },
    {
      label: strings.phoneNumber,
      key: "phone_number",
    },
    {
      label: strings.addressLine1,
      key: "address_line_1",
    },
    {
      label: strings.addressLine2,
      key: "address_line_2",
    },
    {
      label: strings.sex,
      key: "sex",
    },
    {
      label: strings.dateOfBirth,
      key: "date_of_birth",
    },
    {
      label: strings.state,
      key: "state",
    },
    {
      label: strings.country,
      key: "country",
    },
    {
      label: strings.postCode,
      key: "postcode",
    },
    {
      label: strings.loyaltyId,
      key: "loyalty_id",
    },
    {
      label: strings.createdAt,
      key: "created_at",
    },
    {
      label: strings.noOfTransactionsMade,
      key: "transaction_ids.length",
    },
  ];

  const filteredAllCustomers = useMemo(() => {
    return allCustomers.length &&
    allCustomers.map((customer, key) => {
      return {
        ...customer,
        key: customer.id,
        email:
          customer.email && customer.email.split("@")[1] === "loystarmail.com"
            ? ""
            : customer.email && customer.email.split("@")[1] === "yopmail.com"
            ? ""
            : customer.email,
      };
    });
  }, [allCustomers, customer]);

  const filteredCustomers = useMemo(() => {
    return customers.length &&
    customers.map((customer, key) => {
      return {
        ...customer,
        key: customer.id,
        email:
          customer.email && customer.email.split("@")[1] === "loystarmail.com"
            ? ""
            : customer.email && customer.email.split("@")[1] === "yopmail.com"
            ? ""
            : customer.email,
      };
    });
  }, [customers, search, searchedData]);

  const sortedAllCustomers = useMemo(() => { 
    return filteredAllCustomers.length &&
    [...filteredAllCustomers].sort((a, b) => {
      var x = a.first_name.toLowerCase();
      var y = b.first_name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }, [filteredAllCustomers]);

  const sortedCustomers = useMemo(() => { 
    return filteredCustomers.length &&
    [...filteredCustomers].sort((a, b) => {
      var x = a.first_name.toLowerCase();
      var y = b.first_name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }, [filteredCustomers, search, searchedData]);

  const exportableCustomerList =
    sortedAllCustomers &&
    sortedAllCustomers.map((customer) => {
      const {
        loyalty_points: { simple_points, stamps_program },
      } = customer;

    const simplePointList = simple_points.map((loyalty) => {
      return {
        [loyalty.program_name]: `${loyalty.accumulated_points} ${strings.points}`,
      };
    });

    const stampsProgramList = stamps_program.map((loyalty) => {
      return {
        [loyalty.program_name]: `${loyalty.accumulated_stamps} ${strings.stamps}`,
      };
    });

    let programs = {};

    const customerLoyaltyList = [...simplePointList, ...stampsProgramList];

    customerLoyaltyList.forEach((item) => {
      const key = Object.keys(item)[0];

      programs[key] = item[key];
    });

    return {
      ...customer,
      ...programs,
    };
  });

  const loyaltyProgramNames = useMemo(() => { 
    return allLoyaltyPrograms.map(
      (loyaltyProgram) => loyaltyProgram.name
    );
  }, [allLoyaltyPrograms]);

  loyaltyProgramNames.length &&
    loyaltyProgramNames.forEach((name) => {
      customerHeaders.push({
        label: `${strings.pointsBalance} - ${name}`,
        key: name,
      });
    });


    ////////////variables//////////////
  
  const totalCustomers = customers.length || 0;

  const columns = [
    {
      title: strings.firstName,
      dataIndex: "first_name",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...getColumnSearchProps("first_name", strings.firstName),
    },
    {
      title: strings.lastName,
      dataIndex: "last_name",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...getColumnSearchProps("last_name", strings.lastName),
    },
    {
      title: strings.email,
      dataIndex: "email",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...getColumnSearchProps("email", strings.email),
    },
    {
      title: strings.phoneNumber,
      dataIndex: "phone_number",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...getColumnSearchProps("phone_number", strings.phoneNumber),
    },
    {
      title: strings.dateOfBirth,
      dataIndex: "date_of_birth",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    {
      title: strings.gender,
      dataIndex: "sex",
    },
  ];
  const hasSelected = selectedRowKeys.length > 0;

  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);

  const isAdmin = user.role_id && user.role_id === 1;
  //////////functions/////////////
  const fetchMultiLevelPrograms = async () => {
    try {
      setIsProgramsLoading(true);

      const result = await client.query({
        query: LIST_LOYALTIES,
        fetchPolicy: "network-only",
      });

      if (result.error) {
        message.error(result.error.message, 5);
      }

      const multiLevelLoyalties = result.data ? result.data.listLoyalties : [];

      setIsProgramsLoading(false);
    //   this.setState({ isProgramsLoading: false });

      // NB: Merchant should only have one active loyalty program running at a time.
      const activeLoyalty =
        multiLevelLoyalties.length &&
        multiLevelLoyalties.find(
          (loyalty) => loyalty.status.toLowerCase() === "active"
        );

        setActiveLoyalty(activeLoyalty);
    //   this.setState({ activeLoyalty });
    } catch (error) {
        setIsProgramsLoading(false);
    //   this.setState({ isProgramsLoading: false });

      console.log(error);
    }
  };

  const toggleBulkDeleteModal = () => {
      setIsBulkDeleteModalVisible(!isBulkDeleteModalVisible);
      // this.setState({
      //   isBulkDeleteModalVisible: !this.state.isBulkDeleteModalVisible,
      // });
  };

  const toggleSetBirthMessageModal = () => {
    setOpenBirthdayModal(!openBirthdayModal);
    // this.setState({
    //   isBulkDeleteModalVisible: !this.state.isBulkDeleteModalVisible,
    // });
};

  const fetchMultiLevelMembers = async () => {
      try {
        setIsMembersLoading(true);
        const result = await client.query({
          query: LIST_CUSTOMERS,
          fetchPolicy: "network-only",
        });
  
        if (result.error) {
          message.error(strings.errorFetchingCustomersMultilevelDetail, 5);
        }
  
        const multiLevelMembers =
          result.data &&
          result.data.listCustomers &&
          result.data.listCustomers.length
            ? result.data.listCustomers
            : [];
        
        setMultiLevelMembers(multiLevelMembers);
        setIsMembersLoading(false);
      //   this.setState({ multiLevelMembers, isMembersLoading: false });
      } catch (error) {
        console.log(error);
        setIsMembersLoading(false);
      //   this.setState({ isMembersLoading: false });
      }
  };

  const getMerchantSMSBalance = async () => {
    try {
      const res = await api.merchants.getMerchantSMSBalance();

      if (res.status === 200) {
        setSms_balance(res.data.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllCustomers = (pageNumber=1, pageSize=5000) => {
    setFetchAllLoading(true);
    getAllCustomers(pageNumber, pageSize)
    .then(res => {
        setAllCustomers(res.data);
        setFetchAllLoading(false);
    });
  };

  const fetchCustomers = (pageNumber=1, pageSize=30) => {
      setLoadingCustomers(true);
      // this.setState({ loading: true });

      getAllCustomers(pageNumber, pageSize)
      .then(res => {
          setCustomerTotal(res.headers["total"]);
          setLoadingCustomers(false);
      })
      // this.props
      //   .getAllCustomers()
      //   .then((res) => this.setState({ loading: false }));
  };

  const toggleAddCustomerModal = () => {
      setShowAddCustomerModal(!showAddCustomerModal);
      // this.setState({ showAddCustomerModal: !this.state.showAddCustomerModal });
  };

  const toggleEditCustomerModal = () => {
      setShowEditCustomerModal(!showEditCustomerModal);
      setShowCustomerDetailsModal(false);
      // this.setState({
      //   showEditCustomerModal: !this.state.showEditCustomerModal,
      //   showCustomerDetailModal: false,
      // });
  };

  const toggleAwardPointsModal = () => {
      setIsAwardPointsModalOpen(!isAwardPointsModalOpen);
      // this.setState({
      //   isAwardPointsModalOpen: !this.state.isAwardPointsModalOpen,
      // });
  };

  const toggleDeleteCustomerModal = () => {
      setShowDeleteCustomerModal(!showDeleteCustomerModal);
      setShowCustomerDetailsModal(false);
      // this.setState({
      //   showDeleteCustomerModal: !this.state.showDeleteCustomerModal,
      //   showCustomerDetailModal: false,
      // });
  };

  const  toggleSmsModal = (isBroadcast) => {
      setShowSMSModal(!showSMSModal);
      setShowCustomerDetailsModal(false);
      setIsBroadCast(isBroadcast);
      // this.setState({
      //   showSMSModal: !this.state.showSMSModal,
      //   showCustomerDetailModal: false,
      //   isBroadcast,
      // });
  };

  const toggleRedeemRewardModal = () => {
      setShowRedeemRewardModal(!showRedeemRewardModal);
      // this.setState({
      //   showRedeemRewardModal: !this.state.showRedeemRewardModal,
      // });
  };

  const toggleCustomerDetailModal = (record) => {
      const customerMultiLevelDetails =
        multiLevelMembers.length &&
        multiLevelMembers.find((member) =>
          member.points && member.points.length
            ? Number(member.customer_id) === Number(record.id)
            : Number(member.id) === Number(record.id)
      );

      setCustomerMultiLevelDetails(customerMultiLevelDetails);
      setShowCustomerDetailsModal(!showCustomerDetailModal);
      setCustomer(record);
      setSearch("")
  };

  function getColumnSearchProps(dataIndex, valen){
    return({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`${strings.search} ${valen}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {strings.search}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {strings.reset}
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (!record[dataIndex]) return;
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
  })};

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setSearch(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  
    if(dataIndex === "email" || dataIndex === "phone_number"){
      // setSearchedData(null);
      setLoadingCustomers(true)
      searchForCustomer(dataIndex, selectedKeys[0])
      .then(res => {
          setCustomerTotal(res.headers["total"]);
          setLoadingCustomers(false);
          // setSearchedData(res.data);
          confirm();
      })
      .catch(err => {
        setLoadingCustomers(false);
        console.log("error", err)
      })
    }else if(dataIndex === "first_name" || dataIndex === "last_name"){
      setLoadingCustomers(true)
      searchForCustomer("name", selectedKeys[0])
      .then(res => {
        setCustomerTotal(res.headers["total"]);
        setLoadingCustomers(false);
        // setSearchedData(res.data);
        confirm();
      })
      .catch(err => {
        setLoadingCustomers(false);
        console.log("error", err)
      })
    }else{
      const filtered = sortedCustomers.filter(item =>
        item[dataIndex] ? item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) : false
      );
      setSearchedData(filtered);
      confirm();
    }
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearch("");
    setSearchedColumn("");
    setSearchedData(null);
    fetchCustomers(pageNumber, pageSize);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const toggleCustomerImportModal = () => {
      setIsCustomerImportModalVisible(!isCustomerImportModalVisible);
  };

  return (
    <main>
        <Card
          title={strings.customers}
          extra={
            <>
              <Dropdown
                overlay={
                  <Menu>
                    <MenuItem key="1">
                      <Button
                        onClick={toggleCustomerImportModal}
                        style={{ width: "100%" }}
                      >
                        {strings.importCustomer}
                      </Button>
                    </MenuItem>

                    {isAdminIsManager && (
                      <MenuItem key="2">
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => {
                            notification.info({
                              message: strings.exportCustomers,
                              description: strings.yourDownloadWillStart,
                              duration: 5,
                            });
                          }}
                          disabled={
                            !exportableCustomerList ||
                            exportableCustomerList.length === 0
                          }
                        >
                          <CSVLink
                            data={
                              exportableCustomerList &&
                              exportableCustomerList.length
                                ? exportableCustomerList
                                : []
                            }
                            headers={customerHeaders}
                            filename={"customerList.csv"}
                            target="_blank"
                          >
                            {strings.exportCustomers}
                          </CSVLink>
                        </Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="default"
                  onClick={toggleAddCustomerModal}
                >
                  {strings.addNewCustomer} <Icon type="down" />
                </Button>
              </Dropdown>
            </>
          }
          loading={loading}
        >
          {isAdminIsManager && (
            <div className="sms-details">
              <Button
                onClick={() => {
                  setShowSMSModal(true);
                  setIsBroadCast(true);
                }}
              >
                <Icon type="mail" /> {strings.sendSmsBroadcast}
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  setShowCustomerGroupModal(true);
                }}
              >
                <Icon type="team" /> Customer Groups
              </Button>

              {isAdmin && hasSelected && (
                <Button
                  style={{ marginLeft: "20px" }}
                  icon={<DeleteOutlined />}
                  onClick={toggleBulkDeleteModal}
                >
                  {strings.deleteAll}
                </Button>
              )}

              {isAdmin && (
                <Button
                  style={{ marginLeft: "20px" }}
                  icon={<HeartOutlined />}
                  onClick={toggleSetBirthMessageModal}
                >
                  {strings.birthdayMessage}
                </Button>
              )}
              
            </div>
          )}

          <section className="customers-table mt-40">
            <h6>
              {strings.totalCustomers}: {searchedData ? searchedData.length : customerTotal}
            </h6>
            <CustomDataTable
              columns={columns}
              dataSource={searchedData ? searchedData : sortedCustomers}
              loading={loadingCustomers}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    toggleCustomerDetailModal(record);
                  },
                };
              }}
              pagination={{ 
                total: searchedData ? searchedData.length : customerTotal, 
                defaultPageSize: pageSize, 
              }}
              onChange={(page) => {
                const { pageSize, current } = page;
                if(pageNumber !== current){
                  setPageNumber(current);
                  setPageSize(pageSize)
                  fetchCustomers(current, pageSize);
                }
              }}
              current={pageNumber}
              rowSelection={rowSelection}
            />
          </section>
        </Card>
        {showCustomerGroupModal && (
          <CustomerGroups
            visible={showCustomerGroupModal}
            onCancel={() =>setShowCustomerGroupModal(false)}
            customers={customers}
            sendSmsToCustomerGroup={sendSmsToCustomerGroup}
          />
        )}
        {showCustomerDetailModal && (
          <CustomerDetails
            user={user}
            isProgramsLoading={isProgramsLoading}
            isMembersLoading={isMembersLoading}
            customer={customer}
            showCustomerDetailModal={showCustomerDetailModal}
            closeModal={toggleCustomerDetailModal}
            toggleEditCustomerModal={toggleEditCustomerModal}
            toggleDeleteCustomerModal={toggleDeleteCustomerModal}
            toggleSmsModal={toggleSmsModal}
            toggleRedeemRewardModal={toggleRedeemRewardModal}
            activeLoyalty={activeLoyalty}
            customerMultiLevelDetails={customerMultiLevelDetails}
            toggleAwardPointsModal={toggleAwardPointsModal}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        )}

        {isBulkDeleteModalVisible ? (
          <BulkDeleteCustomerModal
            customerIds={selectedRowKeys}
            showModal={isBulkDeleteModalVisible}
            closeModal={toggleBulkDeleteModal}
            getAllCustomers={() => fetchCustomers(0, pageSize)}
            setIsSelectedRow={()=>setSelectedRowKeys([])}
          />
        ) : null}

        {openBirthdayModal ? (
          <BirthDayModal
            open={openBirthdayModal}
            onCancel={toggleSetBirthMessageModal}
          />
        ) : null}

        {isAwardPointsModalOpen ? (
          <AwardPointsModal
            loyalty_id={activeLoyalty.id}
            user={user}
            visible={isAwardPointsModalOpen}
            closeModal={toggleAwardPointsModal}
            customer={customer}
          />
        ) : null}

        {showRedeemRewardModal && (
          <RedeemReward
            customer={customer}
            allLoyaltyPrograms={allLoyaltyPrograms}
            showCustomerDetailModal={showCustomerDetailModal}
            toggleEditCustomerModal={toggleEditCustomerModal}
            toggleDeleteCustomerModal={toggleDeleteCustomerModal}
            toggleSmsModal={toggleSmsModal}
            showModal={showRedeemRewardModal}
            closeModal={toggleRedeemRewardModal}
          />
        )}

        {showAddCustomerModal ? (
          <AddCustomerModal
            showModal={showAddCustomerModal}
            closeModal={toggleAddCustomerModal}
            pageSize={pageSize}
            pageNumber={pageNumber}
          />
        ) : null}

        {showEditCustomerModal ? (
          <EditCustomerModal
            showModal={showEditCustomerModal}
            closeModal={toggleEditCustomerModal}
            customer={customer}
          />
        ) : null}

        {showDeleteCustomerModal ? (
          <DeleteCustomerModal
            showModal={showDeleteCustomerModal}
            closeModal={toggleDeleteCustomerModal}
            customer={customer}
            pageSize={pageSize}
            pageNumber={pageNumber}
          />
        ) : null}

        {showSMSModal ? (
          <ShortSMSModalForm
            showModal={showSMSModal}
            closeModal={toggleSmsModal}
            customer={customer || {}}
            totalCustomers={totalCustomers}
            isBroadcast={isBroadcast}
            smsBalance={sms_balance}
          />
        ) : null}

        {isCustomerImportModalVisible ? (
          <ImportCustomerModal
            isCustomerImportModalVisible={isCustomerImportModalVisible}
            toggleCustomerImportModal={toggleCustomerImportModal}
            user={user}
          />
        ) : null}

        
      </main>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  customers: state.customer.allCustomers,
  isSubscribedToMultiLevel: state.multiLevel.isSubscribed,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  error: state.error,
});

export default connect(mapStateToProps, {
  getAllCustomers,
  getAllLoyaltyPrograms,
  searchForCustomer,
  getCustomerSales,
  sendSmsToCustomerGroup
})(WithApolloClient(Customers));