import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { Modal, Button, Popover, Input, message, Tag } from "antd";
import { Icon, Form } from "@ant-design/compatible";
import {
  DeleteOutlined,
  PrinterOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { getAllCustomers } from "../../../../../redux/actions/customerActions";
import CartList from "../../../../Sales/_partials/CartList";
import SalesReceipt from "../../../../Sales/_partials/SalesReceipt";
import banks from "../../../../../banks.json";
import RequestDeliveryModal from "../../../../Sales/_partials/RequestDeliveryModal";
import "./index.scss";
import api from "../../../../../api";
import strings from "../../../../../strings";
import moment from "moment";
import { returnOneItem, mangeReturnedItems } from "../../../../../redux/actions/saleActions";
import ManageReturnModal from "../../Products/_partials/ManageReturnModa";

const TransactionDetails = ({
  transaction = {},
  closeModal,
  openDetailsModal,
  user,
  toggleDeleteTransactionModal,
  getAllCustomers,
  customers,
  form,
  returnOneItem,
  getAllSales,
  mangeReturnedItems,
  getSalesRange,
  salespage,
  isFezDeliveryActive
}) => {
  const [isSendingReceipt, setIsSendingReceipt] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [requestDeliveryModalOpen, setRequestDeliveryModalOpen] =
    useState(false);
  const [customEmail, setCustomEmail] = useState(false);
  const [ returnLoading, setReturnLoading ] = useState(false);
  const [sendReceiptEmail, setSendReceiptEmail] = useState(false);
  const [ openManageReturnModal, setOpenManangeReturnModal ] = useState(false);
  const [ seletedItem, setSelectedItem ] = useState(null);

  const { getFieldDecorator, validateFields } = form;
  // console.log("tramnsatio", transaction);
  

  const {
    has_discount,
    discount_amount,
    channel,
    payment_reference,
    paymentType,
    reference_code,
    transactions,
    customerName,
    invoice_id,
    total,
    total_profit,
    staff,
    customer,
    id,
    created_at, 
    payment_label,
    net_vat
  } = transaction;

  let componentRef;

  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2) || (user.email.includes("@andreaiyamah.com"));
  const isAdmin = user.role_id === 1;

  useEffect(() => {
    getAllCustomers();
  }, [getAllCustomers]);

  const bankChannel = channel && banks.filter((bank) => bank.code === channel);

  const currency = user.currency || (user.employer && user.employer.currency);

  const original_total =
    has_discount && discount_amount
      ? Number(total) + Number(discount_amount)
      : 0;

  const discountValue =
    (100 * Number(discount_amount)) / Number(original_total);

  const content = (
    <div
      className="popover-content"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Button
        style={{ marginTop: 10 }}
        loading={isSendingReceipt}
        onClick={() => {
          if (customerName === strings.guestCustomer || !customer.email) {
            setCustomEmail(true);
          } else {
            setSendReceiptEmail(true);
          }
        }}
      >
        <Icon type="mail" />
        Send Receipt To Email
      </Button>
      <>
        <ReactToPrint
          trigger={() => (
            <Button style={{ marginTop: 20 }} icon={<PrinterOutlined />}>
              {strings.printReceipt}
            </Button>
          )}
          content={() => componentRef}
        />
        <div style={{ display: "none" }}>
          <SalesReceipt
            currency={user.currency || user.employer.currency}
            saleTotal={total}
            paidWith={paymentType}
            transaction={transactions}
            saleId={id}
            purchaseTime={moment(transaction.created_at || Date.now()).format(
              "DD-MM-YYYY hh:mm a"
            )}
            user={user}
            ref={(el) => (componentRef = el)}
            selectedCustomer={""}
            customersList={customers}
            channel={channel}
            payment_reference={payment_reference}
            hasDiscount={has_discount}
            discountAmount={discount_amount}
          />
        </div>
      </>
      {isFezDeliveryActive && <Button
        key="6"
        style={{ marginTop: 20 }}
        icon={<DeliveredProcedureOutlined />}
        onClick={() => setRequestDeliveryModalOpen(true)}
      >
        Request Delivery
      </Button>}
    </div>
  );

  const handleSendReceiptToEmail = async (guestEmail) => {
    setIsSendingReceipt(true);

    let payload = {
      sale_id: id,
      email: guestEmail,
    };
    // if (customer && customer.email) {
    //   payload = {
    //     sale_id: id,
    //     email: customer.email || guestEmail,
    //   };
    // }

    try {
      const res = await api.sales.sendReceiptToEmail(payload);

      setIsSendingReceipt(false);

      if (res.status === 200) {
        Modal.success({
          title: res.data.message,
          onOk: () => {
            closeModal();
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsSendingReceipt(false);
    }
  };

  const handleVisibleChange = () => setIsVisible((visible) => !visible);

  const handleCustomEmailSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) return;

      setCustomEmail(false);

      handleSendReceiptToEmail(values.email);
    });
  };

  const handleSendReceiptToEmailSubmit = () => {
    validateFields((err, values) => {
      if (err) return;
      setCustomEmail(false);

      handleSendReceiptToEmail(values.receipt_email);
    });
  };

  const handleReturnItem = (item) => {
    setReturnLoading(true);
    returnOneItem(item.id)
    .then(res => {
      if(res.status === 200){
        setReturnLoading(false);
        setSelectedItem(item);
        setOpenManangeReturnModal(true);
        // getAllSales();
        // message.success(res.data.message);
      }
    })
    .catch(err => {
      setReturnLoading(false);
      console.log("err", err.message);
    })
    
  }

  // Content of the action colum
  return (
    <>
    <Modal
      title={`${strings.transactionDetails} - ${id}`}
      cancelText={strings.cancel}
      open={openDetailsModal}
      destroyOnClose
      onCancel={closeModal}
      footer={[
        <Button type="primary">
          <Popover
            content={content}
            trigger="click"
            visible={isVisible}
            onVisibleChange={handleVisibleChange}
          >
            Actions
          </Popover>
        </Button>,
        isAdmin && (
          <Button
            key="2"
            icon={<DeleteOutlined />}
            onClick={toggleDeleteTransactionModal}
          >
            {strings.delete}
          </Button>
        ),
      ]}
    >
      {requestDeliveryModalOpen && (
        <RequestDeliveryModal
          visible={requestDeliveryModalOpen}
          onCancel={() => setRequestDeliveryModalOpen(false)}
          user={user}
          customer={customer}
          cartList={transactions}
          isFezDeliveryActive={isFezDeliveryActive}
        />
      )}
      {sendReceiptEmail && (
        <Modal
          open={sendReceiptEmail}
          title="Send Receipt"
          onCancel={() => setSendReceiptEmail(false)}
          footer={[
            <Button onClick={() => setSendReceiptEmail(false)}>Cancel</Button>,
            <Button
              type="primary"
              onClick={handleSendReceiptToEmailSubmit}
              loading={isSendingReceipt}
            >
              Submit
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="Enter email">
              {getFieldDecorator("receipt_email", {
                rules: [
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ],
                initialValue: customer.email,
              })(<Input size="large" type="email" />)}
            </Form.Item>
          </Form>
        </Modal>
      )}
      <div>
        <p>
          <strong>{strings.customerName}</strong>:{" "}
          {`${customerName ? customerName : "-"}`}
        </p>
        <p>
          <strong>{strings.paymentType}</strong>: {paymentType || "-"}
          {payment_label && <Tag className="ml-1" color="green">
             {payment_label.label_name}
          </Tag>}
        </p>
        <p>
          <strong>{strings.paymentReference}</strong>:{" "}
          {payment_reference || (invoice_id && `INV-${invoice_id}`) || "-"}
        </p>
        {channel && (
          <p>
            <strong>{strings.channel}</strong>: <br />
            Bank Transfer - {bankChannel[0].name}{" "}
            <img
              src={bankChannel[0].image_url}
              alt={bankChannel[0].name}
              style={{ width: "30px", height: "30px" }}
            />
          </p>
        )}

        <p>
          <strong>{strings.servedBy}</strong>:{" "}
          {staff ? staff || staff.username : strings.admin}
        </p>
        <p>
          <strong>{strings.products}</strong>
        </p>
        <div className="cart details-cart">
          {transactions && (
            <CartList
              data={transactions}
              increaseItemQuantity={null}
              removeItemFromCart={null}
              reduceItemFromCart={null}
              handleShowBulkModal={null}
              returnItem
              onClickReturn={handleReturnItem}
              returnLoading={returnLoading}
              returnOneItem={returnOneItem}
              setSelectedItem={setSelectedItem}
              setOpenManangeReturnModal={setOpenManangeReturnModal}
              getAllSales={getAllSales}
            />
          )}
        </div>
        <p>
          <strong>{strings.transDate}</strong>: {created_at && moment(created_at).format(
              "DD-MM-YYYY hh:mm a"
            )}
        </p>
        <p>
          <strong>{strings.total}</strong>: {total && currency}
          {total || "0"}
        </p>

        <p>
          <strong>{strings.netTax}</strong>: 
          {net_vat || "0"}
        </p>

        {has_discount && discount_amount && (
          <p>
            <strong>{strings.discount}</strong>: {currency}
            {discount_amount} (
            {!Number.isInteger(discountValue)
              ? discountValue.toFixed(2)
              : discountValue}
            % off)
          </p>
        )}

        {isAdmin && (
          <p>
            <strong>{strings.profit}</strong>: {total_profit || "-"}
          </p>
        )}
      </div>

      {customEmail && (
        <Form onSubmit={handleCustomEmailSubmit}>
          <Form.Item label={strings.pleaseEnterCustomerEmail}>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCustomerEmail,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>

          <Button type="primary" htmlType="submit">
            {strings.save}
          </Button>
        </Form>
      )}
    </Modal>

    {openManageReturnModal && (
      <ManageReturnModal 
        open={openManageReturnModal}
        onCancel={() => setOpenManangeReturnModal(false)}
        item={seletedItem} 
        mangeReturnedItems={mangeReturnedItems}
        handleGetReturnedItems={getAllSales}
        getSalesRange={getSalesRange}
        salespage={salespage}
      />
    )}
    </>
  );
};

const TransactionDetailsForm = Form.create()(TransactionDetails);

const mapStateToprops = (state) => ({
  customers: state.customer.allCustomers,
  isFezDeliveryActive: state.appStore.isFezDeliveryActive,
});

export default connect(mapStateToprops, { getAllCustomers, returnOneItem, mangeReturnedItems })(
  TransactionDetailsForm
);
